import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, Typography, Skeleton } from '@mui/material'
import { useLocalizedDataTypeWithUnit } from '../../../../shared/utils/langUtils'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  data_type: PropTypes.string
}

const defaultProps = { loading: false, data_type: '' }

/**
 *
 */
function AlertTypeCell ({ loading, data_type: dataType }) {
  const { localizedDataTypeWithUnit } = useLocalizedDataTypeWithUnit()
  return (
    <TableCell align='left'>
      <Typography variant='body1'>
        {loading ? <Skeleton width='80%' /> : localizedDataTypeWithUnit(dataType)}
      </Typography>
    </TableCell>
  )
}

AlertTypeCell.propTypes = propTypes
AlertTypeCell.defaultProps = defaultProps
// #endregion

export default AlertTypeCell
