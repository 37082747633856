import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@mui/material'
import { ReactComponent as AddIcon } from '../icons/AddIcon.svg'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  text: PropTypes.string,
  withAdd: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}
// Sett required and no default because if a  button we need an action
const defaultProps = { text: '', withAdd: false }

/**
 * @param {PropTypes.InferProps<propTypes> & import('@mui/material').ButtonProps} props
 */
function ButtonWhite ({ text, onClick, withAdd, ...props }) {
  return (
    <Button startIcon={withAdd && <AddIcon stroke='black' />} onClick={onClick} sx={{ borderColor: 'grey.main', color: 'black !important', padding: 1, '&:hover, &.Mui-focusVisible': { borderColor: '#000000', backgroundColor: 'transparent' } }} {...props}>
      {text}
    </Button>
  )
}

ButtonWhite.propTypes = propTypes
ButtonWhite.defaultProps = defaultProps
// #endregion

export default ButtonWhite
