import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Stack } from '@mui/material'
import DeviceTitle from '../../../shared/components/DeviceTitle'
import Forecasts from '../../device/components/Forecasts'
// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  device: PropTypes.shape({
    device_reference: PropTypes.string
  })
}

const defaultProps = {
  device: null
}

/**
 *
 */
function DeviceDetailsMapOverlay ({ device }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const linkPath = `/devices/${device.device_reference}`
  return (
    <Stack
      spacing={1}
      margin={2}
    >
      <Stack direction='row' justifyContent='space-between'>
        <DeviceTitle {...device} />
        <Stack flexShrink={0}>
          <Button onClick={() => navigate(linkPath)}>
            {t('see_more')}
          </Button>
        </Stack>
      </Stack>

      <Forecasts data={[device]} loading={false} fromMap />
    </Stack>
  )
}

DeviceDetailsMapOverlay.propTypes = propTypes
DeviceDetailsMapOverlay.defaultProps = defaultProps
// #endregion

export default DeviceDetailsMapOverlay
