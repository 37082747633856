import React from 'react'
import PropTypes from 'prop-types'
import { Marker } from 'react-leaflet'
import { useTheme } from '@mui/material/styles'
import DeviceDetailsMapOverlay from '../DeviceDetailsMapOverlay'
import StyledPopup from '../control/StyledPopup'
import { PinIcon } from './PinIcon'
// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// #region constants
// const normalSize = new L.Point(20, 20);
// const deviceSize = new L.Point(40, 40);

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  position: PropTypes.shape({
    position_latitude: PropTypes.number.isRequired,
    position_longitude: PropTypes.number.isRequired
  }).isRequired,
  levelPercent: PropTypes.number,
  device: PropTypes.shape({}),
  status: PropTypes.string.isRequired,
  missingWeight: PropTypes.number
}

const defaultProps = {
  levelPercent: 100,
  device: null,
  missingWeight: null
}

/**
 *
 */
// @TODO: handle device case not present in demo
// eslint-disable-next-line no-unused-vars
function DevicePin ({ position, status, levelPercent, device, missingWeight }) {
  const theme = useTheme()
  let iconColor = PinIcon(theme.palette.priorityLow.contrastText, theme.palette.priorityLow.dark, missingWeight)
  if (status === 'ok') {
    if (levelPercent <= 50) iconColor = PinIcon(theme.palette.priorityMedium.contrastText, theme.palette.priorityMedium.dark, missingWeight)
    if (levelPercent <= 10) iconColor = PinIcon(theme.palette.priorityHigh.contrastText, theme.palette.priorityHigh.dark, missingWeight)
  } else if (status === 'problem') {
    iconColor = PinIcon(theme.palette.text.secondary, 'black')
  } else {
    iconColor = PinIcon(theme.palette.calibration.main, theme.palette.calibration.dark)
  }

  return (
    <Marker
      position={[position.lat, position.lng]}
      icon={iconColor}
    >
      <StyledPopup minWidth={100} keepInView maxHeight={400} closeOnEscapeKey>
        <DeviceDetailsMapOverlay
          device={device}
        />
      </StyledPopup>
    </Marker>
  )
}

DevicePin.propTypes = propTypes
DevicePin.defaultProps = defaultProps
// #endregion

export default DevicePin
