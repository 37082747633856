import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from 'react-i18next'
import { useTheme, useMediaQuery, Tooltip } from '@mui/material'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func
}

const defaultProps = { selected: false, onClick: null }

/**
 *
 */
function SidebarItem ({ name, icon, selected, onClick }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const xlarge = useMediaQuery(theme.breakpoints.up('xl'))

  const CustomListItem = (withText, my) => {
    return (
      <ListItem
        disablePadding
        sx={{
          my,
          borderRadius: '10px'
        }}
      >
        <ListItemButton
          sx={{
            flexDirection: 'column'
          }}
          onClick={onClick}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              display: 'flex',
              padding: 1.5,
              justifyContent: 'center',
              alignContent: 'center',
              backgroundColor: selected ? 'nanoBlueHighlight.main' : 'transparent',
              borderRadius: 1,
              path: { color: 'white' }
            }}
          >
            {icon}
          </ListItemIcon>
          {withText && <ListItemText
            primary={name}
            sx={{ color: 'white', mt: 0.5 }}
            primaryTypographyProps={{ fontSize: '13px', fontWeight: '600', letterSpacing: '-0.52px' }}
                       />}
        </ListItemButton>
      </ListItem>
    )
  }
  return (
    xlarge
      ? (
          CustomListItem(true, 2.5)
        )
      : (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                typography: 'h6'
              }
            }
          }}
          title={name}
          enterTouchDelay={0}
          enterDelay={0}
          placement='right'
        >
          {CustomListItem(false, 1)}
        </Tooltip>
        )
  )
}

SidebarItem.propTypes = propTypes
SidebarItem.defaultProps = defaultProps
// #endregion

export default SidebarItem
