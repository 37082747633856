import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { Box, CircularProgress, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useTheme } from '@mui/material'
import NanoPaper from '../../../shared/components/NanoPaper'
import { AddIcon, CheckIconActive, CheckIconInactive } from '../../../shared/icons/index'
import NanoSelectSingle from '../../../shared/components/NanoSelectSingle'
import { DateCalendar, PickersDay, MobileTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useWorkspaceSettingsStore } from '../../../shared/store'
import NanoChip from '../../../shared/components/NanoChip'
import { client } from '../../../shared/apiClient'
import minHoursBeforeOrderOptions from '../../../shared/models/minHoursBeforeOrder'
import { dateMediumWithoutYearTimeAndDay } from '../../../shared/utils/dateUtils'
import ButtonWhite from '../../../shared/components/ButtonWhite'
import { debounce } from '@mui/material/utils'
import i18n from '../../../config/i18n'
// #region constants

const daysAlreadyPicked = (props) => {
  const { orderDatesDisabled = [], day, outsideCurrentMonth, ...other } = props
  const isSelected =
    !props.outsideCurrentMonth && orderDatesDisabled.some(disabledDate => disabledDate.isSame(day, 'day'))

  return (
    <PickersDay
      {...other} outsideCurrentMonth={outsideCurrentMonth} day={day}
      sx={{
        ...(isSelected
          ? {
              color: 'white',
              backgroundColor: (theme) => `${theme.palette.secondary.main} !important`,
              '&:hover, &:focus': {
                color: 'white',
                backgroundColor: (theme) => `${theme.palette.secondary.dark} !important`
              }
            }
          : {
              color: 'black !important',
              backgroundColor: (theme) => 'transparent !important',
              '&:hover, &:focus': {
                backgroundColor: (theme) => 'black'
              }
            }
        )
      }}
    />
  )
}
// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */

// @FIX ME: we should save value not after modification
function AdminPlanningPage () {
  const theme = useTheme()
  const { t } = useTranslation()
  const state = useWorkspaceSettingsStore()
  const [weekday, setWeekday] = useState([])
  const [calendarIsOpen, setCalendarIsOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  // workspace settings
  const [minHoursBeforeOrder, setMinHoursBeforeOrder] = useState([])
  const [minTimeFriday, setMinTimeFriday] = useState([])
  const [orderWeekdaysEnabled, setOrderWeekdaysEnabled] = useState([])
  const [orderDatesDisabled, setOrderDatesDisabled] = useState([])

  const getData = async () => {
    setLoading(true)
    await state.fetchData()
    // MinHoursBeforeOrder
    const minHoursBeforeOrderSettings = state.getSetting('minHoursBeforeOrder')?.value
    setMinHoursBeforeOrder(minHoursBeforeOrderSettings)
    // MinTimeFriday
    const minTimeFridaySettings = state.getSetting('minTimeFriday')?.value
    // @ts-ignore
    setMinTimeFriday(minTimeFridaySettings ? dayjs(`2022-01-01T${minTimeFridaySettings}`) : null)
    // OrderWeekdaysEnabled
    const orderWeekdaysEnabledSettings = state.getSetting('orderWeekdaysEnabled')?.value?.split(',')
    setOrderWeekdaysEnabled(orderWeekdaysEnabledSettings?.map((days) => days.toLowerCase()) || [])
    // OrderDatesDisabled
    const dates = state.getSetting('orderDatesDisabled')?.value?.split(',')
    const datesDayjs = dates?.map(date => dayjs(`${date}`, 'M/D/YYYY'))
    setOrderDatesDisabled(datesDayjs?.filter(holiday => holiday >= dayjs())?.sort((a, b) => a - b) || [])
    setLoading(false)
  }

  useEffect(() => {
    getData()
    // @FIXME : Use weekdays from dateUtils.js but i18n not initalized at time
    setWeekday(Array.from({ length: 7 }, (_, index) => {
      const nextDate = new Date('1970-01-04')
      nextDate.setDate(nextDate.getDate() + index)
      const day = nextDate.toLocaleString('en-EN', { weekday: 'long' })
      let dayLocal = nextDate.toLocaleString(i18n.resolvedLanguage, { weekday: 'long' })
      let dayIndex = null
      dayLocal = dayLocal[0].toUpperCase() + dayLocal.slice(1)
      dayIndex = index
      return {
        day,
        dayLocal,
        dayIndex
      }
    }))
  }, [i18n.resolvedLanguage])

  /// / update functions
  const updateWeekday = (daySelected) => {
    let newOrderWeekdaysEnabled = []
    if (orderWeekdaysEnabled?.includes(daySelected.toLowerCase())) {
      newOrderWeekdaysEnabled = orderWeekdaysEnabled.filter(d => d !== daySelected.toLowerCase())
      setOrderWeekdaysEnabled(newOrderWeekdaysEnabled)
    } else {
      newOrderWeekdaysEnabled = [...orderWeekdaysEnabled, daySelected.toLowerCase()]
      setOrderWeekdaysEnabled(newOrderWeekdaysEnabled)
    }

    const id = state.getSetting('orderWeekdaysEnabled')?.workspace_setting_id
    if (id) {
      // @ts-ignore
      client.PATCH(`/v1/workspace/settings/${id}`, {
        body: { key: 'orderWeekdaysEnabled', value: newOrderWeekdaysEnabled.toString() }
      }).then(() => state.fetchData())
    } else {
      client.POST('/v1/workspace/settings',
        {
          // @ts-ignore
          body: {
            key: 'orderWeekdaysEnabled',
            value: newOrderWeekdaysEnabled.toString()
          }
        }).then(() => state.fetchData())
    }
  }

  const updateDatesDisabled = (date) => {
    const array = [...orderDatesDisabled]
    const index = findIndexDate(array, date)
    if (index >= 0) {
      array.splice(index, 1)
    } else {
      array.push(date)
    }
    setOrderDatesDisabled(array.sort((a, b) => a - b))
    const id = state.getSetting('orderDatesDisabled')?.workspace_setting_id
    if (id) {
      // @ts-ignore
      client.PATCH(`/v1/workspace/settings/${id}`, {
        body: { key: 'orderDatesDisabled', value: array.map(date => dayjs(date).format('MM/DD/YYYY')).toString() }
      }).then(() => state.fetchData())
    } else {
      client.POST('/v1/workspace/settings',
        {
          // @ts-ignore
          body: {
            key: 'orderDatesDisabled',
            value: array.map(date => dayjs(date).format('MM/DD/YYYY')).toString()
          }
        }).then(() => state.fetchData())
    }
  }

  const updateMinHoursBeforeOrder = (valueDay) => {
    setMinHoursBeforeOrder(valueDay)
    const id = state.getSetting('minHoursBeforeOrder')?.workspace_setting_id
    if (id) {
      // @ts-ignore
      client.PATCH(`/v1/workspace/settings/${id}`, {
        body: { key: 'minHoursBeforeOrder', value: valueDay }
      }).then(() => state.fetchData())
    } else {
      client.POST('/v1/workspace/settings',
        {
          // @ts-ignore
          body: {
            key: 'minHoursBeforeOrder',
            value: valueDay
          }
        }).then(() => state.fetchData())
    }
  }

  const updateMinTimeFriday = (value) => {
    const id = state.getSetting('minTimeFriday')?.workspace_setting_id
    let valueHour
    if ((!isNaN(dayjs(value).hour())) && (!isNaN(dayjs(value).minute()))) {
      valueHour = `${dayjs(value).hour()}:${dayjs(value).minute()}`
    } else if (value === null) {
      valueHour = ''
    }
    if (id && valueHour !== undefined) {
      // @ts-ignore
      client.PATCH(`/v1/workspace/settings/${id}`, {
        body: { key: 'minTimeFriday', value: valueHour }
      }).then(() => state.fetchData())
    } else {
      client.POST('/v1/workspace/settings',
        {
          // @ts-ignore
          body: {
            key: 'minTimeFriday',
            value: valueHour
          }
        }).then(() => state.fetchData())
    }
  }

  // FIX ME : Develop a generic function for debouncing updates; when a user attempts to post and subsequently patches the identical settings, it is essential to verify that the post's ID has been generated.
  const debouncedUpdateMinTimeFriday = debounce(updateMinTimeFriday, 250)
  const debouncedUpdateDatesDisabled = debounce(updateDatesDisabled, 250)
  const debouncedUpdateMinHoursBeforeOrder = debounce(updateMinHoursBeforeOrder, 250)
  const debouncedUpdateWeekday = debounce(updateWeekday, 250)

  const findIndexDate = (dates, date) => {
    const dateTime = dayjs(date)
    return dates.findIndex((item) => dayjs(item).isSame(dateTime))
  }

  return (
    loading
      ? (
        <Box display='flex' justifyContent='center'>
          <CircularProgress />
        </Box>
        )
      : (
        <Stack sx={{ pt: 2, flexShrink: 1 }} direction='row' display='flex' justifyContent='space-between' alignItems='stretch' spacing={2}>
          <Stack display='flex' sx={{ flexGrow: 1, minWidth: '33%' }}>
            <NanoPaper>
              <Typography variant='h5' sx={{ m: 1 }}>
                {t('delivery_days')}
              </Typography>
              <List>
                {weekday.map((day) => (
                  <ListItem key={day.dayIndex}>
                    <ListItemButton
                      onClick={() => debouncedUpdateWeekday(day.day)}
                    >
                      <ListItemIcon>
                        {(orderWeekdaysEnabled?.includes(day.day.toLowerCase())) ? <CheckIconActive /> : <CheckIconInactive />}
                      </ListItemIcon>
                      <ListItemText primary={day.dayLocal} />
                      {(orderWeekdaysEnabled?.includes(day.day.toLowerCase())) ? <Typography variant='body1' color={theme.palette.secondary.main}> {t('available')} </Typography> : <Typography variant='body1' color='text.secondary'> {t('unavailable')} </Typography>}
                    </ListItemButton>
                    <Divider />
                  </ListItem>
                ))}
              </List>
            </NanoPaper>
          </Stack>

          <Stack direction='column' display='flex' justifyContent='space-between' spacing={2} sx={{ flexGrow: 2 }}>
            <NanoPaper>
              <Stack direction='row' justifyContent='space-between'>
                <Typography variant='h5' sx={{ m: 1 }}>
                  {t('unavailable_delivery')}
                </Typography>
                <ButtonWhite
                  withAdd
                  variant='outlined'
                  startIcon={<AddIcon />}
                  text={t('unavailability')}
                  onClick={() => setCalendarIsOpen(!calendarIsOpen)}
                />
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' justifyContent='flex-start' alignContent='flex-start' sx={{ flexWrap: 'wrap' }}>
                  {orderDatesDisabled?.map((d, index) => (
                    <Stack key={index} direction='row' sx={{ p: 1 }}>
                      <NanoChip
                        label={dateMediumWithoutYearTimeAndDay(dayjs(d).toDate())}
                        section='day'
                        onDelete={() => debouncedUpdateDatesDisabled(dayjs(d))}
                        sx={{
                          backgroundColor: 'grey.main'
                        }}
                      />
                    </Stack>
                  ))}
                </Stack>
                {calendarIsOpen &&
                  <Stack direction='row' justifyContent='flex-end' spacing={1}>
                    <DateCalendar
                      disablePast
                      disableHighlightToday
                      onChange={(newValue) => debouncedUpdateDatesDisabled(dayjs(newValue))}
                      slots={{
                        day: daysAlreadyPicked
                      }}
                      slotProps={{
                        day: {
                          orderDatesDisabled
                        }
                      }}
                    />

                  </Stack>}
              </Stack>
            </NanoPaper>

            <NanoPaper>
              <Stack direction='column' spacing={2}>
                <Stack pb={2}>
                  <Typography variant='h5' sx={{ m: 1 }}>
                    {t('ordering_features')}
                  </Typography>
                </Stack>
                <Stack direction='row' alignContent='center' alignItems='center' spacing={2}>
                  <NanoSelectSingle
                    label={t('delivery_delay_ph')}
                    options={minHoursBeforeOrderOptions(t)}
                    variant='outlined'
                    onChange={(event) => debouncedUpdateMinHoursBeforeOrder(event.target.value)}
                    value={minHoursBeforeOrder}
                  />
                </Stack>
                <Stack direction='row' alignContent='center' alignItems='center' spacing={2}>
                  <MobileTimePicker
                    label={t('min_time_friday')}
                    value={minTimeFriday}
                    onChange={(newValue) => { debouncedUpdateMinTimeFriday(newValue) }}
                    sx={{ width: 500 }}
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'cancel', 'accept']
                      }
                    }}
                  />

                </Stack>
              </Stack>
            </NanoPaper>
          </Stack>
        </Stack>
        )
  )
}

AdminPlanningPage.propTypes = propTypes
AdminPlanningPage.defaultProps = defaultProps
// #endregion

export default AdminPlanningPage
