import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Stack, DialogContentText } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import NanoDialog from './NanoDialog'
import SelectDevicesAndGroups from './selectDevicesOrGroups/SelectDevicesAndGroups'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  value: PropTypes.shape([])
}

const defaultProps = {
  title: 'change_group',
  subtitle: null,
  value: []
}

/**
 *
 */
function AddDeviceOrGroupDialog ({ open, onClose, title, subtitle, onAdd, value }) {
  const { t } = useTranslation()
  const [accessToAdd, setAccessToAdd] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setAccessToAdd(value)
  }, [value])

  const handleAdd = (itemsToAdd) => {
    setLoading(true)
    onAdd(itemsToAdd)
    onClose()
    setLoading(false)
  }
  return (
    <NanoDialog open={open} onClose={onClose} title={title}>
      {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
      <Stack spacing={2}>
        <SelectDevicesAndGroups
          value={accessToAdd}
          onChange={(_, value) => setAccessToAdd(value)}
        />
        <LoadingButton
          type='submit'
          loading={loading}
          fullWidth
          disabled={!accessToAdd.length}
          onClick={() => handleAdd(accessToAdd)}
        >
          {t('validate')}
        </LoadingButton>

      </Stack>
    </NanoDialog>
  )
}

AddDeviceOrGroupDialog.propTypes = propTypes
AddDeviceOrGroupDialog.defaultProps = defaultProps
// #endregion

export default AddDeviceOrGroupDialog
