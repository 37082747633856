import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup, useTheme, useMediaQuery, Typography } from '@mui/material'
import DashboardDisplayMode from './models/displayModeModel'
import { ReactComponent as MapIcon } from '../../shared/icons/MapIcon.svg'
import { ReactComponent as ListIcon } from '../../shared/icons/ListIcon.svg'
import { sendEvent } from '../../shared/utils/analyticsUtils'
// #region constants
const StyleToggleButton = styled(ToggleButton)(() => ({
  gap: 10,
  color: 'black',
  '&.Mui-selected': {
    backgroundColor: 'white'
  }
}))
const StyleToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(1),
    border: 0,
    '&:not(:first-of-type)': {
      borderRadius: '4px'
    },
    '&:first-of-type': {
      borderRadius: '4px'
    }
  }
}))
// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string
}

const defaultProps = {
  onChange: null,
  defaultValue: DashboardDisplayMode.List
}

/**
 *
 */
function SwitchDisplayMode ({ defaultValue, onChange }) {
  const { t } = useTranslation()
  const [displayMode, setDisplayMode] = useState(defaultValue)
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('md'))

  const handleChange = (_event, newMode) => {
    sendEvent('switch_dashboard_display', { mode: newMode })
    setDisplayMode(newMode)
    onChange(newMode)
  }

  return (
    <StyleToggleButtonGroup
      value={displayMode}
      exclusive
      onChange={handleChange}
      size='small'
      sx={{ backgroundColor: 'grey.main', height: '40px' }}
    >
      <StyleToggleButton value={DashboardDisplayMode.List}>
        <ListIcon />
        <Typography variant='h5'>
          {!mobileFormat && t('dashboard_switch_display_mode_list')}
        </Typography>
      </StyleToggleButton>
      <StyleToggleButton value={DashboardDisplayMode.Map}>
        <MapIcon />
        <Typography variant='h5'>
          {!mobileFormat && t('dashboard_switch_display_mode_map')}
        </Typography>
      </StyleToggleButton>
    </StyleToggleButtonGroup>
  )
}

SwitchDisplayMode.propTypes = propTypes
SwitchDisplayMode.defaultProps = defaultProps
// #endregion

export default SwitchDisplayMode
