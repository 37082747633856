import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Alert, DialogContentText, Stack } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import NanoDialog from '../../../shared/components/NanoDialog'
import SelectEmailUser from '../../../shared/components/SelectEmailUser'
import { useWorkspaceSettingsStore, useUserStore } from '../../../shared/store'
import { client } from '../../../shared/apiClient'

// import { useSessionStore } from '../../shared/store';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  defaultGroup: PropTypes.arrayOf(PropTypes.shape({}))
}

const defaultProps = {
  isOpen: false,
  userGroup: [],
  onSuccess: null,
  defaultGroup: null
}

/**
 *
 */
function DefaultRecipientModal ({ isOpen, onClose, onSuccess }) {
  const { t } = useTranslation()
  const [emails, setEmails] = useState([])
  const [isDisabled, setIsDisabled] = useState([])
  const state = useWorkspaceSettingsStore()
  const coopEmail = state.getSetting('coopEmails')

  const users = useUserStore((state) => state.dataUser)
  const [displayError, setDisplayError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    const coopEmails = coopEmail.value.split(',')
    setEmails(users
      .filter((u) => coopEmails.includes(u.email))
      .map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        id: user.idUser,
        section: 'user',
        ...user
      }))
    )
  }, [])

  const onSubmit = () => {
    setDisplayError(null)
    setIsLoading(true)
    client.PATCH('/v1/workspace/settings/{id}',
      {
        params: { path: { id: coopEmail.workspace_setting_id } },
        body: {
          key: coopEmail.key,
          value: emails.map((email) => email.email).join(',')
        }
      }
    ).then(async () => {
      setIsLoading(false)
      await useWorkspaceSettingsStore.getState().fetchData()
      onClose()
      onSuccess()
    })
      .catch((err) => {
        setDisplayError(err.message)
        setIsLoading(false)
      })
  }

  return (
    <NanoDialog
      open={isOpen}
      onClose={handleClose}
      title={t('default_recipient_title')}
    >
      <DialogContentText>
        {t('default_recipient_description')}
      </DialogContentText>
      <Stack sx={{ pt: 3 }} flexDirection='row' alignItems='flex-end' justifyContent='space-between'>

        <Stack flexGrow='1'>
          <SelectEmailUser
            onChange={(_, data) => {
              setEmails(data)
              setIsDisabled(data.length === 0)
            }}
            value={emails}
            required
          />
        </Stack>
        {!!displayError && <Alert severity='error'>{displayError}</Alert>}
        <LoadingButton
          sx={{ ml: 10 }}
          type='submit'
          loading={isLoading}
          loadingPosition='start'
          disabled={isDisabled}
          onClick={onSubmit}
        >
          {t('custom_button_add')}
        </LoadingButton>
        {/* </Stack> */}
      </Stack>
    </NanoDialog>
  )
}

DefaultRecipientModal.propTypes = propTypes
DefaultRecipientModal.defaultProps = defaultProps
// #endregion

export default DefaultRecipientModal
