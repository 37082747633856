import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoadingPage from './LoadingPage'
// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = { progress: PropTypes.number, heavy: PropTypes.bool }

const defaultProps = { progress: null, heavy: false }

/**
 * @param {(PropTypes.InferProps<LoadingMap.propTypes>)}
 */
function LoadingMap ({ heavy, progress, ...props }) {
  const { t } = useTranslation()
  return (
    <>

      <LoadingPage {...props} />
      <Box>
        <Box
          sx={{
            position: 'absolute',
            top: '50%', // Set top to 50% to center vertically
            left: '50.1%', // Set left to 50% to center horizontally
            transform: 'translate(-50%, -50%)' // Center the box both horizontally and vertically
          }}
        >
          <Typography variant='caption' color='text.secondary'>
            {t('data_type_unit', { value: { value: progress, unit: 'percent' } })}
          </Typography>

        </Box>
        {
          heavy &&
          (
            <Box
              sx={{
                position: 'absolute',
                top: '55%', // Set top to 50% to center vertically
                left: '50.1%', // Set left to 50% to center horizontally
                transform: 'translate(-50%, -50%)' // Center the box both horizontally and vertically
              }}
            >
              {t('map_view_too_heavy')}
            </Box>)
}
      </Box>

    </>
  )
}

LoadingMap.propTypes = propTypes
LoadingMap.defaultProps = defaultProps
// #endregion

export default LoadingMap
