import React from 'react'
import PropTypes from 'prop-types'
import { client } from '../../apiClient'
import SelectDevicesOrGroups from './SelectDevicesOrGroups'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

const fetchOptions = async (search = undefined) => {
  const query = {
    search,
    is_poi: true,
    withRelated: 'devices.deviceCombinedDevices,devices.deviceCombinedDevice,devices.groups,devices.deviceContent'
  }
  const response = await client.GET('/v1/groups', { params: { query } })
  const groups = response.data
    .map((group) => {
      const devices =
      group.devices
        .filter(device => device.deviceCombinedDevice?.length === 0 && device.deviceCombinedDevices?.length === 0)

      return {
        label: group.group_name,
        id: group.group_id,
        section: 'group',
        ...group,
        devices

      }
    })

  return groups
}
// #endregion

// #region component
const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
    section: PropTypes.string

  })
}

const defaultProps = {
  value: null
}

/**
 *
 */
function SelectDevicesOnly ({ onChange, value, label, error, required }) {
  return (
    <SelectDevicesOrGroups
      label={label}
      onChange={onChange}
      error={error}
      value={value}
      required={required}
      fetchOptions={fetchOptions}
      withGroups={false}
    />
  )
}

SelectDevicesOnly.propTypes = propTypes
SelectDevicesOnly.defaultProps = defaultProps
// #endregion

export default SelectDevicesOnly
