import React from 'react'
import PropTypes from 'prop-types'
import NanoSelectMultiple from '../../shared/components/NanoSelectMultiple'
import { useTranslation } from 'react-i18next'
import { AlertDataType } from './models/alertModel'

// #region constants

export const alertDataTypes = (t) => {
  return [
    {
      name: AlertDataType.LevelPercent,
      value: AlertDataType.LevelPercent
    },
    {
      name: t('string_workspace_filling_unit', { value: t(AlertDataType.LevelTons) }),
      value: AlertDataType.LevelTons
    },
    {
      name: AlertDataType.RemainingDays,
      value: AlertDataType.RemainingDays
    }
  ]
}

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  value: PropTypes.shape({

  }),
  handleSelect: PropTypes.func.isRequired
}

const defaultProps = { value: [] }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
const AlertFilterDataType = ({ value, handleSelect }) => {
  const { t } = useTranslation()
  return (
    <NanoSelectMultiple
      value={value}
      handleSelect={handleSelect}
      placeholder={t('alert_form_type_placeholder')}
      options={alertDataTypes(t)}
    />
  )
}

AlertFilterDataType.propTypes = propTypes
AlertFilterDataType.defaultProps = defaultProps
// #endregion

export default AlertFilterDataType
