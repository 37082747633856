import React from 'react'
// import styled from 'styled-components';
import PropTypes from 'prop-types'
import { MenuItem, ListItemText, ListItemIcon, Checkbox } from '@mui/material'
import { ReactComponent as BasicCheckIconInactive } from '../icons/BasicCheckIconInactive.svg'
import { ReactComponent as BasicCheckIconActive } from '../icons/BasicCheckIconActive.svg'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  listItemTextProps: PropTypes.shape({}),
  listItemProps: PropTypes.shape({}),
  icon: PropTypes.element,
  checked: PropTypes.bool,
  withCheckbox: PropTypes.bool,
  section: PropTypes.string,
  children: PropTypes.node
}

const defaultProps = { checked: false, withCheckbox: false, section: null, icon: null, children: null, listItemProps: null, listItemTextProps: null }

/**
 * @param {(PropTypes.InferProps<NanoMenuItem.propTypes> & Partial<import('@mui/material').MenuItemProps>)} props
 */
function NanoMenuItem ({ key, checked, listItemProps, listItemTextProps, icon, withCheckbox, ...props }) {
  return (
    <MenuItem
      {...props}
      onMouseMove={null} // fixes bug when hovering over option

    >
      {icon && (
        <ListItemIcon sx={{ ml: 0 }} {...listItemProps}>
          {icon}
        </ListItemIcon>
      )}
      {listItemTextProps && <ListItemText {...listItemTextProps} />}
      {withCheckbox && (
        <Checkbox
        // sx={{ p: 0 }}
          icon={<BasicCheckIconInactive />}
          checkedIcon={<BasicCheckIconActive />}
          checked={checked}
        />
      )}
      {props.children ?? null}
    </MenuItem>
  )
}

NanoMenuItem.propTypes = propTypes
NanoMenuItem.defaultProps = defaultProps
// #endregion

export default NanoMenuItem
