import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useForm, Controller } from 'react-hook-form'
import { Alert, Card, CardHeader, DialogContentText, IconButton, Stack, TextField, useTheme } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import dayjs from 'dayjs'
import NanoDialog from '../../../shared/components/NanoDialog'
import { ReactComponent as TruckImage } from '../../../shared/icons/TruckImage.svg'
import NanoSelectDate from '../../../shared/components/NanoSelectDate'
import { isAfter, isBefore } from '../../../shared/utils/dateUtils'
import ButtonWhite from '../../../shared/components/ButtonWhite'
import { CloseIcon } from '../../../shared/icons/index'
import { uuid4 } from '@sentry/utils'
import SelectDevices from '../../../shared/components/SelectDevices'
import NanoSelectHour from '../../../shared/components/NanoSelectHour'
import { client } from '../../../shared/apiClient'
import { Promise } from 'bluebird'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  row: PropTypes.shape({})
}

const defaultProps = {
  isOpen: false,
  row: {}
}

function CalibMultiDeliveryModal ({ isOpen, onSuccess, onClose, row }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()
  const [devicesToDeliverIds, setDevicesToDeliverIds] = useState([]) // create the id for the hook form
  const [deliveriesToUpdate, setDeliveriesToUpdate] = useState([]) // existing deliveries for this day, to be updated with a new hour
  const [deliveriesToCreate, setDeliveriesToCreate] = useState([]) // list of deliveries to create
  const [displayError, setDisplayError] = useState(false)

  const { handleSubmit, getValues, watch, control } = useForm()
  const date = watch('date')
  const data = watch()

  const getDeliveriesThisDay = async (date, device) => {
    if (!date.isValid()) return []
    const startDate = dayjs(date).startOf('day').toISOString()
    const endDate = dayjs(date).endOf('day').toISOString()
    const query = { start_date: startDate, type: 'delivery', end_date: endDate }
    const response = await client.GET('/v2/calibrations', { params: { query } })
    return response.data.filter((calib) => calib.idDevice === device)
  }

  const displayDeliveriesThisDay = async (date, device) => {
    const existingDeliveries = await getDeliveriesThisDay(date, device)
    if (existingDeliveries.length) {
      setDeliveriesToUpdate((oldDel) => {
        // Si deliveriesToUpdate est vide, ajouter simplement existingDeliveries
        if (oldDel.length === 0) {
          return existingDeliveries
        }
        const uniqueNewDeliveries = new Set([
          ...oldDel.map((delivery) => delivery.idCalibration),
          ...existingDeliveries.map((delivery) => delivery.idCalibration)
        ])
        const updatedDeliveries = Array.from(uniqueNewDeliveries).map((idCalibration) =>
          [...oldDel, ...existingDeliveries].find((delivery) => delivery.idCalibration === idCalibration)
        )
        return updatedDeliveries
      })
    }
  }

  const checkHourExists = (id) => {
    const allDevicesWithHour =
    deliveriesToCreate.map((d) => {
      return { id: d.id, reference: d.value.device_reference, hour: data[`hour_${d.id}`] }
    })
      .concat(deliveriesToUpdate.map((d) => {
        return { id: d.idCalibration, reference: d.idDevice, hour: data[`hour_${d.idCalibration}`] }
      }))

    const duplicateEntries = []
    allDevicesWithHour.forEach((device, index) => {
      // Vérifier si une entrée identique existe avant l'index actuel
      const duplicateIndex = allDevicesWithHour.findIndex((otherDevice, otherIndex) => {
        // Comparer les références et les heures en utilisant dayjs
        return (
          otherIndex < index &&
      otherDevice.reference === device.reference &&
      dayjs(otherDevice.hour).startOf('minute').isSame(dayjs(device.hour).startOf('minute'))
        )
      })

      if (duplicateIndex !== -1) {
        if (duplicateIndex !== -1) {
          // Ajouter les deux entrées dupliquées au tableau duplicateEntries
          duplicateEntries.push(device, allDevicesWithHour[duplicateIndex])
        }
      }
    })

    if (duplicateEntries.find((d) => d.id === id)) {
      return true
    }
    return false
  }

  const handleClose = () => {
    onClose()
  }

  const onSubmit = (data) => {
    setDisplayError(null)
    setIsLoading(true)
    // Manage erros not handled by hook form
    if (!deliveriesToCreate.length) {
      setDisplayError(t('multi_calib_form_at_least_one_device'))
      setIsLoading(false)
      return
    }
    Promise.map(
      // BLUEBIRD
      deliveriesToUpdate,
      (d) => {
        return client
          .PATCH('/internal/calibration-delivery/{id}', {
            params: { path: { id: d.idCalibration } },
            body: {
              idDelivery: row.idCalibration,
              delivery_date: dayjs(date)
                .set('hour', data[`hour_${d.idCalibration}`].hour())
                .set('minute', data[`hour_${d.idCalibration}`].minute())
                .set('second', 0)
                .set('millisecond', 0)
                .toDate()
                .toISOString()
            }
          })
          .catch((error) => {
            if (error.code === 'calibration_already_exist_for_silo_date') { setDisplayError(t('calibration_already_exist_for_silo_date')) } else { setDisplayError(error.message) }
            return Promise.reject(error)
          })
      },
      { concurrency: 1 }
    )
      .then(() => {
        Promise.map(
          // BLUEBIRD
          deliveriesToCreate,
          (d) => {
            const payload = {
              source: 'delivery',
              bypass_send_email: true,
              date: dayjs(date)
                .set('hour', data[`hour_${d.id}`].hour())
                .set('minute', data[`hour_${d.id}`].minute())
                .set('second', 0)
                .set('millisecond', 0)
                .toDate()
                .toISOString(),
              silos: [
                {
                  id: d.value.id,
                  tonnage: parseFloat(data[`quantity_${d.id}`])
                }
              ],
              timeSlot: data[`hour_${d.id}`].hour() > 12
                ? 'afternoon'
                : 'morning'

            }

            return client
              .POST('/v1/orders', { body: { ...payload } })
              .catch((error) => {
                if (error.code === 'calibration_already_exist_for_silo_date') { setDisplayError(t('calibration_already_exist_for_silo_date')) } else { setDisplayError(error.message) }
                return Promise.reject(error)
              })
          },
          { concurrency: 1 }
        )
          .then(() => {
            setIsLoading(false)
            onSuccess()
            handleClose()
          })
          .catch((error) => {
            setDisplayError(error.message)

            setIsLoading(false)
          })
      })
      .catch((error) => {
        setDisplayError(error.message)

        setIsLoading(false)
      })
  }

  return (
    <NanoDialog
      open={isOpen}
      onClose={handleClose}
      title={t('calib_delivery_form_title')}
    >
      <DialogContentText>{t('calib_delivery_form_subtitle')}</DialogContentText>
      <Stack
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        display='flex'
        pb={3}
      >
        <Box width={156} height={156} flexShrink={0}>
          <TruckImage />
        </Box>
      </Stack>

      <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Controller
            control={control}
            name='date'
            defaultValue={dayjs()}
            rules={{
              validate: {
                futureDate: (deliveryDate) => {
                  if (!deliveryDate) return
                  const deliveryIsAfterDeviceInstallations = deliveriesToCreate
                    .map((deviceDelivery) => new Date(deviceDelivery.value.device_install_date))
                    .every((deviceInstallDate) => isAfter(new Date(deliveryDate), deviceInstallDate))

                  return deliveryIsAfterDeviceInstallations || t('calib_date_error_past')
                },
                pastDate: (deliveryDate) => {
                  return isBefore(new Date(deliveryDate), new Date()) || t('calib_date_error_future')
                }
              },
              required: t('form_field_required_error_message')
            }}
            render={({ field, fieldState: { error } }) => (
              <NanoSelectDate
                {...field}
                fullWidth
                maxDate={dayjs()}
                label={t('delivery_date')}
                error={!!error}
                disableFuture
                onChange={(data) => {
                  field.onChange(data)
                  setDeliveriesToUpdate([])
                  deliveriesToCreate.forEach(async (device) => {
                    displayDeliveriesThisDay(
                      data,
                      device.value.device_reference
                    )
                  })
                }}
                value={field.value}
                slotProps={{
                  textField: { error: !!error, helperText: error?.message }
                }}
              />
            )}
          />

          {deliveriesToUpdate.map((delivery) => {
            return (
              <Card
                sx={{
                  p: 2,
                  border: `1px solid ${theme.palette.priorityMedium.contrastText}`
                }}
                key={delivery.idCalibration}
              >
                <CardHeader
                  sx={{ px: 1 }}
                  title={`${
                    deliveriesToCreate.find(
                      (d) => d.value.device_reference === delivery.idDevice
                    )?.value?.device_name
                  } (${
                    deliveriesToCreate.find(
                      (d) => d.value.device_reference === delivery.idDevice
                    )?.value?.farm_name
                  })`}
                />
                <Stack display='flex' spacing={2}>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    spacing={1}
                  >
                    <Controller
                      defaultValue={delivery.quantity}
                      control={control}
                      name={`quantity_${delivery.idCalibration}`}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true }}
                          label={t('calib_delivery_quantity')}
                          placeholder={t('string_workspace_filling_unit', { value: t('multi_calib_form_quantity_placeholder') })}
                          fullWidth
                          required
                          disabled
                          type='number'
                          error={!!error}
                          variant='outlined'
                          helperText={error?.message}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`hour_${delivery.idCalibration}`}
                      defaultValue={dayjs(delivery.calibration_date ?? dayjs().startOf('day'))}
                      rules={{
                        validate: (value) => {
                          const isHourExists = checkHourExists(
                            delivery.idCalibration
                          )

                          if (isHourExists) { return t('delivery_exists_today_hour') }
                          const date = new Date(getValues().date)
                          const dateTime = new Date(value)
                          if (date && !isNaN(dateTime)) {
                            const dateWithTime = new Date(
                              date.getFullYear(),
                              date.getMonth(),
                              date.getDate(),
                              dateTime.getHours(),
                              dateTime.getMinutes()
                            )
                            return (
                              isBefore(dateWithTime, new Date(Date.now())) ||
                              t('calib_date_error_future')
                            )
                          }
                          if (isNaN(dateTime)) { return t('form_field_required_error_message') }
                        },
                        required: t('form_field_required_error_message')
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <NanoSelectHour
                          {...field}
                          required
                          fullWidth
                          label={t('calib_delivery_time')}
                          onChange={(data) => {
                            field.onChange(data)
                          }}
                          value={field.value}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message
                            }
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Card>
            )
          })}

          {devicesToDeliverIds.map((deviceId) => {
            return (
              <Card sx={{ p: 2 }} key={deviceId}>
                <CardHeader
                  sx={{ px: 1 }}
                  title={
                    deliveriesToCreate.find((d) => d.id === deviceId)
                      ? `${
                          deliveriesToCreate.find((d) => d.id === deviceId)
                            .value.device_name
                        } (${
                          deliveriesToCreate.find((d) => d.id === deviceId)
                            .value.farm_name
                        })`
                      : t('multi_calib_form_select_devices_label')
                  }
                  action={
                    <IconButton
                      onClick={() => {
                        setDevicesToDeliverIds((oldIds) =>
                          oldIds.filter((id) => id !== deviceId)
                        )
                        setDeliveriesToCreate((previousDevices) =>
                          previousDevices.filter(
                            (devices) => devices.id !== deviceId
                          )
                        )
                        const idDevice = deliveriesToCreate.find(
                          (d) => d.id === deviceId
                        )?.value?.device_reference
                        const lastDevice = deliveriesToCreate.filter(
                          (d) =>
                            d.value.device_reference ===
                            deliveriesToCreate.find((d) => d.id === deviceId)
                              ?.value?.device_reference
                        )
                        if (
                          lastDevice.length < 2 &&
                          deliveriesToUpdate.find(
                            (delivery) => delivery.idDevice === idDevice
                          )
                        ) {
                          setDeliveriesToUpdate((previousDeliveries) =>
                            previousDeliveries.filter(
                              (delivery) => delivery.idDevice !== idDevice
                            )
                          )
                        }
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                />
                <Stack display='flex' spacing={2}>
                  {!deliveriesToCreate.find((d) => d.id === deviceId) && (
                    <Controller
                      control={control}
                      defaultValue={null}
                      name={`device_${deviceId}`}
                      rules={{
                        required: t('form_field_required_error_message')
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <SelectDevices
                          label={null}
                          error={error}
                          onChange={async (_, data) => {
                            field.onChange(data)
                            setDeliveriesToCreate((previousDevices) =>
                              previousDevices.filter(
                                (devices) => devices.id !== deviceId
                              )
                            )
                            setDeliveriesToCreate((previousDevices) => [
                              ...previousDevices,
                              { id: deviceId, value: data }
                            ])
                            if (data) {
                              displayDeliveriesThisDay(
                                date,
                                data.device_reference
                              )
                            }
                          }}
                          value={field.value}
                          multiple={false}
                          required
                        />
                      )}
                    />
                  )}
                  {/* {deliveriesThisDay[0].idDevice ===  */}
                  {deliveriesToCreate.find((d) => d.id === deviceId) && (
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      spacing={1}
                    >
                      <Controller
                        defaultValue={null}
                        control={control}
                        name={`quantity_${deviceId}`}
                        rules={{
                          validate: (value) =>
                            value > 0 ||
                            t(
                              'calib_delivery_form_quantity_greater_than_zero_tons'
                            ),
                          max: {
                            value:
                              deliveriesToCreate?.find((d) => d.id === deviceId)
                                .value.capa_max * 2,
                            message: t('calib_form_capa_max', {
                              count: deliveriesToCreate?.find(
                                (d) => d.id === deviceId
                              ).value.capa_max
                            })
                          },
                          required: t('form_field_required_error_message')
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            label={t('calib_delivery_quantity')}
                            // InputLabelProps={{ shrink: true }}
                            placeholder={t('string_workspace_filling_unit', { value: t('multi_calib_form_quantity_placeholder') })}
                            fullWidth
                            required
                            autoFocus
                            type='number'
                            error={!!error}
                            variant='outlined'
                            helperText={error?.message}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name={`hour_${deviceId}`}
                        defaultValue={dayjs().startOf('day')}
                        rules={{
                          validate: (value) => {
                            const date = new Date(getValues().date)
                            const dateTime = new Date(value)
                            const isHourExists = checkHourExists(deviceId)

                            if (isHourExists) { return t('delivery_exists_today_hour') }

                            if (date && !isNaN(dateTime)) {
                              const dateWithTime = new Date(
                                date.getFullYear(),
                                date.getMonth(),
                                date.getDate(),
                                dateTime.getHours(),
                                dateTime.getMinutes()
                              )
                              return (
                                isBefore(dateWithTime, new Date(Date.now())) ||
                                t('calib_date_error_future')
                              )
                            }
                            if (isNaN(dateTime)) { return t('form_field_required_error_message') }
                          },
                          required: t('form_field_required_error_message')
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <NanoSelectHour
                            {...field}
                            required
                            fullWidth
                            label={t('calib_delivery_time')}
                            onChange={(data) => {
                              field.onChange(data)
                            }}
                            value={field.value}
                            slotProps={{
                              textField: {
                                error: !!error,
                                helperText: error?.message
                              }
                            }}
                          />
                        )}
                      />
                    </Stack>
                  )}
                </Stack>
              </Card>
            )
          })}

          <ButtonWhite
            variant='outlined'
            fullWidth
            withAdd
            onClick={() => {
              setDisplayError(null)
              setDevicesToDeliverIds((oldIds) => [...oldIds, uuid4()])
            }}
            text={t('add_silo_and_qty')}
          />

          {displayError
            ? (
              <Alert severity='error'>{displayError}</Alert>
              )
            : (
                deliveriesToUpdate.length > 0 && (
                  <Alert severity='warning'>
                    {t('delivery_exists_today', {
                      device: `${
                    deliveriesToUpdate[deliveriesToUpdate.length - 1]
                      .device_name
                  } (${
                    deliveriesToUpdate[deliveriesToUpdate.length - 1].poi_name
                  })`
                    })}
                  </Alert>
                )
              )}
          <LoadingButton
            loading={isLoading}
            type='submit'
            loadingPosition='start'
            fullWidth
            onClick={handleSubmit(onSubmit)}
          >
            {t('confirm_and_send')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

CalibMultiDeliveryModal.propTypes = propTypes
CalibMultiDeliveryModal.defaultProps = defaultProps
// #endregion

export default CalibMultiDeliveryModal
