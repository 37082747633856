import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { TableCell, Typography, Skeleton, Stack, Tooltip, IconButton } from '@mui/material'
import { ExpandIcon } from '../icons/index'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  device_name: PropTypes.string,
  idDevice: PropTypes.string
}

const defaultProps = { loading: false, device_name: '', idDevice: '' }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function DeviceExpandedCell ({ loading, device_name: deviceName, idDevice }) {
  const openInNewTab = (url) => {
    window.open(url)
  }
  const deviceNameRef = useRef()

  const isOverflowActive = (event) => {
    if (!event) return
    return event.offsetHeight < event.scrollHeight || event.offsetWidth < event.scrollWidth
  }

  return (
    <TableCell>
      <Stack direction='row' alignItems='center' justifyContent='flex-start' sx={{ mr: 3 }}>
        <Tooltip title={(isOverflowActive(deviceNameRef.current)) ? deviceName : null}>
          <Typography ref={deviceNameRef} sx={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {loading ? <Skeleton width='80%' /> : deviceName}
          </Typography>
        </Tooltip>
        <IconButton
          role='link'
          onClick={() => openInNewTab(`/devices/${idDevice}`)}
          sx={{ marginLeft: 1 }}
        >
          {loading
            ? <Skeleton width='25%' />
            : <ExpandIcon />}
        </IconButton>
      </Stack>
    </TableCell>
  )
}

DeviceExpandedCell.propTypes = propTypes
DeviceExpandedCell.defaultProps = defaultProps
// #endregion

export default DeviceExpandedCell
