import React, { useState, useEffect, useContext, useCallback } from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs } from '@mui/material'
import FilterBar from '../../../shared/components/FilterBar'
import ButtonAdd from '../../../shared/components/ButtonAdd'
import { ToastContext } from '../../../shared/contexts'
import AdminPlanningPage from './AdminPlanningPage'
import InviteRecipientsModal from './InviteRecipientsModal'
import SimpleCell from '../../../shared/components/SimpleCell'
import OrderRecipientsCell from './OrderRecipientsCell'
import { client } from '../../../shared/apiClient'
import NanoTable from '../../../shared/components/NanoTable'
import { useWorkspaceSettingsStore } from '../../../shared/store'
import NotFoundData from '../../../shared/components/NotFoundData'
import { MessageStarIcon } from '../../../shared/icons/index'
import DefaultRecipientModal from './DefaultRecipientModal'

const columns = (t, onSuccess, onSuccessRemove) => [
  {
    header: t('group_other'),
    width: 250,
    format: (row) => (
      <SimpleCell loading={row.loading} data={row.group_name} />
    )
  },
  {
    header: t('order_recipients'),
    width: 900,
    format: (row) => (<OrderRecipientsCell {...row} onSuccess={onSuccess} onSuccessRemove={onSuccessRemove} />)
  }
]

const propTypes = {}
const defaultProps = {}

/**
 *
 */
function AdminDeliveryPage () {
  const tabsInfo = [{ value: 'planning' }, { value: 'order_recipients' }]
  const [formAddRecipientIsOpen, setFormAddRecipientIsOpen] = useState(false)
  const [formDefaultRecipientIsOpen, setFormDefaultRecipientIsOpen] = useState(false)
  const toastContext = useContext(ToastContext)
  const [valueTab, setValueTab] = useState(0)
  const { t } = useTranslation()
  const [dataLoading, setDataLoading] = useState(false)
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const disableOrders = useWorkspaceSettingsStore((state) => state.getSetting('disableOrders'))?.value === 'true'

  const fetchGroups = useCallback(() => {
    setDataLoading(true)
    client.GET('/v1/groups', { params: { query: { is_poi: true } } })
      .then((response) => {
        setData(response.data)
      })
      .finally(() => {
        setDataLoading(false)
      })
  }, [client])

  useEffect(() => {
    fetchGroups()
  }, [])

  const onSuccess = () => {
    toastContext.sendMessage(t('success_order_recipients'))
    fetchGroups()
  }

  const onSuccessRemove = () => {
    toastContext.sendMessage(t('success_remove_recipients'))
    fetchGroups()
  }

  const filteredData = search
    ? data.filter(
      (data) => [data.group_name, ...data.order_recipient_emails].some(text => text.toLowerCase().includes(search.toLowerCase()))
    )
    : data

  if (disableOrders) { return <NotFoundData customText='no_access_orders_section' /> } else {
    return (
      <>
        <FilterBar
          withTabs
          isSearch={valueTab === 1}
          searchFieldPlaceholder='search'
          searchFieldValue={search}
          searchFieldAction={setSearch}
        >
          <Tabs variant='scrollable' value={valueTab} onChange={(_, newValue) => { setValueTab(newValue) }}>
            {tabsInfo.map((tab) => <Tab key={tab.value} sx={{ padding: 0 }} label={t(tab.value)} />)}
          </Tabs>
          {valueTab === 1
            ? (
              <>
                <ButtonAdd text={t('default_recipient')} onClick={() => { setFormDefaultRecipientIsOpen(true) }} startIcon={<MessageStarIcon stroke='white' />} />
                <ButtonAdd text={t('alert_form_select_user_label')} onClick={() => { setFormAddRecipientIsOpen(true) }} />
              </>
              )
            : <></>}
        </FilterBar>
        {valueTab === 0 && (
          <AdminPlanningPage />
        )}
        {valueTab === 1 && (
          <NanoTable
            columns={columns(t, onSuccess, onSuccessRemove)}
            data={filteredData}
            loading={dataLoading}
          />
        )}

        {formAddRecipientIsOpen && (
          <>
            {valueTab === 1 && (
              <InviteRecipientsModal
                isOpen={formAddRecipientIsOpen}
                onClose={() => { setFormAddRecipientIsOpen(false) }}
                onSuccess={onSuccess}
              />
            )}
          </>
        )}

        {formDefaultRecipientIsOpen && (
          <>
            {valueTab === 1 && (
              <DefaultRecipientModal
                isOpen={formDefaultRecipientIsOpen}
                onClose={() => { setFormDefaultRecipientIsOpen(false) }}
                onSuccess={onSuccess}
              />
            )}
          </>
        )}
      </>
    )
  }
}

AdminDeliveryPage.propTypes = propTypes
AdminDeliveryPage.defaultProps = defaultProps

export default AdminDeliveryPage
