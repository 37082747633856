import React, { forwardRef, useEffect, useState } from 'react'
// import styled from 'styled-components';
import PropTypes from 'prop-types'
import { TextField, InputAdornment, FormControlLabel, Checkbox } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as EyeCloseIcon } from '../icons/EyeCloseIcon.svg'
import { ReactComponent as EyeOpenIcon } from '../icons/EyeOpenIcon.svg'
import { useTranslation } from 'react-i18next'

const propTypes = {
  label: PropTypes.string,
  validatePassword: PropTypes.bool,
  value: PropTypes.string.isRequired,
  /** Invoke at each change indicate if it is valid */
  isValid: PropTypes.func
}

const defaultProps = {
  label: '',
  validatePassword: false,
  isValid: () => null
}

/**
 * @type {import('react').ForwardRefExoticComponent<PropTypes.InferProps<propTypes & Partial<import('@mui/material').TextFieldProps>>>}
 */
const NanoPassword = forwardRef(({ label, validatePassword, isValid, value, ...props }, ref) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
  const handleShowPassword = () => setShowPassword(!showPassword)

  const validationState =
    {
      number: /\d/.test(value),
      characterLength: !!value && value.length >= 8,
      capital: /[A-Z]/.test(value),
      character: /[!@#$%^&*()]/.test(value)
    }
  useEffect(() => { isValid(Object.values(validationState).every(v => v)) }, [value])

  return (
    <>
      <TextField
        ref={ref}
        {...props}
        fullWidth
        spellCheck='false'
        type={showPassword ? 'text' : 'password'}
        label={label}
        InputProps={{
          placeholder: label,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                role='switch'
                aria-label='toggle password visibility'
                aria-controls='password'
                aria-expanded={showPassword}
                onClick={handleShowPassword}
                edge='end'
              >
                {showPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {validatePassword &&
        Object.entries(validationState).map(([label, checked], i) => (
          <FormControlLabel
            key={i}
            control={<Checkbox sx={{ pt: 0, pb: 0 }} value={label} />}
            checked={checked}
            label={t(`settings_password_checkbox_${label}`)}
            disabled
          />
        ))}
    </>
  )
})

NanoPassword.propTypes = propTypes
NanoPassword.defaultProps = defaultProps

export default NanoPassword
