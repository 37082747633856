import React from 'react'
import PropTypes from 'prop-types'
import NanoDialog from '../../shared/components/NanoDialog'
import SearchDevice from '../../shared/components/SearchDevice'
import { getRequest } from '../../shared/apiClient'

// import { useSessionStore } from '../../shared/store';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

const fetchOptions = async (search = undefined) => {
  const params = {
    search
  }
  const response = await getRequest('v1/devices/', { params })

  const allDevices = response.data
    .map((device) => ({
      label: device.device_name,
      others: { ...device }
    }))
  return [...allDevices]
}

// #endregion

// #region component
const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired

}

const defaultProps = {
  isOpen: false

}

/**
 *
 */
function SearchDeviceModal ({ isOpen, onClose }) {
  return (
    <NanoDialog
      open={isOpen}
      onClose={onClose}
      scroll='body'
      PaperProps={{
        sx: {
          width: '50%',
          minHeight: '45%',
          maxHeight: '90%'
          // Ajoutez une classe CSS ou un style pour définir la hauteur maximale
        }
      }}
    >
      <SearchDevice
        fetchOptions={(search) => fetchOptions(search)} // retrieve all the options (initial state, pagination)
      />
    </NanoDialog>
  )
}

SearchDeviceModal.propTypes = propTypes
SearchDeviceModal.defaultProps = defaultProps
// #endregion

export default SearchDeviceModal
