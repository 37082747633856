import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography, Skeleton, Stack, useTheme, useMediaQuery } from '@mui/material'
import Level from './Level'
import isNumber from '../utils/isNumber'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  level: PropTypes.shape({
    free_space: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string
    }),
    filling: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string
    }),
    percent: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string
    })
  }),
  is_fake: PropTypes.bool.isRequired,
  is_combined: PropTypes.bool.isRequired
}

const defaultProps = { loading: false, level: {} }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function LevelWithTonsAndMissingWeight ({ level, loading, is_combined: isCombined, is_fake: isFake }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const mobileformat = !useMediaQuery(theme.breakpoints.up('sm'))

  return (

    <Stack flexShrink={3} display='flex' direction='row' alignItems='center' spacing={2}>
      <Stack>
        <Level loading={loading} levelPercent={level.percent?.value} isBig isCombined={isCombined} isFake={isFake} />
      </Stack>
      <Stack display='flex'>
        <Typography variant={mobileformat ? 'h6' : 'h4'}>
          {loading ? <Skeleton /> : t('data_type_unit', { value: level.filling })}
        </Typography>
        {!mobileformat &&
          <Typography variant='body2' color='text.secondary'>
            {loading ? <Skeleton /> : t('free_space_with_value', { value: t('data_type_unit', { value: level?.free_space }) })}
          </Typography>}
      </Stack>
    </Stack>
  )
}

LevelWithTonsAndMissingWeight.propTypes = propTypes
LevelWithTonsAndMissingWeight.defaultProps = defaultProps
// #endregion

export default LevelWithTonsAndMissingWeight
