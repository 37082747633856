import React from 'react'
import PropTypes from 'prop-types'
import { devicePropTypes } from '../model'
import DeviceTitle from '../../../shared/components/DeviceTitle'
import NanoPaper from '../../../shared/components/NanoPaper'
import { Button, Grid, Stack } from '@mui/material'
import { EditIcon } from '../../../shared/icons'
import { useTranslation } from 'react-i18next'
import QuickActions from './QuickActions'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  device: devicePropTypes,
  loading: PropTypes.bool,
  handleEdit: PropTypes.func.isRequired,
  displayOrder: PropTypes.bool
}

const defaultProps = { loading: false, displayOrder: false }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
const DeviceFirstSection = ({ device, loading, handleEdit, displayOrder }) => {
  const { t } = useTranslation()

  return (
    <Grid container justifyContent='space-between' spacing={2} alignItems='center'>
      <Grid item xl={6} lg={5} md={6} sm={6}>
        <NanoPaper>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <DeviceTitle {...device} deviceDetail />
            {!loading &&
              <Button
                startIcon={<EditIcon />}
                style={{ backgroundColor: 'white', color: 'black', border: '1px solid #EFEFF3' }}
                onClick={handleEdit}
              >
                {t('edit')}
              </Button>}
          </Stack>
        </NanoPaper>
      </Grid>
      <Grid item sm={6}>
        <QuickActions device={device} loading={loading} displayOrder={displayOrder} />
      </Grid>
    </Grid>
  )
}

DeviceFirstSection.propTypes = propTypes
DeviceFirstSection.defaultProps = defaultProps
// #endregion

export default DeviceFirstSection
