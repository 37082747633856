import React, { useCallback } from 'react'
import { Button, useTheme, useMediaQuery } from '@mui/material'

function QuickButton ({ Icon, color, title, fromDashboard, onClick, ...otherButtonProps }) {
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))

  // on mobile, only show the icon
  const icon = <Icon color={fromDashboard ? theme.palette[color].main : theme.palette[color].contrastText} height={24} width={24} />

  const buttonProps = {
    variant: fromDashboard ? 'outlined' : 'contained',
    sx: { p: 1 },
    color
  }

  const catchOnClick = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
    if (onClick) {
      onClick(e)
    }
  }, [onClick])

  return mobileFormat
    ? <Button {...buttonProps} {...otherButtonProps} onClick={catchOnClick}>{icon}</Button>
    : <Button {...buttonProps} {...otherButtonProps} onClick={catchOnClick} startIcon={icon}>{title}</Button>
}

export default QuickButton
