import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import TopPage from '../layout/TopPage'
import DashboardDisplayMode from './models/displayModeModel'
import ViewSelect from './ViewSelect'
import { getRequest } from '../../shared/apiClient'
import DeviceMapView from './map/DeviceMapView'
import { updateDashboardFilters, useDashboardStore, useDeviceContentStore, useGroupStore } from '../../shared/store'
import { BIN_SORT } from '../../shared/models/binSort'
import FilterBar from '../../shared/components/FilterBar'
import SwitchDisplayMode from './SwitchDisplayModeToggle'
import FilterModal from './FilterModal'
import { CalibrateIcon } from '../../shared/icons'
import SearchDeviceModal from './SearchDeviceModal'
import NanoSelectSort from '../../shared/components/NanoSelectSort'
import LoadingPage from '../../shared/components/LoadingPage'
import DeviceListView from '../device/components/DeviceListView'
import ButtonWhite from '../../shared/components/ButtonWhite'
import { useLocation, useNavigate } from 'react-router-dom'
import ExportForm from './ExportForm'
import { ToastContext } from '../../shared/contexts'
import CalibMultiDeliveryModal from '../device/components/CalibMultiDeliveryModal'
import ConfirmationModal from '../device/components/ConfirmationModal'
// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

const InlineBadge = styled(Badge)(({ theme }) => ({
  paddingRight: theme.spacing(4),
  '& .MuiBadge-badge': {
    right: 12
  }
}))

function Dashboard () {
  const { t } = useTranslation()
  const filterModel = useDashboardStore((state) => state.filterModel)
  const dataGroup = useGroupStore((state) => state.dataGroup)
  const dataDeviceContent = useDeviceContentStore((state) => state.dataDeviceContent)
  const location = useLocation()
  const navigate = useNavigate()
  const [displayMode, setDisplayMode] = useState((new URLSearchParams(location.search).get('tab')) ?? DashboardDisplayMode.List)
  const [groupLoading, setGroupLoading] = useState(true)
  const [customViews, setCustomViews] = useState([])
  const [formFilterIsOpen, setFormFilterIsOpen] = useState(false)
  const [badgeFilters, setBadgeFilters] = useState(null)
  const [formSearchIsOpen, setFormSearchIsOpen] = useState(false)
  const [formExportIsOpen, setFormExportIsOpen] = useState(false)
  const [formCalibIsOpen, setFormCalibIsOpen] = useState(false)
  const [confirmationPopupIsOpen, setConfirmationPopupIsOpen] = useState(false)
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  const toastContext = useContext(ToastContext)

  const handleValidateFilters = (filters) => {
    updateDashboardFilters(filters)
  }
  const fetchCustomView = useCallback(
    () => {
      getRequest('v2/custom-views').then((result) => {
        setCustomViews(result.data)
      })
    },
    [customViews, setCustomViews]
  )

  useEffect(() => {
    setBadgeFilters((filterModel.deviceContentsFilters?.length ? 1 : 0) + (filterModel.groupsFilters?.length ? 1 : 0) + (filterModel.remainingDaysFilters?.length ? 1 : 0) + (filterModel.levelsFilters?.length ? 1 : 0) + (filterModel.statusFilters?.length ? 1 : 0) + (filterModel.batteryFilters?.length ? 1 : 0))
  }, [filterModel])

  useEffect(() => {
    if (!groupLoading) setGroupLoading(true)
    Promise.all([
      useDeviceContentStore.getState().fetchData(),
      useGroupStore.getState().fetchData(),
      fetchCustomView()
    ]).finally(() => { setGroupLoading(false) })
  }, [])

  const handleDisplayMode = (newMode) => {
    setDisplayMode(newMode)
    navigate(`/devices?tab=${newMode}`)
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%' }}>
      <TopPage
        renderTitle={(
          <ViewSelect groups={dataGroup} customViews={customViews} onUpdate={() => fetchCustomView()} />
        )}
        actions={(
          mobileFormat
            ? (null)
            : (<SwitchDisplayMode defaultValue={displayMode} onChange={handleDisplayMode} />)
        )}
      />

      <FilterBar
        searchAction={() => { setFormSearchIsOpen(true) }}
        isSearch
        isExport
        isCalib
        calibAction={() => { setFormCalibIsOpen(true) }}
        exportAction={() => { setFormExportIsOpen(true) }}
      >
        <ButtonWhite
          variant='outlined'
          startIcon={<CalibrateIcon />}
          endIcon={badgeFilters ? <InlineBadge color='secondary' badgeContent={badgeFilters} /> : null}
          onClick={() => { setFormFilterIsOpen(true) }}
          text={t('filter')}
        />
        <NanoSelectSort
          withGroups={(filterModel.viewSelected.id === 0 || filterModel.viewSelected.id === undefined || Object.keys(filterModel.customViewSelected).length > 0)}
          value={filterModel.sortValue}
          handleSelect={(value, asc) => updateDashboardFilters({ sortValue: value, ascMode: asc })}
          disabled={displayMode === DashboardDisplayMode.Map}
          options={Object.values(BIN_SORT)}
        />
      </FilterBar>

      {formFilterIsOpen && (
        <FilterModal
          isOpen={formFilterIsOpen}
          onSuccess={handleValidateFilters}
          onClose={() => { setFormFilterIsOpen(false) }}
          filterModel={filterModel}
          dataGroup={dataGroup}
          dataDeviceContent={dataDeviceContent}
        />
      )}

      {(
      // @ts-ignore
        displayMode === DashboardDisplayMode.Map)
        ? (groupLoading)
            ? (<LoadingPage withLogo={false} />)
            : <DeviceMapView groups={dataGroup} />
        : <DeviceListView />}

      {formSearchIsOpen && <SearchDeviceModal
        isOpen={formSearchIsOpen}
        onClose={() => { setFormSearchIsOpen(false) }}
                           />}

      {formExportIsOpen && <ExportForm
        isOpen={formExportIsOpen}
        groups={dataGroup}
        onSuccess={() => toastContext.sendMessage(t('export_snackbar_downloaded'))}
        onClose={() => { setFormExportIsOpen(false) }}
                           />}

      {formCalibIsOpen &&
        <CalibMultiDeliveryModal
          isOpen={formCalibIsOpen}
          onSuccess={() => setConfirmationPopupIsOpen(true)}
          onClose={() => { setFormCalibIsOpen(false) }}
        />}

      {confirmationPopupIsOpen && (
        <ConfirmationModal
          isOpen={confirmationPopupIsOpen}
          onClose={() => setConfirmationPopupIsOpen(false)}
        />
      )}

    </div>
  )
}

Dashboard.defaultProps = defaultProps
Dashboard.propTypes = propTypes
// #endregion

export default Dashboard
