import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Badge, IconButton, Skeleton, Stack, TableCell, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import NanoMenuItem from '../../../shared/components/NanoMenuItem'
import { CalibrateIcon, OrderIcon, SiloIcon, Battery33PercentIcon, NotificationIcon } from '../../../shared/icons'
import { ToastContext } from '../../../shared/contexts'

// #region constants
const avatarsByNotification = {
  alert: {
    default: (theme) => <Avatar sx={{ backgroundColor: 'priorityHigh.main', mr: 3 }}><SiloIcon color={theme.palette.priorityHigh.contrastText} /></Avatar>
  },
  sensorInfos: {
    calibrating: (theme) => <Avatar sx={{ backgroundColor: 'priorityMedium.main', mr: 3 }}><CalibrateIcon fill={theme.palette.priorityMedium.contrastText} /></Avatar>,
    lowBattery: (theme) => <Avatar sx={{ backgroundColor: 'priorityHigh.main', mr: 3 }}><Battery33PercentIcon color={theme.palette.priorityHigh.contrastText} height={24} width={24} /></Avatar>,
    calibrated: (theme) => <Avatar sx={{ backgroundColor: 'ok.main', mr: 3 }}><CalibrateIcon fill={theme.palette.ok.contrastText} /></Avatar>
  },
  order: {
    default: (theme) => <Avatar sx={{ backgroundColor: 'priorityMedium.main', mr: 3 }}><OrderIcon stroke={theme.palette.priorityMedium.contrastText} /></Avatar>
  },
  default: () => <>TODO</>
}

const actionsByNotification = {
  alert: (navigate, resourceId, payload) => navigate(`/devices/${payload.device_reference}`),
  sensorInfos: (navigate, resourceId, payload) => navigate(`/devices/${payload.device_reference}`),
  order: (navigate, resourceId) => navigate(`/orders/${resourceId}`)
}

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  status: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  payload: PropTypes.shape({ poi: PropTypes.string }).isRequired,
  created_at: PropTypes.string.isRequired,
  typeId: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  changeStatus: PropTypes.func.isRequired
}

const defaultProps = { loading: false }

/**
 * Represent one Row of noticiation because only one column
 * use a lot of predefined constant because multiple use case possible.
 * Each row must have an icon, informations and callback when clicked
 *
 */
function NotificationCell ({ loading, status, action, created_at: createdAt, payload, typeId, resourceId, id, changeStatus }) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const toastContext = useContext(ToastContext)
  const icon = avatarsByNotification[action] ? avatarsByNotification[action][typeId] ?? avatarsByNotification[action].default : avatarsByNotification.default
  return (
    <TableCell sx={{ backgroundColor: status !== 'open' ? 'greyBackground.main' : 'transparent', height: '100%', px: 2 }}>
      {loading
        ? (
          <>
            {/* Match the size of the listItem */}
            <Skeleton width='50%' sx={{ fontSize: 14 }} />
            <Skeleton width='30%' sx={{ fontSize: 12 }} />
          </>)
        : (
          <Stack
            justifyContent='space-between'
            flexDirection='row'
            height='100%'
            sx={{
              '& .MuiIconButton-root': {
                display: 'none'
              },
              ':hover': {
                '& .MuiIconButton-root': {
                  display: 'flex'
                }
              }
            }}
          >

            <NanoMenuItem
              sx={{ height: '100%', flexGrow: 1, ':hover': { backgroundImage: 'none', backgroundColor: 'transparent' } }} // important for center display, override background inherited from theme have a clean hover from Nanotable
              icon={icon(theme)}
              onClick={() => {
                if (actionsByNotification[action]) {
                  actionsByNotification[action](navigate, resourceId, payload)
                  if (status !== 'open') changeStatus(id, status) // we force open status on click if not already open
                } else toastContext.handleComingSoon()
              }}
              listItemTextProps={{
                sx: { textWrap: 'wrap' },
                primary: payload?.value
                  ? t(`new_notification_${action}.${typeId}`, payload)
                  : t(`notification_${action}.${typeId}`, payload),
                secondary: `${new Date(createdAt).toLocaleString(i18n.language, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              })} · ${payload.poi ?? 'unknown'}`
              }}
            />
            <IconButton onClick={() => changeStatus(id, status)}>
              <Badge
                sx={{
                  '& .MuiBadge-badge': {
                    right: 6,
                    top: 5
                  }
                }} variant='dot' invisible={status === 'open'} color='priorityHighRevert'
              >
                <NotificationIcon />
              </Badge>
            </IconButton>
          </Stack>
          )}
    </TableCell>
  )
}

NotificationCell.propTypes = propTypes
NotificationCell.defaultProps = defaultProps
// #endregion

export default NotificationCell
