const envDomainMap = {
  io: 'production',
  tech: 'preproduction',
  dev: 'develop'
}
/**
 * Get the env based on the url.
 * Sample url : workspace.nanolike.env
 * @returns {String}
 */
const getEnv = () => {
  const a = window.location.hostname.split('.')
  return a[a.length - 1] // dev tech io
}

/**
 * @returns {{workspace: string, ENDPOINT: string, environment: string}}
 */
const loadEnv = () => {
  const isLocalhost = Boolean(window.location.hostname.includes('localhost'))
  if (isLocalhost) {
    if (!process.env.REACT_APP_WORKSPACE || !process.env.REACT_APP_API_ENDPOINT) {
      throw new Error(
        'Workspace and API endpoint must be set in .env.local file.\nSee .env.local.sample for more information.'
      )
    }

    return {
      // defined in .env.local
      workspace: process.env.REACT_APP_WORKSPACE,
      ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
      environment: 'develop'
    }
  }

  return {
    ENDPOINT: `//api.nanolike.${getEnv()}/`,
    environment: envDomainMap[getEnv()],
    workspace: window.location.hostname.split('.')[0]
  }
}

export default loadEnv
