import React from 'react'
import PropTypes from 'prop-types'
import { client } from '../../apiClient'
import SelectDevicesOrGroups from './SelectDevicesOrGroups'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
    section: PropTypes.string
  })),
  readOnly: PropTypes.bool,
  withCombined: PropTypes.bool
}

const defaultProps = {
  label: '',
  error: null,
  value: [],
  required: false,
  readOnly: false,
  withCombined: false
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function SelectDevicesAndGroups ({ label, onChange, error, value, required, readOnly, withCombined }) {
  const fetchOptions = async (search = undefined, combined = withCombined) => {
    const query = {
      search,
      is_poi: true
    }

    // @ts-ignore @TODO: change doc error on api side
    const response = await client.GET('/v1/groups', { params: { query } })
    const groups = response.data.map((group) => {
      const devices =
    !combined
      ? group.devices
        .filter(device => !device.is_combined)
      : group.devices
      return {
        label: group.group_name,
        id: group.group_id,
        section: 'group',
        ...group,
        devices
      }
    })
    return groups
  }
  return (
    <SelectDevicesOrGroups
      label={label}
      readOnly={readOnly}
      onChange={onChange}
      error={error}
      value={value}
      required={required}
      fetchOptions={fetchOptions}
    />
  )
}

SelectDevicesAndGroups.propTypes = propTypes
SelectDevicesAndGroups.defaultProps = defaultProps
// #endregion

export default SelectDevicesAndGroups
