import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MoreAction from '../../../shared/components/MoreAction'
import { TableCell } from '@mui/material'

// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  handleUpdateOrDelete: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['level', 'delivery']).isRequired
}

const defaultProps = {}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function MoreActionCalib ({ handleUpdateOrDelete, type }) {
  const { t } = useTranslation()

  return (
    <TableCell align='right' onClick={(e) => e.stopPropagation()}>

      <MoreAction
        deleteItemFunction={() => handleUpdateOrDelete('delete')}
        deleteItemAlertTitle={t(`admin_calib_${type}_confirm_deletion_title`)}
        deleteItemAlertDescription={t('action_deletion_default_description')}
        onClickEdit={() => handleUpdateOrDelete('update')}
      />
    </TableCell>
  )
}

MoreActionCalib.propTypes = propTypes
MoreActionCalib.defaultProps = defaultProps
// #endregion

export default MoreActionCalib
