import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@mui/material'
import { ReactComponent as AddIcon } from '../icons/AddIcon.svg'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired
}
// Sett required and no default because if a  button we need an action
const defaultProps = { text: '' }

/**
 *
 */
function ButtonAdd ({ text, onClick, ...props }) {
  return (
    <Button startIcon={<AddIcon stroke='white' />} onClick={onClick} {...props}>
      {text}
    </Button>
  )
}

ButtonAdd.propTypes = propTypes
ButtonAdd.defaultProps = defaultProps
// #endregion

export default ButtonAdd
