import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Skeleton, Stack, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { dateMediumWithoutYearAndWithoutTime } from '../../shared/utils/dateUtils'
import { RemainingDaysIcon, RemainingDaysIconBold } from '../../shared/icons'
import isNumber from '../utils/isNumber'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  remaining_days: PropTypes.number
}
const defaultProps = { loading: false, remaining_days: null }

/**
 *
 */
function RemainingDays ({ loading, remaining_days: remainingDays }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const addDays = (date, days) => {
    date.setDate(date.getDate() + days)
    return date
  }
  const newDate = addDays(new Date(), remainingDays)
  return (
    <>
      {loading
        ? (
          <Skeleton variant='rounded' width={24} height={24} />
          )
        : (
          <Stack display='flex' direction='column' alignItems='center' spacing={1}>
            {remainingDays > 5 || remainingDays === null
              ? (
                <RemainingDaysIcon width={24} height={24} />
                )
              : (
                <RemainingDaysIconBold
                  width={24}
                  height={24}
                  fill={theme.palette.priorityHigh.contrastText}
                />
                )}
            <Typography align='center' variant='h6'>
              {loading
                ? (
                  <Skeleton />
                  )
                : (
                    // @ FIXME : Use the Intl.relativeTime with today, tomorrow but no specific words
                    isNumber(remainingDays) ? t('remaining_date', { count: remainingDays, relativeTime: { numeric: remainingDays === 2 ? 'always' : 'auto' } }) : '-'
                  )}
            </Typography>

            <Typography noWrap align='center' variant='body2' color='text.secondary'>
              {isNumber(remainingDays) ? dateMediumWithoutYearAndWithoutTime(newDate) : '-'}
            </Typography>
          </Stack>
          )}
    </>
  )
}

RemainingDays.propTypes = propTypes
RemainingDays.defaultProps = defaultProps
// #endregion

export default RemainingDays
