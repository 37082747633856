const batteryFilterModel = Object.freeze([
  {
    name: 'battery_full',
    value: 'high'
  },
  {
    name: 'battery_half_full',
    value: 'medium'
  },
  {
    name: 'battery_almost_empty',
    value: 'low'
  },
  {
    name: 'battery_empty',
    value: 'empty'
  }
])
export default batteryFilterModel
