import React, { useContext } from 'react'
import { Chip } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PoiIcon } from '../../shared/icons/PoiIconBold.svg'
import { GroupCellContext, ToastContext } from '../../shared/contexts'
import { deleteRequest } from '../../shared/apiClient'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  user_group_membership_id: PropTypes.string.isRequired,
  user_id: PropTypes.string.isRequired,
  group: PropTypes.shape({
    group_name: PropTypes.string.isRequired
  }).isRequired
}

const defaultProps = {}

/**
 *
 */
function GroupChip ({ user_group_membership_id: ugmId, group, user_id: userId }) {
  const { t } = useTranslation()
  const groupCellContext = useContext(GroupCellContext)
  const toastContext = useContext(ToastContext)
  const handleDelete = () => {
    deleteRequest(`v1/users/${userId}/group-memberships/${ugmId}`).then(() => {
      groupCellContext.rmvGroup(ugmId)
      toastContext.sendMessage(t('access_deleted'))
    })
  }

  return (
    <Chip
      onDelete={handleDelete}
      icon={<PoiIcon />}
      label={group.group_name}
    />
  )
}

GroupChip.propTypes = propTypes
GroupChip.defaultProps = defaultProps
// #endregion

export default GroupChip
