import PropTypes from 'prop-types'

export const devicePropTypes = {
  device_name: PropTypes.string.isRequired,
  capa_max: PropTypes.number.isRequired,
  device_reference: PropTypes.string.isRequired,
  device_content_id: PropTypes.string,
  deviceContent: PropTypes.string,
  device_id: PropTypes.string.isRequired,
  metadata: PropTypes.shape({
    problem_cause: PropTypes.string,
    info_to_calibrate: PropTypes.string
  }).isRequired,
  status: PropTypes.string.isRequired,
  group_poi: PropTypes.shape({
    group_poi_name: PropTypes.string,
    group_poi_id: PropTypes.string
  })
}

/** @typedef {PropTypes.InferProps<devicePropTypes>} Device */
