import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as BinConnectPicto } from '../icons/BinConnectLogoPicto.svg'
import { ReactComponent as BinConnectLogoBlue } from '../icons/BinConnectLogoBlue.svg'
import { Box } from '@mui/material'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = { color: PropTypes.string }

const defaultProps = { color: 'white' }

/**
 *
 */
function SideBarHeader ({ color }) {
  const Logo = color === 'white' ? BinConnectPicto : BinConnectLogoBlue
  return (
    <Box pr={5} pl={5} pt={4} pb={3}>
      <Logo />
    </Box>
  )
}

SideBarHeader.propTypes = propTypes
SideBarHeader.defaultProps = defaultProps
// #endregion

export default SideBarHeader
