import { Avatar, Skeleton, Tooltip } from '@mui/material'
import React from 'react'
// import styled from 'styled-components';
import PropTypes from 'prop-types'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  /** if not set no tooltip is displaed at all */
  tooltip: PropTypes.string,
  /** what must be displayed in the avatar */
  children: PropTypes.node,
  /** handle loading state with skeleton */
  loading: PropTypes.bool
}

const defaultProps = {
  tooltip: null,
  children: null,
  loading: false
}

/**
 * @param {(PropTypes.InferProps<NanoAvatar.propTypes> & Partial<import('@mui/material').AvatarProps>)} props
 */
function NanoAvatar ({ tooltip, children, loading, ...props }) {
  return (
    loading
      ? (
        <Skeleton variant='circular'>
          <Avatar />
        </Skeleton>
        )
      : (
        <Tooltip title={tooltip}>
          <Avatar {...props}>
            {children}
          </Avatar>
        </Tooltip>
        )
  )
}

NanoAvatar.propTypes = propTypes
NanoAvatar.defaultProps = defaultProps
// #endregion

export default NanoAvatar
