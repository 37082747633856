import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Alert, Stack, TextField, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { client } from '../../../shared/apiClient'
import NanoDialog from '../../../shared/components/NanoDialog'
import { sendEvent } from '../../../shared/utils/analyticsUtils'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    device_content_type: PropTypes.string.isRequired,
    min_quantity_tons: PropTypes.number
  })
}

const defaultProps = {
  isOpen: false,
  row: {}

}

/**
 *
 */
function AdminDeviceContentTypeModal ({ isOpen, onClose, onSuccess, row }) {
  const { t } = useTranslation()
  const isUpdateMode = Object.keys(row).length > 0
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayAlert] = useState(null)

  const { handleSubmit, control } = useForm()

  const handleClose = () => {
    onClose()
  }

  const onSubmit = (data) => {
    setDisplayAlert(null)
    setIsLoading(true)

    let payload = {}

    payload = {
      device_content_type: data.dc_type_name,
      min_quantity_tons: parseFloat(data.min_quantity_tons)
    }
    if (isUpdateMode) {
      client.PATCH('/v1/workspace/device-content-types/{id}',
        {
          params: { path: { id: row.id } },
          body: { ...payload }
        })
        .then(() => {
          sendEvent('device_content_type_updated')
          setIsLoading(false)
          handleClose()
          onSuccess()
        }).catch((err) => {
          setDisplayAlert(err.message)
          setIsLoading(false)
        })
    } else {
      client.POST('/v1/workspace/device-content-types', { body: { ...payload } })
        .then(() => {
          sendEvent('device_content_type_created')
          setIsLoading(false)
          handleClose()
          onSuccess()
        }).catch((err) => {
          setDisplayAlert(err.message)
          setIsLoading(false)
        })
    }
  }
  return !isOpen
    ? null
    : (
      <NanoDialog
        open={isOpen}
        onClose={handleClose}
        title={isUpdateMode ? t('admin_feed_type_form_title_update') : t('create_new_device_content_type')}
      >
        <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack spacing={3}>
            <Controller
              control={control}
              name='dc_type_name'
              defaultValue={row.device_content_type}
              rules={{ required: t('form_field_required_error_message') }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('device_content_type')}
                  autoFocus
                  error={!!error}
                  helperText={error?.message}
                  required
                  inputProps={{ maxLength: 255 }}
                />
              )}
            />

            <Controller
              control={control}
              defaultValue={row.min_quantity_tons ?? []}
              rules={{
                valueAsNumber: true,
                validate: (value) => (value >= 0) || t('specify_quantity')
              }}
              name='min_quantity_tons'
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('min_quantity_tons_title')}
                  type='number'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            {!!displayError && <Alert severity='error'>{displayError}</Alert>}
            <LoadingButton
              loading={isLoading}
              type='submit'
              loadingPosition='start'
            // startIcon={<Icon />} // temporary fix : // forum to fix the error message: https://github.com/mui/material-ui/issues/31235
              fullWidth
            >
              {t('validate')}
            </LoadingButton>
          </Stack>
        </Box>
      </NanoDialog>
      )
}

AdminDeviceContentTypeModal.propTypes = propTypes
AdminDeviceContentTypeModal.defaultProps = defaultProps
// #endregion

export default AdminDeviceContentTypeModal
