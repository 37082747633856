import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Grid, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SiloIcon } from '../../shared/icons/SiloIcon.svg'
import { dateLongWithTime } from '../../shared/utils/dateUtils'
import NanoAvatar from '../../shared/components/NanoAvatar'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool.isRequired,
  device_name: PropTypes.string.isRequired,
  notification_timestamp: PropTypes.string.isRequired,
  poi_name: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  alertType: PropTypes.string,
  alertName: PropTypes.string,
  lastIndex: PropTypes.bool
}

const defaultProps = { lastIndex: false, alertType: null, alertName: null }

/**
 *
 */
function AlertHistorySection ({ key, loading, alertType, alertName, device_name: deviceName, lastIndex, notification_timestamp: notificationTimestamp, offending_value: value, poi_name: poiName }) {
  const { t } = useTranslation()
  const theme = useTheme()

  let valueToDisplay = value

  /**
   * The Alerts API can't give us the units linked to dataType. We have to do the mapping ourselves using formatters
   */
  if (alertType === 'level_t') {
    valueToDisplay = t('number_workspace_filling_unit', { value })
  } else if (alertType === 'level_percent') {
    valueToDisplay = t('data_type_unit', { value: { value, unit: 'percent' } })
  } else if (alertType === 'remainingDays') {
    valueToDisplay = t('data_type_unit', { value: { value, unit: 'day' } })
  }

  return (
    <Grid item xs={12} key={key}>
      {loading
        ? (
          <Skeleton />
          )
        : (
          <>
            <Stack p={1} alignItems='center' direction='row'>
              <NanoAvatar sx={{ width: 40, height: 40, backgroundColor: 'priorityHigh.main' }}>
                <SiloIcon color={theme.palette.priorityHigh.contrastText} />
              </NanoAvatar>
              <Stack p={1} alignItems='flex-start' direction='column'>

                <Stack alignItems='center' direction='row'>
                  {t(`new_notification_alert.${alertType}`, { device_name: deviceName, poi: poiName, alert_name: alertName, value: valueToDisplay })}
                </Stack>
                <Stack alignItems='center' direction='row'>
                  <Typography variant='body2' color='text.secondary'>
                    {`${dateLongWithTime(notificationTimestamp)}`}
                    {poiName ? ` - ${poiName}` : ''}

                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            {!lastIndex && <Divider />}
          </>

          )}
    </Grid>
  )
}

AlertHistorySection.propTypes = propTypes
AlertHistorySection.defaultProps = defaultProps
// #endregion

export default AlertHistorySection
