import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MoreAction from '../../../shared/components/MoreAction'
import { deleteRequest } from '../../../shared/apiClient'
import { DialogContext } from '../../../shared/contexts'
import { sendEvent } from '../../../shared/utils/analyticsUtils'

// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  id: PropTypes.string,
  handleEdit: PropTypes.func.isRequired
}

const defaultProps = {}

/**
 *
 */
function MoreActionAdminDeviceContent ({ id, handleEdit }) {
  const { t } = useTranslation()
  const dialogContext = useContext(DialogContext)

  const fetchData = () => deleteRequest(`v1/workspace/device-contents/${id}`).then(
    () => {
      sendEvent('device_content_deleted')
      dialogContext.deletedOrEdited()
    }
  )

  return (
    <MoreAction
      deleteItemFunction={fetchData}
      deleteItemAlertTitle={t('admin_feed_confirm_deletion_title')}
      deleteItemAlertDescription={t('admin_feed_confirm_deletion_description')}
      onClickEdit={handleEdit}
    />
  )
}

MoreActionAdminDeviceContent.propTypes = propTypes
MoreActionAdminDeviceContent.defaultProps = defaultProps
// #endregion

export default MoreActionAdminDeviceContent
