import { TableCell, Skeleton, Stack, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState, useMemo, useEffect, useContext } from 'react'
import GroupChip from './GroupChip'
import { GroupCellContext, ToastContext } from '../../shared/contexts'
import { ReactComponent as AddIcon } from '../../shared/icons/AddIcon.svg'
import AddGroupsDialog from '../../shared/components/AddGroupsDialog'
import { client } from '../../shared/apiClient'
import { useTranslation } from 'react-i18next'
import NanoChip from '../../shared/components/NanoChip'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  idUser: PropTypes.string.isRequired,
  email: PropTypes.string,
  group_memberships: PropTypes.arrayOf(PropTypes.shape({})),
  workspaceRole: PropTypes.shape({ idRole: PropTypes.string, global: PropTypes.number })
}

const defaultProps = { loading: false, group_memberships: [], email: '' }

/**
 *
 */
function UserGroupCell ({ group_memberships: gm, email, loading, idUser, workspace_role: workspaceRole }) {
  const { t } = useTranslation()
  const [groupsValue, setGroupsValue] = useState(gm)
  const [openNewAccessModal, setOpenNewAccessModal] = useState(false)
  const toastContext = useContext(ToastContext)
  const value = useMemo(() => ({
    groups: groupsValue,
    rmvGroup: (id) => {
      setGroupsValue(
        groupsValue.filter((g) => g.user_group_membership_id !== id)
      )
    }
  }), [groupsValue])
  useEffect(() => {
    // @TODO :  When V1 deprecated, remove this filter 
    setGroupsValue(gm.filter((g) => g.group.is_poi).map(g => ({ ...g, id: g.group_id, label: g.group.group_name, section: 'group' })))
  }, [gm])
  const handleValidate = async (newAccess) => {
    const oldAccess = [...groupsValue]
    // CHANGE SERVICE WHEN REWORK OF BACK
    // ADD NEW ACCESS
    return Promise.all(newAccess.filter(niu => !oldAccess.map(g => g.id).includes(niu.id)).map((g) =>
      client.POST('/v1/users/{id}/group-memberships', {
        params: { path: { id: idUser } },
        body: {
          group_id: g.id,
          group_role_id: 'Admin' // default user
        }
      })))
      .then((responses) => {
        // we update new access based on response to be well displayed
        for (const r of responses) {
          const index = newAccess.findIndex(a => a.group_id === r.data[0].group_id)
          newAccess[index] = { ...r.data[0], id: r.data[0].group_id, section: 'group', label: r.data[0].group.group_name }
        }
        // DELETE FORMER ACCESS
        return Promise.all(oldAccess
          .filter(old => !newAccess.map(niu => niu.id).includes(old.id))
          .map((g) =>
            client.DELETE('/v1/users/{id}/group-memberships/{membershipId}', {
              params: { path: { id: idUser, membershipId: g.user_group_membership_id } }
            })))
      })
      .then(() => {
        setGroupsValue(newAccess)
        toastContext.sendMessage(t('access_added'))
      })
  }
  return (
    <TableCell>
      {
        loading
          ? <Skeleton variant='rounded' />
          : (
            <GroupCellContext.Provider value={value}>
              {!workspaceRole.global
                ? (
                  <>
                    <Stack direction='row' spacing={1} alignItems='center' alignContent='flex-start'>
                      <IconButton sx={{ backgroundColor: 'grey.main' }} onClick={() => setOpenNewAccessModal(true)}>
                        <AddIcon />
                      </IconButton>
                      {!!groupsValue.length && <GroupChip {...groupsValue[0]} />}
                      {groupsValue.length > 1 && (
                        <IconButton onClick={() => setOpenNewAccessModal(true)}>
                          <Typography variant='body1' color='text.secondary'>
                            +
                            {groupsValue.length - 1}
                          </Typography>
                        </IconButton>)}

                    </Stack>
                    {openNewAccessModal && (<AddGroupsDialog open={openNewAccessModal} title={t('add_access_to_user_title')} subtitle={email} onClose={() => setOpenNewAccessModal(false)} groups={groupsValue} handleValidate={handleValidate} />)}
                  </>
                  )
                : (
                  <NanoChip
                    sx={{ backgroundColor: 'grey.main' }}
                    section='group'
                    label={t('all_groups')}
                  />
                  )}
            </GroupCellContext.Provider>
            )
      }
    </TableCell>
  )
}

UserGroupCell.propTypes = propTypes
UserGroupCell.defaultProps = defaultProps
// #endregion

export default UserGroupCell
