import create from 'zustand'
import env from '../config/env.js'
import { getRequest, loginRequest, clearSession, client, refresh } from './apiClient.js'
// import { loginRequest } from './openApiClient'
import dayjs from 'dayjs'
import { Crisp } from 'crisp-sdk-web'
import { sendEvent } from './utils/analyticsUtils.js'
import { weekday } from './utils/dateUtils.js'
import { combine } from 'zustand/middleware'
import { BIN_SORT } from './models/binSort'

export const useSessionStore = create(
  combine({
    /** @type { import('../../openapiDoc').components['schemas']['me']} */
    user: null,
    /** @type { import('../../openapiDoc').components['schemas']['client']} */
    currentClient: null,
    /** @type {boolean} */
    connected: false, // boolean te determinate if user is fully connected and can access to the dashboard page
    /** @type {{[string]: never, all: number}} */
    unreadNotificationsCount: { all: 0 },
    refreshMe: () => null
  }, (set, get) => ({
  /**
   * Refresh ME
   */
    refreshMe: async () => {
      const response = await client.GET('/v2/me', {})
      if (response) set({ user: response.data, currentClient: response.data.clients.find(c => c.name === env().workspace.toUpperCase()) })
    },
    /**
  * Refresh the token and refresh ME
  */
    refreshSession: async () => {
      await refresh().then(() => get().refreshMe()).catch(err => console.error(err))
    },

    signin: async (/** @type {import('openapi-fetch').RequestBodyOption< import('../../openapiDoc').operations['/internal/login/post']>['body']} */ payload, /** @type boolean */rememberMe, /** @type boolean */ isRegistered) => {
      const response = await loginRequest(payload, rememberMe)
      if (response) {
        Crisp.session.reset()
        Crisp.setTokenId(response.idUser)
        set({ user: response, currentClient: response.clients.find(c => c.name === env().workspace?.toUpperCase()), connected: isRegistered })
      }
    },
    logout: () => {
      Crisp.session.reset()
      clearSession()
      set({ user: null, connected: false })
    },
    fetchUnread: async () => client.GET('/internal/notifications/getuserwebnotificationscount', {}).then((res) => {
      const current = res.data.find(x => x.idClient === get().currentClient.idClient)
      if (current) set({ unreadNotificationsCount: { ...current.unread, all: Object.values(current.unread).reduce((prev, next) => prev + next, 0) } })
      else set({ unreadNotificationsCount: { all: 0 } })
    })
  }))
)

/**
 * Generate the dashboard fetch query parameters for the given filter model
 * @param {Object} params
 * @param {DashboardFilterModel} params.filterModel
 * @param {number} [params.pageToCall]
 * @param {number} [params.sizeToCall]
 * @return {{ filters: string, ordering: string, paginationDefined: string }}
 */
function dashboardQueryParams ({ filterModel, pageToCall = 1, sizeToCall = 15 }) {
  let filters = ''
  let ordering = ''
  let paginationDefined = ''

  const { deviceContentsFilters, levelsFilters, remainingDaysFilters, groupsFilters, statusFilters, batteryFilters, sortValue, ascMode } = filterModel
  // filters
  if (deviceContentsFilters?.length) filters += `&device_content_id=${deviceContentsFilters.join(',')}`
  if (levelsFilters?.length) filters += `&level_filters=${levelsFilters.join(',')}`
  if (remainingDaysFilters?.length) filters += `&remaining_days=${remainingDaysFilters.join(',')}`
  if (groupsFilters?.length) filters += `&group_ids=${groupsFilters.join(',')}`
  if (statusFilters?.length) filters += `&status=${statusFilters.join(',')}`
  if (batteryFilters) filters += `&battery_status=${batteryFilters}`
  // ordering
  if (sortValue.value.length) ordering += `&ordering=${ascMode ? `${sortValue.value}` : `-${sortValue.value}`}`
  // page
  if (pageToCall) paginationDefined += `&page=${pageToCall}`
  if (sizeToCall) paginationDefined += `&page_size=${sizeToCall}`

  return { filters, ordering, paginationDefined }
}

/**
 * Send analytics event after a dashboard request was made
 * @param {DashboardFilterModel} filterModel
 */
function sendDashboardAnalytics (filterModel) {
  const { deviceContentsFilters, levelsFilters, remainingDaysFilters, groupsFilters, statusFilters, batteryFilters, sortValue } = filterModel

  // send google analytics event
  if (deviceContentsFilters.length || levelsFilters.length || remainingDaysFilters.length || groupsFilters.length || statusFilters.length || batteryFilters) {
    const params = {}
    if (deviceContentsFilters.length) params.nb_contents = deviceContentsFilters.length
    if (levelsFilters.length) params.nb_levels = levelsFilters.length
    if (remainingDaysFilters.length) params.nb_remaining_days = remainingDaysFilters.length
    if (groupsFilters.length) params.nb_groups = groupsFilters.length
    if (statusFilters.length) params.nb_status = statusFilters.length
    if (batteryFilters) params.value_battery = batteryFilters
    if (sortValue) params.sort = sortValue.name
    sendEvent('filter_dashboard', params)
  }
}

/**
 * True if the two filter models represent the same dashboard view
 * @param {DashboardFilterModel} filterModelA
 * @param {DashboardFilterModel} filterModelB
 * @returns {Boolean}
 */
function isFilterModelMatching (filterModelA, filterModelB) {
  const paramsA = dashboardQueryParams({ filterModel: filterModelA })
  const paramsB = dashboardQueryParams({ filterModel: filterModelB })

  return paramsA.filters === paramsB.filters && paramsA.ordering === paramsB.ordering
}

/**
 *
 * @typedef {Object} DashboardFilterModel
 * @property {Array} viewSelected
 * @property {Array} deviceContentsFilters
 * @property {Object} customViewSelected
 * @property {Array} levelsFilters
 * @property {Array} remainingDaysFilters
 * @property {Array} groupsFilters
 * @property {Array} statusFilters
 * @property {Array} batteryFilters
 * @property {Object} sortValue
 * @property {boolean} ascMode
 */

/**
   * @typedef {Object} DashboardPagination
   * @property {number} page
   * @property {number} [pageSize]
   * @property {number} [pageCount]
   * @property {number} [rowCount]
   */

export const useDashboardStore = create(combine({
  data: [],
  /** @type {DashboardPagination} */
  pagination: { page: 1 },
  dataGroup: [],
  viewSelected: {},
  customViewSelected: {
    deviceContent: [],
    groups: [],
    levels: [],
    remainingDays: [],
    status: []
  },
  /** @type {DashboardFilterModel} */
  filterModel: {
    viewSelected: [{ name: 'all_bins', id: '0' }],
    deviceContentsFilters: [],
    customViewSelected: {},
    levelsFilters: [],
    remainingDaysFilters: [],
    groupsFilters: [],
    statusFilters: [],
    batteryFilters: null,
    sortValue: BIN_SORT.FARM_NAME, // par défaut
    ascMode: true
  }
}, (set, get) => ({

  setViewSelected: (group) => set({ viewSelected: group }),

  emptyDashboard: () => set({ data: [], pagination: { page: 0 } }),

  setData: (data) => set({ data }),

  fetchData: async (pageToCall = 1, sizeToCall = 15) => {
    const initialFilterModel = get().filterModel
    const { filters, ordering, paginationDefined } = dashboardQueryParams({ filterModel: initialFilterModel, pageToCall, sizeToCall })

    // send request
    const res = await getRequest(`v2/dashboard?${filters}${ordering}${paginationDefined}`)
    const { page, pageSize, rowCount, pageCount, results } = res.data

    const currentFilterModel = get().filterModel
    if (!isFilterModelMatching(currentFilterModel, initialFilterModel)) {
      // discard fetch result as they no longer match the current view
      return
    } else if (page !== get().pagination.page + 1) {
      // discard as it is not the next page
      return
    }

    set((state) => ({ data: [...state.data, ...results], pagination: { page, pageSize, rowCount, pageCount } }))

    sendDashboardAnalytics(initialFilterModel)
  }
}))

)
export const updateDashboardFilters = (filter) => useDashboardStore.setState((state) => ({ filterModel: { ...state.filterModel, ...filter } }))

export const useDeviceContentStore = create(combine({
  /** @type {{ name: string, value: string}[]} */
  dataDeviceContent: []

}, (set) => ({
  fetchData: async () => client.GET('/v1/workspace/device-contents', {}).then((result) => {
    if (result.data.length) {
      set({
        dataDeviceContent: result.data.map((dc) => ({
          name: dc.device_content, value: dc.id
        }))
      })
    }
  })
}
)))

export const useGroupStore = create(combine({
  dataGroup: []
},
(set) => ({
  setData: (dataGroup) => set({ dataGroup }),
  fetchData: async () => client.GET('/v1/groups', { params: { query: { is_poi: true, withRelated: 'devices.deviceCombinedDevices,devices.deviceCombinedDevice,devices.groups,devices.deviceContent', ordering: 'group_name' } } }).then((result) => {
    set({ dataGroup: /** @type {import('../../openapiDoc').components['schemas']['group'][]} */(result.data) })
  })
})))

export const useUserStore = create(combine({
  /** @type {import('../../openapiDoc').components['schemas']['user'][]} */
  dataUser: []
}, (set) => ({
  fetchData: async () => {
    getRequest('v1/users').then((result) => {
      set({ dataUser: /** @type {import('../../openapiDoc').components['schemas']['user'][]} */ (result.data.results) })
    })
  }
})))

export const useWorkspaceSettingsStore = create((set, get) => ({
  _data: [],
  holidays: [],
  getSetting: (key) => get()._data.find((setting) => setting.key === key),
  setDataSetting: (key, newValue) => {
    set((state) => {
      const updatedData = state._data.map((setting) => {
        if (setting.key === key) {
          return { ...setting, value: newValue }
        }
        return setting
      })
      return { _data: updatedData }
    })
  },
  fetchData: async () => {
    const result = await client.GET('/v1/workspace/settings', {})

    /**
       * We use dayjs because these dates will be used
       * in the MUI calendar component which works with datejs
       */
    // @ts-ignore TODO: fix doc openapi
    const orderDatesDisabledSettings = result.data.results?.find((setting) => setting.key === ('orderDatesDisabled'))?.value?.split(',')
    const orderDatesDisabled = orderDatesDisabledSettings?.map(date => dayjs(`${date}`, 'M/D/YYYY'))
    const holidays = [...(orderDatesDisabled?.length > 0 ? orderDatesDisabled : [])]
    // @ts-ignore TODO: fix doc openapi
    const orderWeekdaysEnabled = result.data.results?.find((setting) => setting.key === ('orderWeekdaysEnabled'))?.value?.split(',')
    const daysOn = orderWeekdaysEnabled?.map((days) => days.toLowerCase())
    const daysOff = weekday.filter(day => !daysOn?.includes(day.day.toLowerCase()))?.map(day => day.dayIndex)
    // @ts-ignore TODO: fix doc openapi
    set({ _data: result.data.results, holidays, daysOn, daysOff })
  }
}
))
