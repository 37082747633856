import React from 'react'
import PropTypes from 'prop-types'
import DevicePin from './DevicePin'
import { LatLng } from 'leaflet'

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  distance: PropTypes.number.isRequired,
  missingWeight: PropTypes.bool
}

const defaultProps = { missingWeight: false }

function MarkerDevices ({ data, distance, missingWeight }) {
  // Tri des points par coordonnées GPS
  const groupedData = data.reduce((acc, cur) => {
    const key = `${cur.position.position_latitude},${cur.position.position_longitude}`
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(cur)
    return acc
  }, {})

  // // Conversion en tableau de tableaux
  const groupedDataArray = Object.values(groupedData)
  // Modification des coordonnées GPS pour affichage en cercle
  const updatedData = groupedDataArray.map(group => {
    const countWithSameGps = group.length
    const angleStep = 360 / countWithSameGps

    return group.map((device, index) => {
      const angle = index * angleStep
      const angleRad = (angle * Math.PI) / 180
      const newLatitude = device.position.position_latitude + (distance / 111111) * Math.cos(angleRad)
      const newLongitude = device.position.position_longitude + (distance / 111111) * Math.sin(angleRad) / Math.cos((device.position.position_latitude * Math.PI) / 180)
      return {
        ...device,
        position: newLatitude && newLongitude ? new LatLng(newLatitude, newLongitude) : null
      }
    })
  })

  return (
    <>
      {updatedData.map((group, groupIndex) => (
        group.map((device, index) => (
          device.position && <DevicePin
            key={`${groupIndex}-${index}`}
            position={device.position}
            status={device.status}
            levelPercent={device.levels[0]?.percent?.value ?? null}
            device={device}
            missingWeight={missingWeight ? device.levels[0]?.free_space?.value : null}
                             />
        ))
      ))}
    </>

  )
}

MarkerDevices.propTypes = propTypes
MarkerDevices.defaultProps = defaultProps

export default MarkerDevices
