import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MoreAction from '../../../shared/components/MoreAction'
import { Skeleton, Stack, TableCell } from '@mui/material'
import ValidateCommandButton from '../components/ValidateCommandButton'

// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  handleEdit: PropTypes.func.isRequired,
  /** indicate if we should show the edit button */
  disableOrderEdition: PropTypes.bool,
  loading: PropTypes.bool,
  /** indicate if the order is in the future from now */
  isValidated: PropTypes.bool
}

const defaultProps = {
  disableOrderEdition: true,
  isValidated: false,
  loading: false
}

/**
 *
 */
function MoreActionOrderCell ({ handleEdit, disableOrderEdition, loading, isValidated, ...props }) {
  const [validated, setValidated] = useState(isValidated)

  const handleUpdateValidated = (newValidated) => {
    setValidated(newValidated)
  }
  return (
    <TableCell align='right' onClick={(e) => e.stopPropagation()}>
      <Stack spacing={2} direction='row' justifyContent='flex-end' alignItems='center'>
        {loading
          ? <Skeleton width='80%' />
          : (
            <>
              {!disableOrderEdition && <ValidateCommandButton isValidated={isValidated} order={props} onUpdateValidated={handleUpdateValidated} />}
              {!disableOrderEdition && !isValidated && !validated && <MoreAction onClickEdit={handleEdit} />}
            </>
            )}
      </Stack>
    </TableCell>
  )
}

MoreActionOrderCell.propTypes = propTypes
MoreActionOrderCell.defaultProps = defaultProps
// #endregion

export default MoreActionOrderCell
