import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Chip, IconButton, Skeleton, Stack, TableCell, Typography } from '@mui/material'
import { AddIcon, UsersIconBold } from '../../../shared/icons/index'
import NanoChip from '../../../shared/components/NanoChip'
import { client } from '../../../shared/apiClient'
import InviteRecipientsModal from './InviteRecipientsModal'
import NanoDialog from '../../../shared/components/NanoDialog'
import { useWorkspaceSettingsStore } from '../../../shared/store'
import { useTranslation } from 'react-i18next'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  order_recipient_emails: PropTypes.arrayOf(PropTypes.string),
  group_id: PropTypes.string,
  group_name: PropTypes.string,
  onSuccess: PropTypes.func,
  onSuccessRemove: PropTypes.func
}

const defaultProps = { loading: false, order_recipient_emails: [], onSuccess: null, onSuccessRemove: null, group_name: null }

/**
 * @param {PropTypes.InferProps<propTypes>} props
*/
function OrderRecipientsCell ({ order_recipient_emails: orderRecipientEmails, group_id: groupId, group_name: groupName, loading, onSuccess, onSuccessRemove }) {
  const [recipientsValue, setRecipientsValue] = useState(orderRecipientEmails)
  const [openNewAccessModal, setOpenNewAccessModal] = useState(false)
  const [openListModal, setOpenListModal] = useState(false)
  const state = useWorkspaceSettingsStore()
  const coopEmails = state.getSetting('coopEmails')?.value.split(',')
  const { t } = useTranslation()
  useEffect(() => {
    setRecipientsValue(orderRecipientEmails)
  }, [orderRecipientEmails])
  const handleRecipientChange = (recipients) => {
    client.PATCH(`/v1/groups/${groupId}`, { body: { order_recipient_emails: recipients } }).then(() => {
      setRecipientsValue(recipients)
      onSuccessRemove()
    })
  }

  return (
    <TableCell onClick={(e) => e.stopPropagation()}>
      {
      loading
        ? <Skeleton variant='rounded' />
        : (
          <>
            <Stack direction='row' alignItems='center' spacing={1} alignContent='flex-start'>

              <IconButton sx={{ backgroundColor: 'grey.main' }} onClick={() => setOpenNewAccessModal(true)}>
                <AddIcon />
              </IconButton>

              {recipientsValue.length
                ? (
                  <>
                    <NanoChip
                      onDelete={() => handleRecipientChange(recipientsValue.filter(x => x !== recipientsValue[0]))}
                      sx={{
                        backgroundColor: 'grey.main',
                        '& .MuiChip-label': { maxWidth: 250 }
                      }}
                      section='user'
                      label={recipientsValue[0]}
                      tooltip={recipientsValue[0]}
                    />
                    {recipientsValue.length >= 2 && (
                      <NanoChip
                        onDelete={() => handleRecipientChange(recipientsValue.filter(x => x !== recipientsValue[1]))}
                        sx={{
                          backgroundColor: 'grey.main',
                          '& .MuiChip-label': { maxWidth: 250 }
                        }}
                        section='user'
                        label={recipientsValue[1]}
                        tooltip={recipientsValue[1]}
                      />
                    )}
                    {recipientsValue.length > 2 && (
                      <IconButton onClick={() => setOpenListModal(true)}>
                        <Typography variant='body1' color='text.secondary'>
                          +{recipientsValue.length - 2}
                        </Typography>
                      </IconButton>
                    )}
                  </>
                  )
                : (
                  <>
                    {coopEmails.length &&
                    (
                      <NanoChip
                        sx={{ backgroundColor: 'grey.main' }}
                        section='user'
                        label={t('default_recipient')}
                      />
                    )}

                  </>
                  )}
            </Stack>

            {openNewAccessModal &&
              <InviteRecipientsModal
                isOpen={openNewAccessModal}
                onClose={() => { setOpenNewAccessModal(false) }}
                onSuccess={onSuccess}
                defaultGroup={[{ label: groupName, id: groupId, group_id: groupId, section: 'group', order_recipient_emails: orderRecipientEmails }]}
              />}

            {openListModal && (
              <NanoDialog open={openListModal} onClose={() => setOpenListModal(false)}>
                <Stack spacing={2} direction='row' display='flex' justifyContent='flex-start' alignItems='center'>
                  {
          recipientsValue.map((v) => {
            return (
              <Chip
                key={v}
                sx={{
                  backgroundColor: 'grey.main'
                }}
                icon={<UsersIconBold />}
                label={v}
                onDelete={() => handleRecipientChange(recipientsValue.filter(x => x !== v))}

              />
            )
          })
}
                </Stack>
              </NanoDialog>

            )}
          </>
          )
    }
    </TableCell>
  )
}

OrderRecipientsCell.propTypes = propTypes
OrderRecipientsCell.defaultProps = defaultProps
// #endregion

export default OrderRecipientsCell
