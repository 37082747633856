import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { Avatar, AvatarGroup, IconButton, Stack, Typography, useTheme, useMediaQuery, InputAdornment, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { grey } from '@mui/material/colors'
import { ReactComponent as PoiIcon } from '../../shared/icons/PoiIcon.svg'
import { ReactComponent as AddIcon } from '../../shared/icons/AddIcon.svg'
import { ReactComponent as EditIcon } from '../../shared/icons/EditIcon.svg'
import { ReactComponent as FavoriteIcon } from '../../shared/icons/FavoriteIcon.svg'
import { ReactComponent as TrashIcon } from '../../shared/icons/TrashIcon.svg'
import { ReactComponent as ChartIcon } from '../../shared/icons/ChartIcon.svg'
import { ReactComponent as ArrowDownIcon } from '../../shared/icons/ArrowDownIcon.svg'
import { useDashboardStore, updateDashboardFilters, useDeviceContentStore, useGroupStore } from '../../shared/store'
import { BIN_SORT } from '../../shared/models/binSort'
import NanoMenuItem from '../../shared/components/NanoMenuItem'
import { client } from '../../shared/apiClient'
import InviteUserModal from './InviteUserModal'
import CustomViewModal from './CustomViewModal'
import levelFilterModel from '../../shared/models/levelFilter'
import statusFilterModel from '../../shared/models/statusFilter'
import batteryFilterModel from '../../shared/models/batteryFilterModel'
import remainingDaysFilterModel from '../../shared/models/reaminingDaysFilter'
import UserAvatarAndName from '../../shared/components/UserAvatarAndName'
import AccessRoleComponent from '../../shared/components/AccessRoleComponent'
import { sendEvent } from '../../shared/utils/analyticsUtils'
import DialogConfirmAction from '../../shared/components/DialogConfirmAction'
import { SearchIcon } from '../../shared/icons/index'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    group_name: PropTypes.string.isRequired,
    group_id: PropTypes.string.isRequired
  })),
  customViews: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdate: PropTypes.func
}

const defaultProps = {
  groups: [],
  customViews: [],
  onUpdate: null
}

/**
 *
 */
function ViewSelect ({ groups, customViews, onUpdate }) {
  const customView = customViews.map((obj) => ({
    ...obj,
    filters: {
      levelsFilters: obj.filters.levels,
      remainingDaysFilters: obj.filters.remainingDays,
      deviceContentsFilters: obj.filters.deviceContentIds,
      groupsFilters: obj.filters.groupIds,
      statusFilters: obj.filters.status,
      batteryFilters: obj.filters.battery
    }
  }))
  const { t } = useTranslation()
  const dataDeviceContent = useDeviceContentStore((state) => state.dataDeviceContent)
  const dataGroup = useGroupStore((state) => state.dataGroup)
  const filterModel = useDashboardStore((state) => state.filterModel)
  const [userGroup, setUserGroup] = useState([])
  const [title, setTitle] = useState()
  const [InviteUserModalIsOpen, setInvitUserModalIsOpen] = useState(false)
  const [customModalUpdateMode, setCustomModalUpdateMode] = useState(null)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [viewToDelete, setViewToDelete] = useState({ idCustomView: null, nameCustomView: null })
  const [customModalNewMode, setCustomModalNewMode] = useState(false)
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))

  const [optionsGroup, setOptionsGroup] = useState(groups)
  const [optionsCustomViews, setOptionsCustomViews] = useState(customView)
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const fetchOptions = async (search = undefined) => {
    if (search.length > 0) {
      setOptionsGroup(groups.filter((group) => group.group_name.toLowerCase().includes(search.toLowerCase())))
      setOptionsCustomViews(customView.filter((customviews) => customviews.name.toLowerCase().includes(search.toLowerCase())))
    }
  }

  const fetchData = (v) => {
    setLoading(true)
    fetchOptions(v).then(() => {
      if (v) {
        sendEvent('search_group_and_view', { query: v })
      }
    }
    )
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    setLoading(true)
    if (searchQuery.length > 0) {
      if (typeof fetchOptions === 'function') fetchData(searchQuery)
    } else {
      setOptionsGroup(groups)
      setOptionsCustomViews(customView)
    }
    setLoading(false)
  }, [searchQuery])

  useEffect(() => {
    setLoading(true)
    setOptionsGroup(groups)
    if (!filterModel?.viewSelected?.name) setTitle(groups?.length < 1 ? '' : groups?.length === 1 ? groups[0]?.group_name : t('dashboard_title'))
    setLoading(false)
  }, [groups])

  useEffect(() => {
    setLoading(false)
    setOptionsCustomViews(customView)
    setLoading(true)
  }, [customViews])

  const parseFilters = (deviceContentsFilters, groupsFilters, levelsFilters, remainingDaysFilters, statusFilters, batteryFilters, filters) => {
    const remainingDaysFiltersAsString = filters.remainingDaysFilters
      .map((item) => String(item)) // Convertir les valeurs en chaînes
    return {
      deviceContentsFilters: deviceContentsFilters
        .filter((item) => filters.deviceContentsFilters.includes(item.value))
        .map((item) => ({ id: item.value, label: item.name, section: 'device_content' })),
      levelsFilters: levelsFilters
        .filter((item) => filters.levelsFilters.includes(item.value))
        .map((item) => ({ id: item.value, label: item.name, section: 'level_filter' })),
      remainingDaysFilters: remainingDaysFilters
        .filter((item) => (remainingDaysFiltersAsString.includes(item.value)))
        .map((item) => (item.value)), // Uncommented and corrected the remainingDaysFilters part
      groupsFilters: groupsFilters
        .filter((item) => filters.groupsFilters.includes(item.group_id))
        .map((item) => ({ id: item.group_id, label: item.group_name, section: 'group' })),
      statusFilters: statusFilters
        .filter((item) => filters.statusFilters.includes(item.value))
        .map((item) => ({ id: item.value, label: item.name, section: 'status' })),
      batteryFilters: batteryFilters
        .find((item) => filters.batteryFilters === item.value) ?? null
    }
  }

  const handleChange = (event) => {
    if (event.target.value === t('custom_view_create')) {
      event.stopPropagation()
      setCustomModalNewMode(true)
      return
    }
    // @FIXME: j'aimerai pouvoir gérer les deux correctements
    if (event.target.value.filters) {
      sendEvent('dashboard_custom_view_selected')
      updateDashboardFilters({
        deviceContentsFilters: event.target.value.filters.deviceContentsFilters,
        levelsFilters: event.target.value.filters.levelsFilters,
        statusFilters: event.target.value.filters.statusFilters,
        batteryFilters: event.target.value.filters.batteryFilters,
        remainingDaysFilters: event.target.value.filters.remainingDaysFilters,
        groupsFilters: event.target.value.filters.groupsFilters,
        customViewSelected: { name: event.target.value.name, id: event.target.value.idCustomView },
        viewSelected: { name: event.target.value.name, id: event.target.value.idCustomView },
        sortValue: BIN_SORT.FARM_NAME
      })
    } else {
      sendEvent('dashboard_group_selected')
      updateDashboardFilters({
        groupsFilters: (event.target.value.id !== 0 && event.target.value.id != null) ? [event.target.value.id] : [],
        deviceContentsFilters: [],
        levelsFilters: [],
        statusFilters: [],
        batteryFilters: null,
        remainingDaysFilters: [],
        viewSelected: event.target.value,
        customViewSelected: [],
        sortValue: (event.target.value.id !== 0 && event.target.value.id != null) ? BIN_SORT.DEVICE_NAME : BIN_SORT.FARM_NAME
      })
    }
  }
  const handleInvite = () => {
    setInvitUserModalIsOpen(true)
  }

  const handleDeleteView = async () => {
    try {
      await
      client.DELETE('/v2/custom-views/{id}', { params: { path: { id: viewToDelete.idCustomView } } })
      sendEvent('custom_view_deleted')
      const name = t('dashboard_title')
      if (title === viewToDelete.nameCustomView) { // If we are on the custom view, we redirect to the All bins
        updateDashboardFilters({
          groupsFilters: [],
          deviceContentsFilters: [],
          levelsFilters: [],
          statusFilters: [],
          batteryFilters: null,
          remainingDaysFilters: [],
          viewSelected: [{ name, id: '0' }],
          customViewSelected: [],
          sortValue: BIN_SORT.FARM_NAME
        })
        setTitle(name)
      }
      await onUpdate()
      setViewToDelete({ idCustomView: null, nameCustomView: null })
      setConfirmDelete(false)
    } catch (error) {
      console.error(error)
    }
  }
  const fetchUsers = useCallback(
    () => {
      // setDataLoading(true);
      client.GET('/v1/users', {})
        .then((userList) => {
          setUserGroup(userList.data.results
            .filter((user) => (!user.workspace_role.global))
            .map((obj) => {
              // @TODO: Changer l'API pour récupérer les users directement avec le group ID soit /groups soit /users
              // Ici on récupère les user appartenant au groupe sélectionné (@Corentin explique c'est quoi le pb à résoudre)
              const filteredA = obj.group_memberships.filter((subObj) => subObj.group.group_id === filterModel.viewSelected.id)
              return { ...obj, a: filteredA }
            }).filter((obj) => obj.a && obj.a.length > 0)
            // Sort users by first_name, placing empty names at the end
            .sort((a, b) => (a.first_name ? (b.first_name ? a.first_name.localeCompare(b.first_name) : -1) : (b.first_name ? 1 : 0))))
        })
    },
    [filterModel.viewSelected.id, setUserGroup]
  )
  useEffect(() => {
    fetchUsers()
    if (filterModel.viewSelected.name) setTitle(filterModel.viewSelected.name)
  }, [filterModel.viewSelected.name])

  return (
    <Stack direction='row' alignItems='center' alignContent='flex-start' justifyContent='space-between'>
      {!mobileFormat && <Typography mr={2} variant='h1' color='black'> {title} </Typography>}
      <TextField
        variant='outlined'
        value={filterModel.viewSelected}
        onChange={handleChange}
        InputProps={{
          disableUnderline: true,
          style: { marginRight: '20px', lineHeight: 'inherit', color: 'black', height: '40px', backgroundColor: grey.main, fontWeight: 600 }
        }}
        SelectProps={{
          onClose: () => { setSearchQuery(''); setOptionsGroup(groups); setOptionsCustomViews(customView) },
          onOpen: () => { setSearchQuery(''); setOptionsGroup(groups); setOptionsCustomViews(customView) },
          autoWidth: false,
          MenuProps: {
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom'
            },
            transformOrigin: {
              horizontal: 'left',
              vertical: -16
            },
            PaperProps: {
              sx: {
                width: '400px'
              }
            }
          },
          IconComponent: ArrowDownIcon,
          sx: {
            '& .MuiSelect-icon': { top: 'calc(50% - 12px)', right: 2 }, // align the icon with midle of the text, the 12px = heightofIcon / 2
            '& .MuiInput-input': { fontSize: 24, fontWeight: 700, pr: 1 }
          },
          renderValue: () => (t('change_view'))
        }}
        select
      >
        <TextField
          fullWidth
          onKeyDown={(e) => e.stopPropagation()}
          placeholder={t('search')}
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            style: {
              backgroundColor: 'white'
            }
          }}
        />

        {(searchQuery?.length === 0 && groups.length !== 1) &&
          <NanoMenuItem
            sx={{ borderBottom: 'none', height: 70 }}
          // @ts-ignore
            value={{ name: t('dashboard_title'), id: 0 }} // don't correct my type
            icon={<ChartIcon width={20} height={20} />}
            listItemTextProps={{
              primary: t('dashboard_title'),
              // secondary: t('binWithCount2', { count: rowCount }),
              primaryTypographyProps: { variant: 'h5' }
            }}
          />}
        {searchQuery?.length === 0 &&
          <NanoMenuItem
            value={t('custom_view_create')}
            sx={{ borderBottom: 'none', height: 70 }}
            icon={<AddIcon width={20} height={20} />}
            listItemTextProps={{
              sx: { mr: 4 },
              primary: t('custom_view_create'),
              // secondary: t('binWithCount2', { count: 0 }),
              primaryTypographyProps: { variant: 'h5' }

            }}
          />}

        {optionsCustomViews.map(({ name, idCustomView, filters }) => (
          <NanoMenuItem
            key={idCustomView}
            sx={{ borderBottom: 'none', height: 70 }}
            value={{ name, idCustomView, filters }}
            icon={<FavoriteIcon width={20} height={20} />}
            listItemTextProps={{
              sx: { mr: 4 },
              primary: name,
              // secondary: t('binWithCount2', { count: 0 }),
              primaryTypographyProps: { variant: 'h5' }
            }}
          >
            <IconButton onClick={(event) => { event.stopPropagation(); setCustomModalUpdateMode({ id: idCustomView, name }) }} sx={{ backgroundColor: 'grey.main', mr: 2 }}>
              <EditIcon />
            </IconButton>
            <IconButton sx={{ backgroundColor: 'grey.main' }} onClick={(event) => { event.stopPropagation(); setConfirmDelete(true); setViewToDelete({ idCustomView, nameCustomView: name }) }}>
              <TrashIcon />
            </IconButton>
          </NanoMenuItem>
        ))}

        {loading && optionsGroup?.length === 0
          ? (
            <Stack direction='row' alignItems='center' alignContent='center' justifyContent='center'>
              <NanoMenuItem>
                <CircularProgress size={24} />
              </NanoMenuItem>
            </Stack>
            )
          : (
              optionsGroup.map((option) => (
                <NanoMenuItem
                  key={option.group_id}
                  sx={{ borderBottom: 'none', height: 70 }}
                  value={{ name: option.group_name, id: option.group_id }}
                  icon={<PoiIcon width={20} height={20} />}
                  listItemTextProps={{
                    sx: { mr: 4 },
                    primary: option.group_name,
                    secondary: `${t('binWithCount2', { count: option.devices.filter(device => !device.is_combined).length })}${option.devices.filter(device => device.is_combined).length ? ` (${t('combinedWithCount', { count: option.devices.filter(device => device.is_combined).length })})` : ''}`,
                    primaryTypographyProps: { variant: 'h5', noWrap: true }
                  }}
                />
              ))
            )}
      </TextField>
      <AccessRoleComponent
        resource='User'
        operation={['CREATE', 'UPDATE']}
      >
        {(!(filterModel.viewSelected.id === 0 || filterModel.viewSelected.id === undefined || Object.keys(filterModel.customViewSelected).length > 0) &&
        (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row'
          }}
          >
            {(userGroup.length > 0)
              ? (
                // +1 to AvatarGroup for the current user
                <AvatarGroup total={userGroup.length + 1} onClick={handleInvite}>
                  <UserAvatarAndName avatarOnly {...userGroup[0]} />
                </AvatarGroup>

                )
              : (
                <Avatar />
                )}
            <LoadingButton
              onClick={handleInvite}
              sx={{ ml: 2 }}
              style={{ height: 40, borderColor: 'rgba(239, 239, 243, 1)' }}
              variant='outlined'
            >
              {t('invite')}
            </LoadingButton>
          </div>
        )
        )}

        <InviteUserModal
          isOpen={InviteUserModalIsOpen}
          onClose={() => { setInvitUserModalIsOpen(false) }}
          onSuccess={() => { fetchUsers() }}
          userGroup={userGroup}
          filters={filterModel}
        />
      </AccessRoleComponent>

      {/* // @FIXME: j'aimerai pouvoir gérer les deux correctements */}
      {customModalNewMode && (
        <CustomViewModal
          isOpen={customModalNewMode}
          onClose={() => setCustomModalNewMode(false)}
          onSuccess={(filters) => {
            sendEvent('custom_view_created')
            onUpdate()
            updateDashboardFilters({
              deviceContentsFilters: filters.filters.deviceContentIds,
              levelsFilters: filters.filters.levels,
              remainingDaysFilters: filters.filters.remainingDays,
              groupsFilters: filters.filters.groupIds,
              statusFilters: filters.filters.status,
              batteryFilters: filters.filters.battery,
              customViewSelected: { name: filters.name },
              viewSelected: { name: filters.name }
            })
          }}
          filters={
            parseFilters(dataDeviceContent, dataGroup, levelFilterModel, remainingDaysFilterModel(t), statusFilterModel, batteryFilterModel, filterModel)
          }
        />
      )}
      {!!customModalUpdateMode && (
        <CustomViewModal
          isOpen={!!customModalUpdateMode}
          onClose={() => setCustomModalUpdateMode(null)}
          onSuccess={(filters) => {
            sendEvent('custom_view_updated')
            onUpdate()
            updateDashboardFilters({
              deviceContentsFilters: filters.filters.deviceContentIds,
              levelsFilters: filters.filters.levels,
              remainingDaysFilters: filters.filters.remainingDays,
              groupsFilters: filters.filters.groupIds,
              statusFilters: filters.filters.status,
              batteryFilters: filters.filters.battery,
              customViewSelected: { name: filters.name, id: customModalUpdateMode.id },
              viewSelected: { name: filters.name, id: customModalUpdateMode.id }
            })
          }}
          viewName={customModalUpdateMode?.name}
          filters={
            parseFilters(dataDeviceContent, dataGroup, levelFilterModel, remainingDaysFilterModel(t), statusFilterModel, batteryFilterModel, customView.find((cv) => cv.idCustomView === customModalUpdateMode.id)?.filters ?? {})
          }
          updateId={customModalUpdateMode?.id}
        />
      )}

      <DialogConfirmAction
        title={t('dialog_confirm_button_delete_custom_view')}
        open={confirmDelete}
        description={t('confirm_delete_custom_view_text')}
        onClickCancel={() => { setConfirmDelete(false); setViewToDelete({ idCustomView: null, nameCustomView: null }) }}
        onClickConfirm={handleDeleteView}
      />
    </Stack>
  )
}

ViewSelect.defaultProps = defaultProps
ViewSelect.propTypes = propTypes
// #endregion

export default ViewSelect
