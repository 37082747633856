import React from 'react'
import { TableCell } from '@mui/material'
import LevelForecast from '../../../shared/components/LevelForecast'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
function LevelForecastCell ({ ...row }) {
  return (
    <TableCell sx={{ pr: 2 }}>
      <LevelForecast {...row} />
    </TableCell>
  )
}

LevelForecastCell.propTypes = propTypes
LevelForecastCell.defaultProps = defaultProps
// #endregion

export default LevelForecastCell
