import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import levelFilterModel from '../models/levelFilter'
import NanoAutocomplete from './NanoAutocomplete'
import { Typography } from '@mui/material'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string
  }))
}

const defaultProps = {
  value: null
}

/**
 *
 */
function SelectLevel ({ onChange, value, ...props }) {
  const { t } = useTranslation()
  const options = async () => levelFilterModel.map((lvl) => ({
    label: t(lvl.name),
    id: lvl.value,
    section: 'level_filter',
    ...lvl
  }))
  return (
    <NanoAutocomplete
      label={t('level_filter')}
      noOptionsText={(<Typography sx={{ pt: 3 }} align='center'>{t('no_levels_to_add')}</Typography>)}
      fetchOptions={() => options()}
      onChange={onChange}
      value={value}
      {...props}
    />
  )
}

SelectLevel.propTypes = propTypes
SelectLevel.defaultProps = defaultProps
// #endregion

export default SelectLevel
