import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import UserAvatarAndName from '../../../../shared/components/UserAvatarAndName'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  recipients_for_notifications_ids: PropTypes.arrayOf(PropTypes.string),
  users: PropTypes.arrayOf(PropTypes.shape({
    idUser: PropTypes.string
  }))
}

const defaultProps = { loading: false, recipients_for_notifications_ids: [], users: [{}] }

/**
 * @param {PropTypes.InferProps<propTypes>} props
*/
function AlertRecipientsCell ({ recipients_for_notifications_ids: recipientsForNotificationsIds, users, loading }) {
  const [recipientsValue, setRecipientsValue] = useState([])
  useEffect(() => {
    setRecipientsValue(users.filter((user) => recipientsForNotificationsIds.includes(user.idUser)).map((user) => ({ ...user })))
  }, [recipientsForNotificationsIds, users])

  return (
    <TableCell align='left'>
      <UserAvatarAndName loading={loading} {...recipientsValue[0]} />
    </TableCell>
  )
}

AlertRecipientsCell.propTypes = propTypes
AlertRecipientsCell.defaultProps = defaultProps
// #endregion

export default AlertRecipientsCell
