import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useForm, Controller } from 'react-hook-form'
import { Alert, DialogContentText, Stack, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import dayjs from 'dayjs'
import NanoDialog from '../../../shared/components/NanoDialog'
import { ReactComponent as TruckImage } from '../../../shared/icons/TruckImage.svg'
import { client } from '../../../shared/apiClient'
import NanoSelectDate from '../../../shared/components/NanoSelectDate'
import { devicePropTypes } from '../model'
import { isAfter, isBefore } from '../../../shared/utils/dateUtils'
import { sendEvent } from '../../../shared/utils/analyticsUtils'
import NanoSelectHour from '../../../shared/components/NanoSelectHour'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  device: devicePropTypes,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  row: PropTypes.shape({})
}

const defaultProps = {
  isOpen: false,
  device: {},
  row: {}
}

function CalibDeliveryModal ({ isOpen, device, onSuccess, onClose, row }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(false)
  const isCreateMode = Object.keys(device).length > 0
  const deviceName = device?.device_name || row?.device_name
  const poiName = device?.group_poi?.group_poi_name || row?.poi_name
  const deviceRef = device?.device_reference || row?.idDevice
  const installDate = device?.device_install_date || row?.device_install_date
  const quantity = row?.quantity || null
  const calibrationDate = row?.calibration_date ? dayjs(row?.calibration_date) : dayjs().startOf('day')
  const capaMax = device?.capa_max || row?.capa_max
  const { handleSubmit, control, getValues } = useForm()
  const handleClose = () => {
    onClose()
  }

  const onSubmit = (data) => {
    setDisplayError(null)
    setIsLoading(true)

    const date = data.date.toDate()
    date.setHours(0, 0, 0, 0)

    const payload = {
      source: 'delivery',
      bypass_send_email: true,
      // date: date.toISOString(),
      date:
       dayjs(date)
         .set('hour', data.hour.hour())
         .set('minute', data.hour.minute())
         .toDate().toISOString(),
      timeSlot: 'morning',
      silos: [
        {
          id: deviceRef,
          tonnage: parseFloat(data.delivery)
        }
      ]
    }
    if (isCreateMode) {
      client.POST('/v1/orders', { body: { ...payload } }).then(() => {
        sendEvent('calibration_delivery_sent')
        setIsLoading(false)
        handleClose()
        onSuccess()
      }).catch((err) => {
        if (err.message === 'order_already_exist_for_silo_date') { setDisplayError(t('calibration_already_exist_for_silo_date')) } else { setDisplayError(err.message) }
        setIsLoading(false)
      })
    } else {
      client.PATCH('/internal/calibration-delivery/{id}', {
        params: { path: { id: row.idCalibration } },
        body: {
          idDelivery: row.idCalibration,
          source: 'delivery',
          delivery_quantity:
           parseFloat(data.delivery),
          delivery_date: dayjs(date)
            .set('hour', data.hour.hour())
            .set('minute', data.hour.minute())
            .set('second', 0)
            .set('millisecond', 0)
            .toDate().toISOString()
        }
      }).then(() => {
        sendEvent('calibration_delivery_updated')
        setIsLoading(false)
        handleClose()
        onSuccess()
      }).catch((err) => {
        if (err.message === 'order_already_exist_for_silo_date') { setDisplayError(t('calibration_already_exist_for_silo_date')) } else { setDisplayError(err.message) }
        setIsLoading(false)
      })
    }
  }
  return (
    <NanoDialog
      open={isOpen}
      onClose={handleClose}
      title={t('calib_delivery_form_title')}
    >
      <DialogContentText>
        {t('calib_delivery_form_subtitle')}

      </DialogContentText>
      <Stack
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        display='flex'
        pb={3}
      >

        <Box
          width={156}
          height={156}
          flexShrink={0}
        >
          <TruckImage />
        </Box>
      </Stack>

      <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Typography variant='h5'>{`${deviceName} (${poiName})`}</Typography>
          <Stack direction='row' justifyContent='space-between' spacing={1}>
            <Controller
              control={control}
              name='date'
              defaultValue={calibrationDate}
              rules={{
                validate: {
                  pastDate: (value) => {
                    return isBefore(new Date(value), new Date()) || t('calib_date_error_future')
                  },
                  futureDate: (value) => {
                    return isAfter(new Date(value), new Date(installDate)) || t('calib_date_error_past')
                  }
                },
                required: t('form_field_required_error_message')
              }}
              render={({ field, fieldState: { error } }) => (
                <NanoSelectDate
                  {...field}
                  sx={{ flexGrow: 4 }}
                  required
                  fullWidth
                  maxDate={dayjs()}
                  label={`${t('delivery_date')} *`}
                  error={!!error}
                  disableFuture
                  onChange={(data) => {
                    field.onChange(data)
                  }}
                  value={field.value}
                  slotProps={{ textField: { error: !!error, helperText: error?.message } }}
                />
              )}
            />
            <Controller
              control={control}
              name='hour'
              defaultValue={calibrationDate}
              rules={{
                validate: (value) => {
                  const date = new Date(getValues().date)
                  const dateTime = new Date(value)
                  if (date && dateTime) {
                    const dateWithTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), dateTime.getHours(), dateTime.getMinutes())
                    return isBefore(dateWithTime, new Date(Date.now())) || t('calib_date_error_future')
                  }
                }
              }}
              render={({ field, fieldState: { error } }) => (
                <NanoSelectHour
                  {...field}
                  sx={{ flexGrow: 1 }}
                  fullWidth
                  label={t('calib_delivery_time')}
                  onChange={(data) => {
                    field.onChange(data)
                  }}
                  value={field.value}
                  slotProps={{ textField: { error: !!error, helperText: error?.message } }}
                />
              )}
            />
          </Stack>
          <Controller
            control={control}
            rules={{
              validate: (value) => (value > 0) || t('calib_delivery_form_quantity_greater_than_zero_tons'),
              max: { value: capaMax * 2, message: t('calib_form_capa_max', { count: capaMax }) },
              required: t('form_field_required_error_message')
            }}
            defaultValue={quantity}
            name='delivery'
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('calib_delivery_quantity')}
                placeholder={t('string_workspace_filling_unit', { value: t('multi_calib_form_quantity_placeholder') })}
                type='number'
                required
                error={!!error}
                inputProps={{ min: 0 }}
                helperText={error?.message}
              />
            )}
          />

          {!!displayError && <Alert severity='error'>{displayError}</Alert>}
          <LoadingButton
            loading={isLoading}
            type='submit'
            loadingPosition='start'
            fullWidth
          >
            {t('confirm_and_send')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

CalibDeliveryModal.propTypes = propTypes
CalibDeliveryModal.defaultProps = defaultProps
// #endregion

export default CalibDeliveryModal
