import { TableCell, Skeleton, Stack, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext } from 'react'
import NanoChip from '../../../shared/components/NanoChip'
import { AddIcon } from '../../../shared/icons'
import AddGroupsDialog from '../../../shared/components/AddGroupsDialog'
import { patchRequest } from '../../../shared/apiClient'
import { DialogContext } from '../../../shared/contexts'
import { useTranslation } from 'react-i18next'
import { sendEvent } from '../../../shared/utils/analyticsUtils'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string.isRequired,
  device_content: PropTypes.string.isRequired

}

const defaultProps = { loading: false, groups: [{}], doubleChip: false }

/**
 *
 */
function AdminDeviceContentGroupCell ({ loading, groups, id, device_content: deviceContent }) {
  const { t } = useTranslation()
  const [groupsValue, setGroupsValue] = useState([])
  const [openNewAccessModal, setOpenNewAccessModal] = useState(false)

  const dialogContext = useContext(DialogContext)
  useEffect(() => {
    setGroupsValue(groups.map((g) => ({ label: g.group_name, id: g.group_id, section: 'group', ...g })))
  }, [groups])

  const handleValidate = (newGroups) => patchRequest(`v1/workspace/device-contents/${id}`, { group_ids: newGroups.map(g => g.group_id) }).then(() => {
    sendEvent('device_content_updated')
    dialogContext.deletedOrEdited()
  })
  return (
    <TableCell>
      {
      loading
        ? <Skeleton variant='rounded' />
        : (
          <>
            <Stack direction='row' alignItems='center' spacing={1} alignContent='flex-start' justifyContent='flex-start'>
              <IconButton sx={{ backgroundColor: 'grey.main' }} onClick={() => setOpenNewAccessModal(true)}>
                <AddIcon />
              </IconButton>
              <Stack direction='row' alignItems='center' spacing={1} alignContent='flex-start' justifyContent='flex-start'>
                <Stack direction='row' justifyContent='flex-start' alignItems='flex-start' flexWrap='wrap'>

                  {!!groupsValue.length && (
                    <NanoChip
                      sx={{ backgroundColor: 'grey.main', m: 0.5 }}
                      section={groupsValue[0].section}
                      label={groupsValue[0].label}
                      tooltip={groupsValue[0].label}
                    />
                  )}
                  {groupsValue.length > 1 &&
                (
                  <NanoChip
                    sx={{ backgroundColor: 'grey.main', m: 0.5 }}
                    section={groupsValue[1].section}
                    label={groupsValue[1].label}
                    tooltip={groupsValue[1].label}
                  />
                )}
                </Stack>
                {groupsValue.length > 2 && (
                  <IconButton onClick={() => setOpenNewAccessModal(true)}>
                    <Typography variant='body1' color='text.secondary'>
                      +
                      {groupsValue.length - 2}
                    </Typography>
                  </IconButton>)}
              </Stack>
            </Stack>
            {openNewAccessModal && <AddGroupsDialog open={openNewAccessModal} title={t('change_group')} subtitle={deviceContent} onClose={() => setOpenNewAccessModal(false)} groups={groupsValue} handleValidate={handleValidate} />}
          </>
          )
    }
    </TableCell>
  )
}

AdminDeviceContentGroupCell.propTypes = propTypes
AdminDeviceContentGroupCell.defaultProps = defaultProps
// #endregion

export default AdminDeviceContentGroupCell
