import React from 'react'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import siloBackground from '../../shared/images/silo_background.png'
// import panel from '../../shared/images/panel.png';
import { ReactComponent as GreenRay } from '../../shared/images/LandingScreenGreenRay.svg'
import { ReactComponent as BinConnectLogo } from '../../shared/icons/BinConnectLogo.svg'
import { Box } from '@mui/material'
import LangComponent from '../../shared/components/LangComponent'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

function LeftPanel (props) {
  const { t } = useTranslation()
  return (
    <Grid container spacing={0} sx={{ height: '100vh', backgroundColor: 'rgb(12, 5, 82)' }}>

      <Grid
        item
        container
        direction='column'
        sm={6}
        xs={12}
        justifyContent='space-between'
        alignItems='left'
        padding={5}
        sx={{
          background: `url(${siloBackground}), rgb(15, 11, 55)`,
          backgroundBlendMode: 'hard-light',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'right',
          backgroundPositionY: 'top'
        }}
      >
        <Grid item sx={{ opacity: { xs: 0, sm: 100 } }}>
          <BinConnectLogo />
        </Grid>
        <Grid item>
          <Stack spacing={2}>
            <Typography variant='h1' color='white'>
              {t('signin_left_panel_title')}
            </Typography>
            <GreenRay />
          </Stack>
        </Grid>
        <Grid item sx={{ opacity: { xs: 0, sm: 100 } }}>
          <Typography variant='caption'>
            {t('all_rights_reserved')}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        sm={6}
        direction='column'
        sx={{
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: { xs: '40px 40px 0 0', sm: 0 }
        }}
      >
        <LangComponent sx={{ position: 'absolute', top: 0, right: 0, p: 5, width: 'auto' }} />
        <Box
          sx={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 400
          }}
        >
          {props.children}
        </Box>
      </Grid>
    </Grid>
  )
}

LeftPanel.propTypes = propTypes
LeftPanel.defaultProps = defaultProps
// #endregion

export default LeftPanel
