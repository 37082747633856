import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { client } from '../apiClient'
import NanoAutocomplete from './NanoAutocomplete'
import { Typography } from '@mui/material'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions
async function fetchOptions (groupFilter) {
  const query = { group_ids: groupFilter }
  const responses =
  await client.GET('/v1/workspace/device-contents', { params: { query } })
  return responses.data.map((dc) => ({
    label: dc.device_content,
    id: dc.id,
    section: 'device_content'
  }))
}
// #endregion

// #region component
const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
    section: PropTypes.string
  }),
  groupFilter: PropTypes.string
}

const defaultProps = {
  value: null,
  groupFilter: null
}

/**
 ** @param {PropTypes.InferProps<propTypes>} props
 */
function SelectDeviceContent ({ onChange, value, groupFilter, ...props }) {
  const { t } = useTranslation()

  return (
    <NanoAutocomplete
      placeholder={t('device_content')}
      noOptionsText={(<Typography sx={{ pt: 3 }} align='center'>{t('no_device_contents_to_add')}</Typography>)}
      fetchOptions={() => fetchOptions(groupFilter)}
      onChange={onChange}
      value={value}
      {...props}
      sx={{ maxHeight: 300, overflow: 'auto' }}
    />

  )
}

SelectDeviceContent.propTypes = propTypes
SelectDeviceContent.defaultProps = defaultProps
// #endregion

export default SelectDeviceContent
