import React from 'react'
import PropTypes from 'prop-types'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import dayjs from 'dayjs'

const propTypes = {

  valueSelected: PropTypes.arrayOf(PropTypes.shape({})),
  calendars: PropTypes.oneOf([1, 2, 3]),
  disableFuture: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

const defaultProps = {
  valueSelected: [null, dayjs()]
}

/**
 *
 * @param {PropTypes.InferProps<propTypes>} props
 */
function NanoDateRange ({ valueSelected, calendars, disableFuture, onChange, ...props }) {
  //  @TODO: Select spread its value to DatePicker so we created a component in order to find a workaround. Now we use valueSelected instead of value.
  //   To fix it, remove the logical of select because we don't need here, we just a want a button (select look and feel) with a popover to select the date.
  //   You can use as an example notification popover.

  return (
    <DateRangePicker
      // @ts-ignore
      calendars={calendars}
      // @ts-ignore
      value={valueSelected}
      defaultValue={valueSelected}
      disableFuture={disableFuture}
      onChange={onChange}
      {...props}
    />

  )
}

NanoDateRange.propTypes = propTypes
NanoDateRange.defaultProps = defaultProps

export default NanoDateRange
