import React from 'react'
import PropTypes from 'prop-types'
import { Chip, Tooltip } from '@mui/material'
import { ContentIconBold, PoiIconBold, SiloIconBold, StatusIconBold, UsersIconBold } from '../icons'
import { CalendarIcon } from '@mui/x-date-pickers'

// #endregion

const sectionIconChip = {
  group: <PoiIconBold />,
  device_content: <ContentIconBold />,
  level_filter: <SiloIconBold />,
  status: <StatusIconBold />,
  bin: <SiloIconBold />,
  day: <CalendarIcon />,
  user: <UsersIconBold />
}
// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  section: PropTypes.oneOf(['group', 'bin', 'device_content', 'level_filter', 'status', 'day', 'user']).isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.string

}

const defaultProps = {
  label: null,
  tooltip: null
}

/**
 *
 */
function NanoChip ({ label, section, tooltip, ...props }) {
  const defaultIcon = sectionIconChip[section] ?? <UsersIconBold />
  return (
    <>
      {tooltip
        ? (
          <Tooltip title={tooltip}>
            <Chip
              sx={{ backgroundColor: '#ffffff' }}
              icon={defaultIcon}
              label={label}
              {...props}
            />
          </Tooltip>
          )
        : (
          <Chip
            sx={{ backgroundColor: '#ffffff' }}
            icon={defaultIcon}
            label={label}
            {...props}
          />
          )}
    </>
  )
}

NanoChip.propTypes = propTypes
NanoChip.defaultProps = defaultProps
// #endregion

export default NanoChip
