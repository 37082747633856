/**
 * LANGUAGE LOGIC
 * Here we store the list of language international code Iso15897
 * https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
 * The ierarchic order of language application is
 * 1. If user connected => user.configuration.preferred_language. {@link '../../App.jsx'}
 * 2. If not connected (login, register)
 * 3. Get from cache
 * 4. Detect from from browser (and set in cache)
 */

// Locales for Picker components used with localization provider
import { deDE, enUS, esES, frFR, itIT, jaJP, plPL, ptBR, viVN, svSE, zhCN } from '@mui/x-date-pickers/locales'
// Locales for other components must be added to the theme
import * as locales from '@mui/material/locale'

// Import for dayJs Adapter, we only get what we need to prevent useless build space taken
// used for date component such as calendar in MUI
import 'dayjs/locale/en'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/de'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/fr-ca'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/pl'
import 'dayjs/locale/vi'
import 'dayjs/locale/th'
import 'dayjs/locale/pt'
import 'dayjs/locale/sv'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-tw'
import i18next from 'i18next'
import isNumber from './isNumber'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useSessionStore } from '../store'

/** list of or available languages */
const langs = /** @type {const} */ (['de', 'en', 'en-US', 'es', 'fr', 'fr-CA', 'it', 'ja', 'pl', 'pt', 'th', 'sv', 'vi', 'zh-CN', 'zh-TW'])

/** list  */
const dataTypeWithDynamicFillingUnit = /** @type {const} */ ([
  'capa_max',
  'daily_analysis',
  'last_calibration',
  'level_kg',
  'level_liter',
  'level_t',
  'filling',
  'level',
  'levelJPlus1',
  'levelJPlus2',
  'levelJPlus3',
  'levelJPlus4',
  'levelJPlus5',
  'levelJPlus6',
  'levelJPlus7',
  'levelJPlus1Kg',
  'levelJPlus2Kg',
  'levelJPlus3Kg',
  'levelJPlus4Kg',
  'levelJPlus1T',
  'levelJPlus2T',
  'levelJPlus3T',
  'levelJPlus4T',
  'levelPredictions',
  'max15days',
  'mean15days',
  'missingLiter',
  'missingLiterJPlus1',
  'missingLiterJPlus2',
  'missingLiterJPlus3',
  'missingLiterJPlus4',
  'missingLiterJPlus5',
  'missingLiterJPlus6',
  'missingLiterJPlus7',
  'missingWeight',
  'missingWeightJPlus1',
  'missingWeightJPlus2',
  'missingWeightJPlus3',
  'missingWeightJPlus4'
])

/**
 * Used to map language to MUI Date Picker locale text list of available : https://mui.com/x/react-date-pickers/localization/#supported-locales
 * @type {{[K in typeof langs[number]] : typeof enUS }}
 */
const localTextMapping = {
  en: enUS,
  'en-US': enUS,
  de: deDE,
  es: esES,
  fr: frFR,
  'fr-CA': frFR,
  it: itIT,
  ja: jaJP,
  pl: plPL,
  pt: ptBR,
  th: enUS,
  sv: svSE,
  vi: viVN,
  'zh-CN': zhCN,
  'zh-TW': zhCN
}

/**
 * Used to map supported MUI Date Picker to language dayJs format (adapterLocale) : https://github.com/iamkun/dayjs/tree/dev/src/locale
* @param {typeof langs[number]} lang
 */
const adapterLocaleMapping = {
  en: 'en-gb',
  'en-US': 'en',
  'fr-CA': 'fr-ca',
  'zh-CN': 'zh-cn',
  'zh-TW': 'zh-tw'
}

/**
 * Used to map language to MUI Component locale text list of available : https://mui.com/material-ui/guides/localization/
 * @type {{[K in typeof langs[number]] : import('@mui/material/locale').Localization }}
 */
const componentLocalesMapping = {
  en: locales.enUS,
  'en-US': locales.enUS,
  de: locales.deDE,
  es: locales.esES,
  fr: locales.frFR,
  'fr-CA': locales.frFR,
  it: locales.itIT,
  ja: locales.jaJP,
  pl: locales.plPL,
  pt: locales.ptBR,
  th: locales.thTH,
  sv: locales.svSE,
  vi: locales.viVN,
  'zh-CN': locales.zhCN,
  'zh-TW': locales.zhTW
}

/**
 * Used to map language to code for flag.
 * Check for exemple : https://flagpedia.net/the-republic-of-china
 * @param {typeof langs[number]} lang
 * @returns {string}
 */
const flagMapping = (lang) => {
  const flagsForced = {
    en: 'gb',
    ja: 'jp',
    pt: 'br',
    sv: 'se',
    vi: 'vn'
  }
  return flagsForced[lang] ?? (lang.includes('-') ? lang.split('-')[1].toLowerCase() : lang)
}

const isUnitSupportedByIntl = (unit) => {
  // @ts-ignore
  return Intl.supportedValuesOf('unit').includes(unit ?? '')
}

const getLocalizedUnit = (t, locale, unit, unitDisplay) => {
  if (isUnitSupportedByIntl(unit)) {
    const format = new Intl.NumberFormat(locale, { style: 'unit', unit, unitDisplay })
    return format.formatToParts().find(part => part.type === 'unit')?.value ?? t(unit)
  }
  return t(unit)
}

/**
 * Hook returns data type with unit and manage if data type is kilogram
 * Remove this logic when there are no longer any kilogram alerts on siloconnect workspaces.
 */
export const useLocalizedDataTypeWithUnit = () => {
  const { t } = useTranslation()
  const { localizedUnit } = useLocalizedUnit()

  const localizedDataTypeWithUnit = useCallback((dataType) => {
    let label = dataTypeWithDynamicFillingUnit.includes(dataType) ? t('string_workspace_filling_unit', { value: t(dataType) }) : t(dataType)
    if (dataType === 'level_kg') {
      label = `${t(dataType)} (${localizedUnit('kilogram', 'long')})`
    }
    return label
  }, [t, localizedUnit])

  return { localizedDataTypeWithUnit }
}

/**
 * Hook extracts units localized from Intl if exists
 */
export const useLocalizedUnit = () => {
  const currentUser = useSessionStore((state) => state.user)
  const { t } = useTranslation()

  const localizedUnit = useCallback((unit, unitDisplay) => {
    const locale = currentUser.preferred_language
    return getLocalizedUnit(t, locale, unit, unitDisplay)
  }, [currentUser.preferred_language, t])

  return { localizedUnit }
}

const setupI18nextFormatters = (fillingUnit) => {
  /**
   * Custom formatter to format number and add the unit for data_type
   * `value` must be an object like this : { value: 2.3, unit: 'pound }
   */
  i18next.services.formatter?.add('format_data_type_unit', (value, lng, options) => {
    if (!isNumber(value?.value)) return '-'
    const unit = value.unit
    if (isUnitSupportedByIntl(unit)) {
      return value.value.toLocaleString(lng, { ...options, style: 'unit', unit })
    } else {
      const formattedValue = value.value.toLocaleString(lng, options)
      return `${formattedValue} ${unit === 'ton' ? i18next.t('ton_short') : (unit ?? '')}`
    }
  })

  /**
   * Custom formatter to format number and add the workspace unit
   * examples: '12 t' / '234 lb' / '22 l / '22 🍆'
   * usage in code : t('number_workspace_filling_unit', {value : 23})
   */
  i18next.services.formatter?.add('format_number_with_workspace_filling_unit', (value, lng, options) => {
    if (!isNumber(value)) return '-'
    const isSupportedUnit = (fillingUnit && isUnitSupportedByIntl(fillingUnit))
    if (isSupportedUnit || (!fillingUnit)) {
      return value.toLocaleString(lng, { ...options, style: 'unit', unit: fillingUnit })
    } else {
      const formattedValue = value.toLocaleString(lng, options)
      const unit = fillingUnit || i18next.t('ton_short')
      return `${formattedValue} ${unit === 'ton' ? i18next.t('ton_short') : unit}`
    }
  })

  /**
   * Custom formatter to add the workspace unit at the end of the string with paranthesis
   * examples: 'Quantity (ton)' / 'Quantity (pound)' / 'Quantity (liter)' / 'Quantity (🍆)'
   * usage in code : t('string_workspace_filling_unit', { value: t('level')})
   */
  i18next.services.formatter?.addCached('format_string_with_workspace_filling_unit', (lng) => {
    const unit = fillingUnit ?? 'ton'
    return (val) => `${val} (${getLocalizedUnit(i18next.t, lng, unit, 'long')})`
  })
}

export { langs, localTextMapping, flagMapping, componentLocalesMapping, adapterLocaleMapping, setupI18nextFormatters }
