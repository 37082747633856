import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { TableCell, Skeleton, Typography, Stack } from '@mui/material'
import { ToastContext } from '../../../../shared/contexts'
import { patchRequest } from '../../../../shared/apiClient'
import NanoSwitch from '../../../../shared/components/NanoSwitch'
import { sendEvent } from '../../../../shared/utils/analyticsUtils'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  isText: PropTypes.bool,
  is_active: PropTypes.bool.isRequired,
  alert_id: PropTypes.string.isRequired,
  onUpdated: PropTypes.func
}

const defaultProps = { isText: false, loading: false, onUpdated: () => {} }

/**
 *
 */
function AlertStateCell ({ loading, is_active: isActive, alert_id: alertId, isText, onUpdated }) {
  const { t } = useTranslation()
  const toastContext = useContext(ToastContext)
  const onChange = (newValue) => {
    patchRequest(`v1/alerts/${alertId}/`, { is_active: newValue })
      .then(() => {
        sendEvent('alert_updated', { is_active: newValue })
        toastContext.sendMessage(t('alert_snackbar_success'))
        onUpdated()
      })
      .catch((err) => {
        toastContext.sendMessage(err.message, 'error')
      })
  }

  return (
    <TableCell align='left' onClick={(e) => e.stopPropagation()}>
      {loading
        ? <Skeleton width='80%' />
        : (

          <Stack direction='row' spacing={2}>
            <NanoSwitch
              defaultChecked={isActive}
              key={alertId}
              onChange={(event) => {
                onChange(event.target.checked)
              }}
            />
            {isText &&
          (
            <Typography variant='body1'>
              {isActive ? t('alerts_detail_on') : t('alerts_detail_off')}
            </Typography>
          )}
          </Stack>

          )}
    </TableCell>
  )
}

AlertStateCell.propTypes = propTypes
AlertStateCell.defaultProps = defaultProps
// #endregion

export default AlertStateCell
