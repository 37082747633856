import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Battery33PercentIcon, Battery66PercentIcon, Battery100PercentIcon, BatteryProblemIcon } from '../../../shared/icons/index'
import { Box, Popover, Stack, Typography, useTheme } from '@mui/material'

// #region constants

const BATTERY_STATUS = Object.freeze({
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
  EMPTY: 'empty'
})

const isKnownBatteryStatus = (status) => {
  return Object.values(BATTERY_STATUS).includes(status)
}

const BATTERY_PERCENTAGE_ICON = Object.freeze({
  [BATTERY_STATUS.HIGH]: Battery100PercentIcon,
  [BATTERY_STATUS.MEDIUM]: Battery66PercentIcon,
  [BATTERY_STATUS.LOW]: Battery33PercentIcon,
  [BATTERY_STATUS.EMPTY]: BatteryProblemIcon
})

const BATTERY_EXPLANATION = Object.freeze({
  [BATTERY_STATUS.HIGH]: 'battery_full',
  [BATTERY_STATUS.MEDIUM]: 'battery_half_full',
  [BATTERY_STATUS.LOW]: 'battery_almost_empty',
  [BATTERY_STATUS.EMPTY]: 'battery_empty'
})
const BATTERY_TOOLTIP = Object.freeze({
  [BATTERY_STATUS.HIGH]: 'battery_full_percent',
  [BATTERY_STATUS.MEDIUM]: 'battery_half_full_percent',
  [BATTERY_STATUS.LOW]: 'battery_almost_empty_percent'
})

// #endregion

// #region components

const propTypes = {
  data: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool
}

const defaultProps = {
  data: {},
  loading: true
}

const BatteryIconWithDescription = ({ status }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const IconComponent = BATTERY_PERCENTAGE_ICON[status]
  const explanation = t(BATTERY_EXPLANATION[status])
  const tooltip = t(BATTERY_TOOLTIP[status])
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    isKnownBatteryStatus(status)
      ? (
        <>
          <Stack direction='row' spacing={1} justifyContent='space-between' alignContent='center' alignItems='center'>
            <IconComponent
              color={status === BATTERY_STATUS.HIGH || status === BATTERY_STATUS.MEDIUM ? theme.palette.success.main : theme.palette.error.main}
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            />
            <Typography variant='body1' color={status === BATTERY_STATUS.HIGH || status === BATTERY_STATUS.MEDIUM ? theme.palette.success.main : theme.palette.error.main}>
              {explanation}
            </Typography>
          </Stack>
          {tooltip &&
            <Popover
              id='mouse-over-popover'
              sx={{ pointerEvents: 'none' }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Box p={2} maxWidth={400}>
                <Typography variant='body1' color='text.secondary'>
                  {tooltip}
                </Typography>
              </Box>
            </Popover>}
        </>
        )
      : t('unknown')
  )
}

BatteryIconWithDescription.propTypes = {
  status: PropTypes.oneOf(Object.values(BATTERY_STATUS)).isRequired
}

// #endregion

// #region functions

BatteryIconWithDescription.propTypes = propTypes
BatteryIconWithDescription.defaultProps = defaultProps

// #endregion

export default BatteryIconWithDescription
