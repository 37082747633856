const statusFilter = Object.freeze([
  {
    name: 'ok',
    value: 'ok'
  },
  {
    name: 'pending',
    value: 'pending,calibrating'
  },
  {
    name: 'problem',
    value: 'problem'
  }
])
export default statusFilter
