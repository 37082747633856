import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar'
import { Card, Stack, Typography } from '@mui/material'
import { useWorkspaceSettingsStore } from '../../../shared/store'
import NanoSelectSingle from '../../../shared/components/NanoSelectSingle'
import isNumber from '../../../shared/utils/isNumber'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  order: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired
}

const defaultProps = {}

/**
 *
 */
function OrderFormStepWhen ({ order, control, watch, setValue, getValues }) {
  const { t } = useTranslation()
  const state = useWorkspaceSettingsStore()
  const deliveryTimes = state.getSetting('deliveryTimesConstants')?.value?.split(',')
  const [availableDeliveryTimes, setAvailableDeliveryTimes] = useState(deliveryTimes)

  const isRange = state.getSetting('deliveryDateRange')?.value === 'true'
  const minHoursBeforeOrder = parseInt(state.getSetting('minHoursBeforeOrder')?.value) ?? 0
  const minTimeFriday = state.getSetting('minTimeFriday')?.value

  const selectedDateCalendar = watch(isRange ? 'when_dates' : 'when_date')

  /**
   * business logic to update the list of available time slots according to the selected date
   */
  useEffect(() => {
    if (selectedDateCalendar) {
      // copy deliveryTimes retrieved via API
      let deliveryTimesCopy = deliveryTimes
      // create now date
      const now = dayjs()
      // create now + minHoursBeforeOrder
      const dateBeforeOrder = now.clone().add(minHoursBeforeOrder, 'hour')
      // convert calendar date to dayjs object
      const selectedDate = dayjs(isRange ? selectedDateCalendar[0] : selectedDateCalendar)

      if (selectedDate.isSame(now, 'day')) {
        if (now.hour() < 12) {
          deliveryTimesCopy = deliveryTimesCopy?.filter(time => time !== 'morning')
        } else {
          deliveryTimesCopy = []
        }
      }

      if (selectedDate.isSame(dateBeforeOrder, 'day')) {
        if (dateBeforeOrder.hour() < 12) {
          deliveryTimesCopy = deliveryTimesCopy?.filter(time => time !== 'morning')
        } else {
          deliveryTimesCopy = []
        }
      }

      // clear selection if selected time slot exists and not includes in deliveryTimesCopy
      const selectedTimeSlot = getValues().when_time_slot
      if (selectedTimeSlot && deliveryTimesCopy?.includes(selectedTimeSlot) === false) {
        setValue('when_time_slot', '')
      }
      // update options for when_time_slot NanoSelectSingle
      setAvailableDeliveryTimes(deliveryTimesCopy)
    }
  }, [selectedDateCalendar])

  /**
   * function that deactivates calendar days based on the days returned by the API
   * and workspace settings minHoursBeforeOrder and minTimeFriday
   *
   * @param {Date} day
   * @returns {boolean}
   */
  function shouldDisableDate (day) {
    // Disable dates returned by the API

    // days off
    if (state.daysOff.includes(day.day())) return true

    // dates off
    if (state.holidays.some(holiday => holiday.isSame(day, 'day'))) return true

    const currentDay = dayjs()
    // Disable today if it's past noon
    if (currentDay.isSame(day, 'day') && currentDay.hour() >= 12) return true

    // Disable dates if it's past the specified number of hours (minHoursBeforeOrder) from the current time
    if (isNumber(minHoursBeforeOrder)) {
      const targetDay = currentDay.clone().add(minHoursBeforeOrder, 'hour')
      if (targetDay.isAfter(day)) {
        const hoursDifference = targetDay.diff(day, 'hour')
        if (hoursDifference >= 12) return true
      }
    }

    // If current date is Friday, disable if current time is after minTimeFriday and day is monday

    if (currentDay.day() === 5) {
      if (minTimeFriday && /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(minTimeFriday)) {
        const currentHourMinute = currentDay.format('HH:mm')

        if (day.day() === 1 && currentHourMinute > minTimeFriday) return true
      }
    }

    return false
  }

  return (
    <Stack spacing={2}>
      <Controller
        control={control}
        defaultValue={
          isRange
            ? [
                (order.date ? dayjs(order.date) : null),
                (order.date_range_end ? dayjs(order.date_range_end) : null)
              ]
            : (order.date ? dayjs(order.date) : null)
        }
        name={
          isRange
            ? 'when_dates'
            : 'when_date'
        }
        rules={{
          validate: (value) => {
            if (isRange) {
              return value[0] ?? t('form_field_required_error_message')
            }
          },
          required: t('form_field_required_error_message')
        }}
        render={({ field, fieldState: { error } }) => {
          const borderColor = error ? 'error.main' : null
          return (
            <Stack>
              <Card sx={{ p: 2, borderColor }}>
                {isRange
                  ? <DateRangeCalendar
                      {...field}
                      displayWeekNumber
                      calendars={1}
                      disablePast
                      shouldDisableDate={shouldDisableDate}
                    />
                  : <DateCalendar
                      {...field}
                      displayWeekNumber
                      disablePast
                      shouldDisableDate={shouldDisableDate}
                    />}
              </Card>
              {error?.message
                ? (
                  <Typography
                    variant='caption'
                    color='error.main'
                    sx={{ ml: 1, mt: 1 }}
                  >{error?.message}
                  </Typography>
                  )
                : null}
            </Stack>
          )
        }}
      />
      <Controller
        control={control}
        defaultValue={availableDeliveryTimes?.includes(order.timeSlot) ? order.timeSlot : ''}
        name='when_time_slot'
        render={({ field, fieldState: { error } }) => (
          <NanoSelectSingle
            label={t('order_time_slot')}
            options={availableDeliveryTimes?.map((deliveryTime) => ({ value: deliveryTime, name: deliveryTime }))}
            variant='outlined'
            {...field}
          />
        )}
      />
    </Stack>

  )
}

OrderFormStepWhen.propTypes = propTypes
OrderFormStepWhen.defaultProps = defaultProps
// #endregion

export default OrderFormStepWhen
