import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useForm, Controller } from 'react-hook-form'
import { Alert, DialogContentText, Stack, TextField, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import dayjs from 'dayjs'
import NanoDialog from '../../../shared/components/NanoDialog'
import { ReactComponent as LevelImage } from '../../../shared/icons/LevelImage.svg'
import { client } from '../../../shared/apiClient'
import NanoSelectDate from '../../../shared/components/NanoSelectDate'
import NanoSelectHour from '../../../shared/components/NanoSelectHour'
import { devicePropTypes } from '../model'
import { isAfter, isBefore } from '../../../shared/utils/dateUtils'
import isNumber from '../../../shared/utils/isNumber'
import { sendEvent } from '../../../shared/utils/analyticsUtils'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  device: devicePropTypes,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  row: PropTypes.shape({})
}

const defaultProps = {
  isOpen: false,
  device: {}
}

function CalibLevelModal ({ isOpen, device, onSuccess, onClose, row }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(false)
  const isCreateMode = Object.keys(device).length > 0
  const deviceName = device?.device_name || row?.device_name
  const poiName = device?.group_poi?.group_poi_name || row?.poi_name
  const deviceRef = device?.device_reference || row?.idDevice
  const quantity = isNumber(row?.quantity) ? row.quantity : null
  const calibrationDate = row?.calibration_date ? dayjs(row?.calibration_date) : dayjs()
  const installDate = device?.device_install_date || row?.device_install_date

  const { handleSubmit, control, getValues } = useForm()

  const handleClose = () => {
    onClose()
  }
  const onSubmit = (data) => {
    setDisplayError(null)
    setIsLoading(true)

    const payload = {
      calibration_date: dayjs(data.date).set('hour', data.hour.hour()).set('minute', data.hour.minute()).set('second', 0).set('millisecond', 0).toDate(),
      calibration_level: parseFloat(data.level)
    }

    if (isCreateMode) {
      client.POST('/v1/devices/{id}/calibrate', { params: { path: { id: deviceRef } }, body: { ...payload } }).then(() => {
        sendEvent('calibration_level_sent')
        setIsLoading(false)
        handleClose()
        onSuccess()
      }).catch((err) => {
        console.log('error :', { err })
        if (err.code === 'calibration_already_exist_for_silo_date') { setDisplayError(t('calibration_already_exist_for_silo_date')) } else { setDisplayError(err.message) }
        setIsLoading(false)
      })
    } else {
      // patchRequest(`v1/devices/${row.idDevice}/calibrate`, payload).then(() => {
      client.PATCH('/internal/calibration-level/{id}', { params: { path: { id: row.idCalibration } }, body: { calibration_date: payload.calibration_date.toISOString(), calibration_level: payload.calibration_level } }).then(() => {
        sendEvent('calibration_level_updated')
        handleClose()
        onSuccess()
      }).catch((err) => {
        if (err.code === 'calibration_already_exist_for_silo_date') { setDisplayError(t('calibration_already_exist_for_silo_date')) } else { setDisplayError(err.message) }
        setIsLoading(false)
      })
    }
  }

  return (
    <NanoDialog
      open={isOpen}
      onClose={handleClose}
      title={t('calib_level_form_title')}
    >
      <DialogContentText>
        {t('calib_level_form_subtitle')}

      </DialogContentText>
      <Stack
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        display='flex'
        pb={3}
      >

        <Box
          width={156}
          height={156}
          flexShrink={0}
        >
          <LevelImage />
        </Box>
      </Stack>

      <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <Typography variant='h5'>{`${deviceName} (${poiName})`}</Typography>
          <Stack direction='row' justifyContent='space-between' spacing={1}>
            <Controller
              control={control}
              name='date'
              defaultValue={calibrationDate}
              rules={{
                validate: {
                  pastDate: (value) => {
                    return isBefore(new Date(value), new Date()) || t('calib_date_error_future')
                  },
                  futureDate: (value) => {
                    return isAfter(new Date(value), new Date(installDate)) || t('calib_date_error_past')
                  }
                },
                required: t('form_field_required_error_message')
              }}
              render={({ field, fieldState: { error } }) => (
                <NanoSelectDate
                  {...field}
                  sx={{ flexGrow: 4 }}
                  fullWidth
                  maxDate={dayjs()}
                  label={`${t('date')} *`}
                  error={!!error}
                  disableFuture
                  onChange={(data) => {
                    field.onChange(data)
                  }}
                  value={field.value}
                  slotProps={{ textField: { error: !!error, helperText: error?.message } }}
                />
              )}
            />
            <Controller
              control={control}
              name='hour'
              defaultValue={calibrationDate}
              rules={{
                validate: (value) => {
                  const date = new Date(getValues().date)
                  const dateTime = new Date(value)
                  if (date && dateTime) {
                    const dateWithTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), dateTime.getHours(), dateTime.getMinutes())
                    return isBefore(dateWithTime, new Date(Date.now())) || t('calib_date_error_future')
                  }
                },
                required: t('form_field_required_error_message')
              }}
              render={({ field, fieldState: { error } }) => (
                <NanoSelectHour
                  {...field}
                  sx={{ flexGrow: 1 }}
                  fullWidth
                  label={`${t('hour')}   *`}
                  onChange={(data) => {
                    field.onChange(data)
                  }}
                  value={field.value}
                  slotProps={{ textField: { error: !!error, helperText: error?.message } }}
                />
              )}
            />
          </Stack>
          <Controller
            control={control}
            defaultValue={quantity}
            rules={{
              min: { value: 0, message: t('error_must_be_positive_number') },
              max: { value: device.capa_max, message: t('calib_form_capa_max', { count: device.capa_max }) },
              required: t('form_field_required_error_message')
            }}
            name='level'
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t('calib_level_quantity')}
                placeholder={t('string_workspace_filling_unit', { value: t('multi_calib_form_quantity_placeholder') })}
                type='number'
                required
                inputProps={{ min: 0 }}
                error={!!error}
                helperText={error?.message ?? t('calib_level_quantity_zero')}
              />
            )}
          />

          {!!displayError && <Alert severity='error'>{displayError}</Alert>}
          <LoadingButton
            loading={isLoading}
            type='submit'
            loadingPosition='start'
            fullWidth
          >
            {t('confirm_and_send')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

CalibLevelModal.propTypes = propTypes
CalibLevelModal.defaultProps = defaultProps
// #endregion

export default CalibLevelModal
