import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material'
import NanoPaper from '../../../../shared/components/NanoPaper'
import { useTranslation } from 'react-i18next'
import { PinIcon, SettingsIcon } from '../../../../shared/icons/index'
import NanoSwitch from '../../../../shared/components/NanoSwitch'

// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = { distance: PropTypes.number, value: PropTypes.bool.isRequired, onChangeFreeSpace: PropTypes.func }
const defaultProps = { onChangeFreeSpace: null }

/**
 *
 */

function LegendMap ({ value, onChangeFreeSpace }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [showLegend, setShowLegend] = useState(false)
  const onChange = () => {
    setShowLegend(!showLegend)
  }

  const items = [
    {
      title: '< 10 %',
      style: { backgroundColor: theme.palette.priorityHigh.contrastText }
    },
    {
      title: '',
      style: { backgroundColor: theme.palette.priorityMedium.contrastText }
    },
    {
      title: '> 50 %',
      style: { backgroundColor: theme.palette.priorityLow.contrastText }
    }
  ]
  return (
    <>
      <Stack
        spacing={1}
        padding={2}
        className='leaflet-top leaflet-right leaflet-control'
      >
        <Stack direction='column' justifyContent='flex-start' spacing={1}>
          {!showLegend &&
            <IconButton
              className='leaflet-control'
              sx={{ p: 0 }}
              onClick={onChange}
            >
              <SettingsIcon color='black' />
            </IconButton>}

          {showLegend &&
            <Stack direction='column' alignItems='flex-end' justifyContent='flex-end' spacing={1}>
              <IconButton
                className='leaflet-control'
                sx={{ p: 0 }}
                onClick={onChange}
              >
                <SettingsIcon color='black' />
              </IconButton>

              <NanoPaper sx={{ m: 1 }} className='leaflet-control'>
                <Stack direction='column' justifyContent='flex-start' spacing={1} sx={{ pb: 2 }}>
                  <Stack direction='column' alignItems='center' alignContent='center' justifyContent='flex-start'>
                    <Typography variant='body1'>
                      {t('level_filter')}
                    </Typography>
                  </Stack>

                  <Box
                    height='20px'
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    justifyContent='center'
                    paddingBottom={2}
                  >
                    {items.map(item => (
                      <Box
                        key={item.title}
                        height='20px'
                        width='80px'
                        style={item.style}
                        title={item.title}
                        fontSize='0.8rem'
                        paddingTop='2px'
                        textAlign='center'
                        color='white'
                        fontWeight='bold'
                      >
                        {item.title}
                      </Box>
                    ))}
                  </Box>
                  {/* Status problem */}
                  <Stack direction='row' justifyContent='flex-start' spacing={1}>
                    <PinIcon fill={theme.palette.text.secondary} stroke='black' />
                    <Typography variant='body1'>
                      {t('problem')}
                    </Typography>
                  </Stack>
                  {/* other status */}
                  <Stack direction='row' justifyContent='flex-start' spacing={1}>
                    <PinIcon fill={theme.palette.calibration.main} stroke={theme.palette.calibration.dark} />
                    <Typography variant='body1'>
                      {t('pending')}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider />
                <Stack sx={{ pt: 2 }} direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
                  <Typography variant='body1'>
                    {t('active_missing_weight')}
                  </Typography>
                  <NanoSwitch
                    className='leaflet-control'
                    defaultChecked={value}
                    onChange={onChangeFreeSpace}
                  />
                </Stack>
                {/* <ThresholdSlider /> */}
              </NanoPaper>
            </Stack>}
        </Stack>
      </Stack>
      <Stack
        spacing={1}
        padding={2}
        className='leaflet-bottom leaflet-right leaflet-control'
      />
    </>
  )
}

LegendMap.propTypes = propTypes
LegendMap.defaultProps = defaultProps
// #endregion

export default LegendMap
