import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Alert, Box, Stack } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import NanoDialog from '../../../shared/components/NanoDialog'
import SelectGroups from '../../../shared/components/SelectGroups'
import { Controller, useForm } from 'react-hook-form'
import { Promise } from 'bluebird'
import { client } from '../../../shared/apiClient'
import SelectEmailUser from '../../../shared/components/SelectEmailUser'

// import { useSessionStore } from '../../shared/store';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  defaultGroup: PropTypes.arrayOf(PropTypes.shape({}))
}

const defaultProps = {
  isOpen: false,
  userGroup: [],
  onSuccess: null,
  defaultGroup: null
}

/**
 *
 */
function InviteRecipientsModal ({ isOpen, onClose, onSuccess, defaultGroup }) {
  const { t } = useTranslation()
  const { handleSubmit, control } = useForm()
  const [displayError, setDisplayError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    onClose()
  }

  const onSubmit = (data) => {
    setIsLoading(true)

    Promise.map( // BLUEBIRD
      data.access,
      (d) => {
        return client.PATCH(`/v1/groups/${d.group_id}`, {
          body: { order_recipient_emails: [...new Set([...d.order_recipient_emails, ...data.email.map((mail) => mail.email)])] }
        })
      },
      { concurrency: 5 }
    )
      .then(() => {
        setIsLoading(false)
        onSuccess()
        handleClose()
      })
      .catch((error) => {
        setDisplayError(error)
        setIsLoading(false)
      })
  }

  return (
    <NanoDialog
      open={isOpen}
      onClose={handleClose}
      title={t('alert_form_select_user_label')}
    >
      <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>

          <Controller
            control={control}
            name='email'
            defaultValue={[]}
            rules={{ required: t('form_field_required_error_message') }}
            render={({ field, fieldState: { error } }) => (
              <SelectEmailUser
                onChange={(_, data) => field.onChange(data)}
                value={field.value}
                required
              />
            )}
          />

          <Controller
            control={control}
            name='access'
            rules={{ required: t('form_field_required_error_message') }}
            defaultValue={defaultGroup ?? []}
            render={({ field, fieldState: { error } }) => (
              <SelectGroups
                selectAll
                error={error}
                onChange={(_, data) => field.onChange(data)}
                value={field.value}
                required
              />
            )}
          />

          {!!displayError && <Alert severity='error'>{displayError}</Alert>}
          <LoadingButton
            loading={isLoading}
            type='submit'
            loadingPosition='start'
            fullWidth
          >
            {t('create')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

InviteRecipientsModal.propTypes = propTypes
InviteRecipientsModal.defaultProps = defaultProps
// #endregion

export default InviteRecipientsModal
