import { Box, CircularProgress, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import NanoPaper from '../../../shared/components/NanoPaper'
import { dateMediumWithTime } from '../../../shared/utils/dateUtils'
import { formatTimeTickLabel, generateTimeTicks } from './timeTicks'

const Loader = () => (
  <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }}
  >
    <CircularProgress
      size={50}
      thickness={2}
      sx={{
        margin: 'auto'
      }}
    />
  </Box>
)

/** Watch the width of a container */
const useWatchContainerWidth = () => {
  const containerRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(undefined)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const observer = new window.ResizeObserver(([entry]) => {
      if (!entry) return
      setContainerWidth(entry.contentRect.width)
    })

    observer.observe(container)
    return () => {
      observer.disconnect()
    }
  }, [containerRef.current])

  return { containerWidth, containerRef }
}

const APPROXIMATE_GRAPH_PADDING = 100 // px

export default function DeviceLevelChart ({ graphData, continuous, isLoading, capaMax }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { containerWidth, containerRef } = useWatchContainerWidth()
  const capaMaxLength = capaMax?.toString().length

  return (
    <NanoPaper sx={{ height: '300px' }} ref={containerRef}>
      <ResponsiveContainer width='100%' height='100%'>
        {isLoading || !containerWidth
          ? <Loader />
          : (
            <LineChart
              data={graphData}
              margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
            >
              <CartesianGrid vertical={false} strokeDasharray='4 1 0' />
              <XAxis
                axisLine={false}
                dataKey='timestamp'
                domain={['dataMin', 'dataMax']}
                name='Time'
                type='number'
                padding='no-gap'
                dy={12} // vertical padding between axis and labels
                tickLine
                scale='time'
                ticks={generateTimeTicks(graphData.map(d => d.timestamp), containerWidth - APPROXIMATE_GRAPH_PADDING)}
                tickFormatter={formatTimeTickLabel}
              />
              <YAxis
                dataKey='filling.value'
                tickFormatter={(value) => t('number_workspace_filling_unit', { value })}
                axisLine={false}
                width={capaMaxLength >= 5 ? 75 : 50} // adapt width to the number of digits in the capa max
                tickLine={false}
              />
              <Tooltip
                wrapperStyle={{ outline: 'none' }}
                formatter={(value) => [t('number_workspace_filling_unit', { value })]}
                labelFormatter={(value) => `${dateMediumWithTime(value)}`}
              />
              <Line
                type={continuous ? 'monotone' : 'stepAfter'}
                stroke={theme.palette.graph.main}
                dataKey='filling.value'
                dot={false}
                strokeWidth={2}
              />
            </LineChart>
            )}
      </ResponsiveContainer>
    </NanoPaper>
  )
}
