import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography, Divider, Box, useTheme, useMediaQuery, Skeleton } from '@mui/material'
import NanoSwitch from '../../shared/components/NanoSwitch'
import { useSessionStore, useWorkspaceSettingsStore } from '../../shared/store'
import TopPage from '../layout/TopPage'
import { ToastContext } from '../../shared/contexts'
import NanoPaper from '../../shared/components/NanoPaper'
import { patchRequest } from '../../shared/apiClient'
import LangComponent from '../../shared/components/LangComponent'
// import { Crisp } from 'crisp-sdk-web'
import { sendEvent } from '../../shared/utils/analyticsUtils'
import { InboxIcon, MobileIcon } from '../../shared/icons/index'
// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */

function Preferences () {
  const { t } = useTranslation()
  const toastContext = useContext(ToastContext)
  const user = useSessionStore((state) => state.user)
  const disableOrders = useWorkspaceSettingsStore((state) => state.getSetting('disableOrders'))?.value === 'true'
  const [checkedPush, setCheckedPush] = useState(user.configuration.notification_push)
  const [checkedEmail, setCheckedEmail] = useState(user.configuration.notification_email)
  const [langUpdate, setLangUpdate] = useState(false)
  const [checkedForecast, setCheckedForecast] = useState(user.configuration.display_forecast)
  const [checkedRemaining, setCheckedRemaining] = useState(user.configuration.display_remaining)
  const [checkedLastOrder, setCheckedLastOrder] = useState(user.configuration.display_last_order)
  const [checkedQuickActionOrder, setCheckedQuickActionOrder] = useState(user.configuration.display_quick_action_order)
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  async function onUpdateUser (payload) {
    await patchRequest(`v1/users/${user.idUser}/`, payload).then(() => {
      sendEvent('account_preferences_updated')
      if (!payload.preferred_language) toastContext.sendMessage(t('user_snackbar_settings_success'))
      return (useSessionStore.getState().refreshMe())
    }).catch((err) => {
      console.log(err.message)
    })
  }
  return (
    <>
      <TopPage
        back
        justifyContent='center'
        title={mobileFormat ? null : t('preferences_menu')}
      />

      <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 4, marginBottom: 'auto', width: mobileFormat ? '90%' : '33%', padding: 1, display: 'center', flexDirection: 'column' }}>
        <Box mt={4} padding='2px'>
          <Stack spacing={3}>

            <NanoPaper p={2}>
              <Typography variant='h5' sx={{ pb: 1 }}>
                {t('notifications')}
              </Typography>

              <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 2, mb: 2 }}>
                <Stack direction='row' alignItems='center' spacing={2}>
                  <MobileIcon />
                  <Typography variant='body1'>
                    {t('notifications_push_text')}
                  </Typography>
                </Stack>
                <NanoSwitch
                  defaultChecked={checkedPush}
                  onChange={() => {
                    const newCheckedPush = !checkedPush
                    setCheckedPush(newCheckedPush)
                    onUpdateUser({ notification_push: newCheckedPush })
                  }}
                />
              </Stack>
              <Divider />

              <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 2 }}>
                <Stack direction='row' alignItems='center' spacing={2}>
                  <InboxIcon />
                  <Typography variant='body1'>
                    {t('notifications_email_text')}
                  </Typography>
                </Stack>
                <NanoSwitch
                  defaultChecked={checkedEmail}
                  onChange={() => {
                    const newCheckedEmail = !checkedEmail
                    setCheckedEmail(newCheckedEmail)
                    onUpdateUser({ notification_email: newCheckedEmail })
                  }}
                />
              </Stack>
            </NanoPaper>

            <NanoPaper p={2}>
              <Typography variant='h5' sx={{ pb: 1 }}>
                {t('user_form_language')}
              </Typography>
              <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 2 }}>
                {langUpdate
                  ? <Skeleton width='100%' />
                  : <LangComponent
                      onChange={(event) => {
                        setLangUpdate(true)
                        onUpdateUser({ preferred_language: event.target.value }).finally(() => setLangUpdate(false))
                      }}
                    />}
              </Stack>
            </NanoPaper>

            <NanoPaper p={2}>
              <Typography variant='h5' sx={{ pb: 1 }}>
                {t('menu_dashboard')}
              </Typography>
              <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 2, mb: 2 }}>
                <Typography variant='body1'>
                  {t('display_forecast_text')}
                </Typography>
                <NanoSwitch
                  color='secondary'
                  defaultChecked={checkedForecast}
                  onChange={() => {
                    const newCheckedForecast = !checkedForecast
                    setCheckedForecast(newCheckedForecast)
                    onUpdateUser({ display_forecast: newCheckedForecast })
                  }}
                />
              </Stack>
              <Divider />

              <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 2, mb: 2 }}>
                <Typography variant='body1'>
                  {t('display_remaining_days')}
                </Typography>
                <NanoSwitch
                  defaultChecked={checkedRemaining}
                  onChange={() => {
                    const newCheckedRemaining = !checkedRemaining
                    setCheckedRemaining(newCheckedRemaining)
                    onUpdateUser({ display_remaining: newCheckedRemaining })
                  }}
                />
              </Stack>

              <Divider />

              <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 2, mb: 2 }}>
                <Typography variant='body1'>
                  {t('device_caracteristic_last_delivery_date')}
                </Typography>
                <NanoSwitch
                  defaultChecked={checkedLastOrder}
                  onChange={() => {
                    const newCheckedLastOrder = !checkedLastOrder
                    setCheckedLastOrder(newCheckedLastOrder)
                    onUpdateUser({ display_last_order: newCheckedLastOrder })
                  }}
                />
              </Stack>
              <Divider />
              <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 2 }}>
                <Typography variant='body1'>
                  {t('display_button_order')}
                </Typography>
                <NanoSwitch
                  disabled={disableOrders}
                  checked={!disableOrders && checkedQuickActionOrder}
                  onChange={() => {
                    const newCheckedQuickActionOrder = !checkedQuickActionOrder
                    setCheckedQuickActionOrder(newCheckedQuickActionOrder)
                    onUpdateUser({ display_quick_action_order: newCheckedQuickActionOrder })
                  }}
                />
              </Stack>
            </NanoPaper>
          </Stack>
        </Box>
      </div>
    </>
  )
}

Preferences.propTypes = propTypes
Preferences.defaultProps = defaultProps
// #endregion

export default Preferences
