import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, Typography, Skeleton } from '@mui/material'
import { dateLongWithoutTime } from '../../../../shared/utils/dateUtils'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  created_at: PropTypes.string
}

const defaultProps = { loading: false, created_at: '' }

/**
 *
 */
function AlertDateModificationCell ({ loading, created_at: createdAt }) {
  const specificDate = new Date(createdAt)

  return (
    <TableCell align='left'>
      <Typography variant='body1'>
        {loading
          ? (
            <Skeleton width='80%' />
            )
          : (
              createdAt ? dateLongWithoutTime(specificDate) : '-'
            )}
      </Typography>
    </TableCell>
  )
}

AlertDateModificationCell.propTypes = propTypes
AlertDateModificationCell.defaultProps = defaultProps
// #endregion

export default AlertDateModificationCell
