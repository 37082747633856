/**
 * Reuse these across the app so that they can be used as unique value identifier (for example for MUI Select)
 */

export const BIN_SORT = Object.freeze({
  FARM_NAME: Object.freeze({ value: 'farm_name', name: 'farm_sort' }),
  DEVICE_NAME: Object.freeze({ value: 'device_name', name: 'device_name_sort' }),
  LEVEL_T: Object.freeze({
    value: 'level_t',
    name: 'level_t',
    unit: true
  }),
  REMAINING_DAYS: Object.freeze({ value: 'remainingDays', name: 'remaining_days' }),
  MISSING_WEIGHT: Object.freeze({ value: 'missingWeight', name: 'free_space', unit: true })
})
