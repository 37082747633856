import ReactGA from 'react-ga4'
import env from './../../config/env'

/**
 * Init Google Analytics
 */
const initAnalytics = () => {
  if (env().environment === 'production') {
    ReactGA.initialize('G-HHCRJ3QZHQ')
  }
}

/**
 * Set user properties for the session in Google Analytics
 * @param {string} userId
 * @param {string} workspace
 * @param {string} role
 */
const setUserProperties = (userId, workspace, role) => {
  if (env().environment === 'production') {
    ReactGA.gtag(
      'set',
      'user_properties', {
        user_id: userId,
        workspace: workspace.toLowerCase(),
        role: role.toLowerCase()
      }
    )
  }
}

/**
 * Send an event to Google Analytics
 * @param {string} name
 * @param {any} params
 */
const sendEvent = (name, params) => {
  if (env().environment === 'production') {
    ReactGA.event(name, params)
  }
}

export {
  initAnalytics,
  setUserProperties,
  sendEvent
}
