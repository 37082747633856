import React from 'react'
import { Route, Outlet, createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate } from 'react-router-dom'
// import PropTypes from 'prop-types';
import Dashboard from '../dashboard/Dashboard'
import Users from '../users/Users'
import NotFoundPage from '../../shared/components/NotFoundPage'
import Alerts from '../alerts/Alerts'
import Admin from '../admin/Admin'
import Device from '../device/Device'
import OrdersPage from '../orders/list/OrdersPage'
import EditProfile from '../settings/EditProfile'
import EditPassword from '../settings/EditPassword'
import Preferences from '../settings/Preferences'
import AlertPage from '../alerts/AlertPage'
import Notifications from '../notifications/Notifications'
import OrderPage from '../orders/details/OrderPage'
import * as Sentry from '@sentry/react'
import HelpPage from '../settings/HelpPage'
import Layout from './Layout'
import SignIn from '../login/Signin'
import ResetPassword from '../login/ResetPassword'
import Register from '../register/Register'
import Anonymous from './Anonymous'
import LeftPanel from '../login/LeftPanel'
import AccessRoleComponent from '../../shared/components/AccessRoleComponent'
import NotAccessPage from '../../shared/components/NotAccessPage'
import AccessRoleGroupComponent from '../../shared/components/AccessRoleGroupComponent'
// #region constants
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter(createRoutesFromElements(
  <>
    {/* If the user/me is not set, we go to Anonymous */}
    <Route element={<Layout />}>
      <Route path='user-profile' element={<EditProfile />} />
      <Route path='preferences' element={<Preferences />} />
      <Route path='user-password' element={<EditPassword />} />
      <Route path='user-help' element={<HelpPage />} />
      <Route path='notifications' element={<Notifications />} />
      <Route path='*' element={<NotFoundPage />} />

      {/* restricted route to certain role */}
      <Route element={<AccessRoleGroupComponent fallbackComponent={<NotAccessPage />}><Outlet /></AccessRoleGroupComponent>}>
        <Route element={<AccessRoleComponent resource='menu_devices' fallbackComponent={<NotAccessPage />}><Outlet /></AccessRoleComponent>}>
          <Route path='/' element={<Navigate to='/devices' />} />
          <Route path='devices' element={<Dashboard />} />
          <Route path='devices/:id' element={<Device />} />
          {/* backup v1  */}
          <Route path='dashboards' element={<Navigate to='/devices' />} />
        </Route>
        <Route element={<AccessRoleComponent resource='menu_orders' fallbackComponent={<NotAccessPage />}><Outlet /></AccessRoleComponent>}>
          <Route path='orders' element={<OrdersPage />} />
          <Route path='orders/:id' element={<OrderPage />} />
        </Route>

        <Route element={<AccessRoleComponent resource='menu_alerts' fallbackComponent={<NotAccessPage />}><Outlet /></AccessRoleComponent>}>
          <Route path='alerts' element={<Alerts />} />
          <Route path='alerts/:id' element={<AlertPage />} />
        </Route>
        <Route path='notifications' element={<Notifications />} />
      </Route>

      {/* restricted route to certain role */}

      <Route element={<AccessRoleComponent resource='menu_admin' fallbackComponent={<NotAccessPage />}><Outlet /></AccessRoleComponent>}>
        <Route path='admin' element={<Admin />} />
      </Route>
      <Route element={<AccessRoleComponent resource='menu_users' fallbackComponent={<NotAccessPage />}><Outlet /></AccessRoleComponent>}>
        <Route path='users' element={<Users />} />
      </Route>

    </Route>
    {/* If the user/me is set, we are kicked from Anonymous => go to Layout */}
    <Route element={<Anonymous />}>
      <Route element={<LeftPanel><Outlet /></LeftPanel>}>
        <Route path='/login' element={<SignIn />} />
        <Route path='/reset-password' element={<ResetPassword />} />
      </Route>
      <Route path='/register' element={<Register />} />
    </Route>
  </>
))
// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
function Router () {
  return <RouterProvider router={router} />
}

Router.defaultProps = defaultProps
Router.propTypes = propTypes
// #endregion

export default Router
