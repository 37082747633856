import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import CalibLevelModal from './CalibLevelModal'
import CalibDeliveryModal from './CalibDeliveryModal'
import ConfirmationModal from './ConfirmationModal'
import { CallIcon, OrdersIcon, SiloIcon, TruckIcon } from '../../../shared/icons'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import { useNavigate } from 'react-router-dom'
import OrderForm from '../../orders/form/OrderForm'
import { client } from '../../../shared/apiClient'
import { ToastContext } from '../../../shared/contexts'
import { sendEvent } from '../../../shared/utils/analyticsUtils'
import OperationSuggestedInfo from '../../../shared/components/OperationSuggestedInfo'
import QuickButton from './QuickButton'
import { canShowOperationSuggestion } from '../../../shared/models/operationSuggested'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  /** @type {PropTypes.Requireable<import('../../../../openapiDoc').components['schemas']['dashboardV2'][0]>} */
  device: PropTypes.shape({}),
  loading: PropTypes.bool,
  /** indicate that if we need to hide some action, also imply that the buttons will in outlined variant */
  fromDashboard: PropTypes.bool,
  displayOrder: PropTypes.bool,
  adminMode: PropTypes.func
}

const defaultProps = { displayOrder: true, loading: false, fromDashboard: false, adminMode: null }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function QuickActions ({ displayOrder, device, loading, fromDashboard, adminMode }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toastContext = useContext(ToastContext)
  const [calibDeliveryFormIsOpen, setCalibDeliveryFormIsOpen] = useState(false)
  const [calibLevelFormIsOpen, setCalibLevelFormIsOpen] = useState(false)
  const [confirmationPopupIsOpen, setConfirmationPopupIsOpen] = useState(false)
  const [orderFormIsOpen, setOrderFormIsOpen] = useState(false)

  const unCombineDevice = async (device) => {
    await client.DELETE('/v1/devices/{id}', { params: { path: { id: device.device_id } } }).then(() => {
      sendEvent('combined_unlinked')
      toastContext.sendMessage(t('admin_combined_snackbar_alert_deleted'))

      adminMode()
    }
    )
  }

  if (!loading) {
    return (
      <>
        <Stack spacing={2} direction='row' flexWrap='wrap' justifyContent='flex-end' useFlexGap>
          {(device.status === 'problem' && fromDashboard) &&
          (canShowOperationSuggestion(device.metadata?.operation_suggested)
            ? <OperationSuggestedInfo operationSuggested={device.metadata?.operation_suggested} />
          // show "Contact support" if we cannot show more appropriate info
            : <QuickButton
                fromDashboard={fromDashboard}
                color='problem'
                Icon={CallIcon}
                title={t('button_support')}
                onClick={() => navigate('/user-help')}
              />)}

          {((device.status === 'ok' || !fromDashboard) && displayOrder) &&
            <QuickButton
              fromDashboard={fromDashboard}
              color='secondary'
              Icon={OrdersIcon}
              title={t('device_quick_action_order_title')}
              onClick={() => setOrderFormIsOpen(true)}
            />}

          {((device.status === 'pending' && device.metadata?.info_to_calibrate?.includes('delivery')) || !fromDashboard) && !device.is_combined &&
            <QuickButton
              fromDashboard={fromDashboard}
              color='pending'
              Icon={TruckIcon}
              title={fromDashboard ? t('device_quick_action_delivery_required') : t('device_quick_action_calibrate_delivery_title')}
              onClick={() => setCalibDeliveryFormIsOpen(true)}
            />}

          {((device.status === 'pending' && device.metadata?.info_to_calibrate?.includes('level')) || !fromDashboard) && !device.is_combined &&
            <QuickButton
              fromDashboard={fromDashboard}
              color='pending'
              Icon={SiloIcon}
              title={fromDashboard ? t('device_quick_action_level_required') : t('device_quick_action_calibrate_lvl_title')}
              onClick={() => setCalibLevelFormIsOpen(true)}
            />}

          {(!!adminMode) &&
            <QuickButton
              fromDashboard={fromDashboard}
              color='secondary'
              Icon={LinkOffIcon}
              title={t('unlink_combined')}
              onClick={() => { unCombineDevice(device) }}
            />}
        </Stack>

        {calibLevelFormIsOpen &&
          <CalibLevelModal
            device={device}
            isOpen={calibLevelFormIsOpen}
            onClose={() => setCalibLevelFormIsOpen(false)}
            onSuccess={() => setConfirmationPopupIsOpen(true)}
          />}

        {calibDeliveryFormIsOpen &&
          <CalibDeliveryModal
            device={device}
            isOpen={calibDeliveryFormIsOpen}
            onClose={() => setCalibDeliveryFormIsOpen(false)}
            onSuccess={() => setConfirmationPopupIsOpen(true)}
          />}

        {confirmationPopupIsOpen &&
          <ConfirmationModal
            isOpen={confirmationPopupIsOpen}
            onClose={() => setConfirmationPopupIsOpen(false)}
          />}

        {orderFormIsOpen &&
          <OrderForm
            isOpen={orderFormIsOpen}
            deviceReference={device.is_combined ? device.devices_combined?.map((dc) => dc.device_reference) : [device.device_reference]}
            onClickCloseButton={() => setOrderFormIsOpen(false)}
          />}
      </>
    )
  }
}

QuickActions.propTypes = propTypes
QuickActions.defaultProps = defaultProps
// #endregion

export default QuickActions
