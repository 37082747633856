import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button, Stack, useTheme, useMediaQuery } from '@mui/material'
import NanoDialog from '../../shared/components/NanoDialog'
import levelFilterModel from '../../shared/models/levelFilter'
import statusFilterModel from '../../shared/models/statusFilter'
import batteryFilterModel from '../../shared/models/batteryFilterModel'
import SelectBattery from '../../shared/components/SelectBattery'
import NanoSelectMultiple from '../../shared/components/NanoSelectMultiple'
import SelectRemainingDays from '../../shared/components/SelectRemainingDays'
import NanoSelectSingle from '../../shared/components/NanoSelectSingle'

// import { useSessionStore } from '../../shared/store';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  filterModel: PropTypes.shape({
    searched: PropTypes.string,
    viewSelected: PropTypes.arrayOf(PropTypes.shape({ })),
    deviceContentsFilters: PropTypes.arrayOf(PropTypes.string),
    levelsFilters: PropTypes.arrayOf(PropTypes.string),
    remainingDaysFilters: PropTypes.arrayOf(PropTypes.string),
    groupsFilters: PropTypes.arrayOf(PropTypes.string),
    sortFilters: PropTypes.arrayOf(PropTypes.string),
    sortValue: PropTypes.shape({})
  }).isRequired,
  dataGroup: PropTypes.arrayOf(PropTypes.shape({ })),
  dataDeviceContent: PropTypes.arrayOf(PropTypes.shape({ }))
}

const defaultProps = {
  isOpen: false,
  dataGroup: [],
  dataDeviceContent: []

}

/**
 *
 */

function FilterModal ({ isOpen, onClose, onSuccess, filterModel, dataGroup, dataDeviceContent }) {
  const { t } = useTranslation()
  const [filters, setFilters] = useState(filterModel)
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  const handleClose = () => {
    onClose()
  }

  const clearAll = () => {
    if (filters.viewSelected.id && !filters.customViewSelected.id) setFilters({ ...filters, remainingDaysFilters: [], levelsFilters: [], deviceContentsFilters: [], statusFilters: [], batteryFilters: null })
    else setFilters({ ...filters, remainingDaysFilters: [], groupsFilters: [], levelsFilters: [], deviceContentsFilters: [], statusFilters: [], batteryFilters: null })
  }
  const handleValidate = () => {
    onClose()
    onSuccess(filters)
  }
  return (
    <NanoDialog
      title={t('filters_form_title')}
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: mobileFormat ? '80%' : '33%',
          overflow: 'hidden',
          position: 'fixed',
          right: 0,
          m: 0
        }
      }}
      fullScreen
    >
      <Stack direction='column' spacing={4} pt={3}>
        <SelectRemainingDays
          value={filters.remainingDaysFilters}
          placeholder='remaining_days'
          handleSelect={(value) => setFilters({ ...filters, remainingDaysFilters: value })}
        />

        <NanoSelectMultiple
          disabled={!!filters.viewSelected.id && !filters.customViewSelected.id}
          placeholder='group_other'
          options={dataGroup.filter((g) => g.is_poi).map((g) => ({ name: g.group_name, value: g.group_id }))}
          value={filters.groupsFilters}
          handleSelect={(value) => setFilters({ ...filters, groupsFilters: value })}
        />

        <NanoSelectMultiple
          placeholder='device_content'
          options={dataDeviceContent}
          value={filters.deviceContentsFilters}
          handleSelect={(value) => setFilters({ ...filters, deviceContentsFilters: value })}
        />
        <NanoSelectMultiple
          placeholder='level_filter'
          options={levelFilterModel}
          value={filters.levelsFilters}
          handleSelect={(value) => { setFilters({ ...filters, levelsFilters: value }) }}
        />

        <NanoSelectMultiple
          placeholder='sensor_status'
          options={statusFilterModel}
          value={filters?.statusFilters}
          handleSelect={(value) => { setFilters({ ...filters, statusFilters: value.flatMap((v) => v) }) }}
        />
        <SelectBattery value={filters?.batteryFilters} onChange={(value) => { setFilters({ ...filters, batteryFilters: value }) }} />
        {/* <NanoSelectSingle
          options={batteryFilterModel}
          label={t('battery')}
          variant='outlined'
          onChange={(value) => { setFilters({ ...filters, batteryFilters: value.flatMap((v) => v) }) }}
        /> */}
      </Stack>

      <Stack direction='row' pt={3} spacing={3} justifyContent='space-between'>
        <Button sx={{ minWidth: '40%', color: 'text.secondary' }} color='grey' onClick={clearAll}>
          {t('clear')}
        </Button>
        <Button sx={{ minWidth: '40%' }} onClick={handleValidate}>
          {t('see_results')}
        </Button>
      </Stack>

    </NanoDialog>
  )
}

FilterModal.propTypes = propTypes
FilterModal.defaultProps = defaultProps
// #endregion

export default FilterModal
