import React from 'react'
import PropTypes from 'prop-types'
import { orderDatesToString, removeDuplicates } from '../utils/orderUtils'
import { dateLongWithTime } from '../../../shared/utils/dateUtils'
import { useTranslation } from 'react-i18next'
import GroupsDevicesCell from '../../../shared/components/GroupsDevicesCell'
import UserAvatarAndName from '../../../shared/components/UserAvatarAndName'
import NanoCharacteristics from '../../../shared/components/NanoCharacteristics'
import ValidateCommandButton from './ValidateCommandButton'

// #region constants
const columns = (t, order, loading, handleValidateOrder) => [
  {
    title: t('order_total_quantity'),
    renderValue: t('number_workspace_filling_unit', { value: order.totalTonnage })
  },
  {
    title: t('order_created_by'),
    renderValue: <UserAvatarAndName {...order.created_by} />
  },
  {
    title: t('created_at'),
    renderValue: order.created_at ? dateLongWithTime(order.created_at) : '-'
  },
  {
    title: t('group_other'),
    renderValue: <GroupsDevicesCell
      readOnly
      loading={loading}
      device_groups_to_check={loading ? [] : removeDuplicates(order.silos.map(s => ({ group_name: s.farm_name, group_id: s.group_ids[0] })))}
                 />
  }, {
    title: t('order_delivery_dates'),
    renderValue: orderDatesToString({ order }) ?? '-'
  },
  {
    title: t('order_time_slot'),
    renderValue: order.timeSlot ? t(order.timeSlot) : '-'
  },
  {
    title: t('comment'),
    renderValue: order.comment ?? '-'
  },
  {
    title: t('status'),
    renderValue: <ValidateCommandButton isValidated={order.status === 'validated'} order={order} onUpdateValidated={handleValidateOrder} />
  }

]

const formColumns = (t, order) => [
  {
    title: t('order_delivery_dates'),
    renderValue: orderDatesToString({ order }) ?? '-'
  },
  {
    title: t('order_time_slot'),
    renderValue: order.timeSlot ? t(order.timeSlot) : '-'
  },
  {
    title: t('order_total_quantity'),
    renderValue: t('number_workspace_filling_unit', { value: order.totalTonnage })
  }
]

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  order: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  fromForm: PropTypes.bool,
  handleValidateOrder: PropTypes.func
}

const defaultProps = {
  order: {},
  loading: false,
  fromForm: false,
  handleValidateOrder: null
}

/**
 *
 */
function OrderCharacteristics ({ order, loading, fromForm, handleValidateOrder }) {
  const { t } = useTranslation()
  /**
   * when displaying an order from the creation path,
   * certain information is not displayed (id, created_by, created_at).
   * The general comment is displayed in a text field (input).
   */
  return (
    <NanoCharacteristics
      columns={fromForm ? formColumns(t, order) : columns(t, order, loading, handleValidateOrder)}
      loading={loading}
    />
  )
}

OrderCharacteristics.propTypes = propTypes
OrderCharacteristics.defaultProps = defaultProps
// #endregion

export default OrderCharacteristics
