const errorMessages = (t) => {
  const errorMessages = [
    {
      message: 'User does not exist',
      code: t('user_not_exists')
    },
    {
      message: 'invalid old_password',
      code: t('invalid_password')
    },
    {
      message: 'min_value must be less than max_value',
      code: t('min_less_max_value')
    }
  ]

  return errorMessages
}

export default errorMessages
