// StyledPopup.js
import { Popup } from 'react-leaflet'
import { styled } from '@mui/material/styles'

const StyledPopup = styled(Popup)`
  & .leaflet-popup-content-wrapper {
    background : white;
    box-shadow : 0px 4px 40px rgba(0, 0, 0, 0.08);  
    border-radius: 8px;
    & .leaflet-popup-content {
      width: 890px !important;
      & .MuiTypography-body2 {
        margin: 0; 
      }
      & .MuiTypography-body1 {
        margin: 0; 
      }
    }
  }
  & .leaflet-popup-tip {
    visibility : hidden;
  }
  .leaflet-container & .leaflet-popup-close-button {
      font: 24px/24px Tahoma, Verdana, sans-serif;
      top: 10px;
      right: 10px;
    }  
  `

export default StyledPopup
