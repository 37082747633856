const levelFilter = Object.freeze([
  {
    name: 'level_high',
    value: '0'
  },
  {
    name: 'level_avg',
    value: '1'
  },
  {
    name: 'level_low',
    value: '2'
  }
])
export default levelFilter
