import PropTypes from 'prop-types'
import { useSessionStore } from '../store'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  resource: PropTypes.string.isRequired,
  operation: PropTypes.arrayOf(PropTypes.string),
  // onlyGlobal: PropTypes.bool,
  fallbackComponent: PropTypes.element, // fallback with a component
  fallbackCallback: PropTypes.func, // fallback with a function
  children: PropTypes.element
}

const defaultProps = {
  fallbackComponent: null,
  fallbackCallback: null,
  operation: ['READ']
}

/**
* @param {PropTypes.InferProps<propTypes>} props
 */
const AccessRoleComponent = ({ resource, operation, children, fallbackComponent, fallbackCallback }) => {
  const me = useSessionStore((state) => state.user)
  const hasPermission = (
    me.workspace_role.authorisation[resource]?.includes('*') ||
    me.workspace_role.authorisation[resource]?.every((op) => operation.includes(op))
  )
  if (hasPermission) {
    return children
  }

  if (fallbackComponent) {
    return fallbackComponent
  } else if (fallbackCallback) {
    return fallbackCallback()
  }
}
AccessRoleComponent.propTypes = propTypes
AccessRoleComponent.defaultProps = defaultProps
// #endregion

export default AccessRoleComponent
