import React from 'react'
import PropTypes from 'prop-types'
import LevelForecastCell from '../../dashboard/cells/LevelForecastCell'
import NanoTable from '../../../shared/components/NanoTable'
import NanoPaper from '../../../shared/components/NanoPaper'
import LevelTodayCell from '../../dashboard/cells/LevelTodayCell'
import LevelTodayErrorCell from '../../dashboard/cells/LevelTodayErrorCell'
import LastDataCell from '../../dashboard/cells/LastDataCell'
import { TableCell } from '@mui/material'
import RemainingDays from '../../../shared/components/RemainingDays'
import DeliveryCell from '../../dashboard/cells/DeliveryCell'

// #region constants
const columns = (fromMap) => [
  {
    format: (row) =>
      row.status === 'ok'
        ? <LevelTodayCell {...row} level={row.levels[0]} />
        : <LevelTodayErrorCell {...row} />,
    width: 400
  },
  ...[...Array(4).keys()].map((i) => (
    {
      format: (row) =>
        row.status === 'ok' && !row.has_parent_combined_device
          ? <LevelForecastCell {...row} day={i + 1} level={row.levels[i + 1]} fromMap={fromMap} />
          : null,
      width: 150,
      hide: 'lg'
    }
  )),
  {
    format: (row) =>
      row.status === 'ok' && !row.has_parent_combined_device
        ? (
          <TableCell sx={{ pl: 2, pr: 2, m: 8 }}>
            <RemainingDays {...row} />
          </TableCell>

          )
        : (null),
    width: 350,
    hide: 'sm'
  },
  {
    header: 'actions',
    format: (row) =>
      row.status === 'ok'
        ? <LastDataCell {...row} />
        : null,
    width: 300,
    hide: fromMap
  },
  {
    format: (row) =>
      row.device_last_delivery &&
  (row.status === 'ok' && !row.has_parent_combined_device)
        ? <DeliveryCell {...row} />
        : null,
    width: 400,
    hide: !fromMap
  }

]
// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  fromMap: PropTypes.bool
}

const defaultProps = { loading: true, fromMap: false }

/**
 *
 */
function Forecasts ({ data, loading, fromMap }) {
  return (

    <div style={{ display: 'flex' }}>
      {!loading
        ? <NanoTable
            columns={columns(fromMap)}
            data={data}
            loading={loading}
            tableProps={{
              disableHeader: true
            }}
            useStyledTable={false}
            nanoPaperProps={{ p: 2 }}
          />
        : <NanoPaper />}

    </div>

  )
}

Forecasts.propTypes = propTypes
Forecasts.defaultProps = defaultProps
// #endregion

export default Forecasts
