import React, { useContext, useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import Background from './components/Background'
import { Box, Button, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import NanoPassword from '../../shared/components/NanoPassword'
import { Controller, useForm } from 'react-hook-form'
import { client } from '../../shared/apiClient'
import { ToastContext } from '../../shared/contexts'
import { useSessionStore } from '../../shared/store'
import { LoadingButton } from '@mui/lab'
import AlertForm from '../alerts/AlertForm'
import i18n from '../../config/i18n'
import StepCircularProgress from '../../shared/components/StepCircularProgress'
import getUrl from '../../shared/utils/urlCons'
import AccessRoleComponent from '../../shared/components/AccessRoleComponent'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
const Register = () => {
  const { t } = useTranslation()
  const [isValid, setIsValid] = useState(false)
  const [step, setStep] = useState(0)
  const [groups, setGroups] = useState([])
  const [registerLoading, setRegisterLoading] = useState(false)
  const toastContext = useContext(ToastContext)
  const [tokenValidationLoading, setTokenValidationLoading] = useState(false)
  const currentClient = useSessionStore((state) => state.currentClient)
  const me = useSessionStore((state) => state.user)
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')
  const theme = useTheme()
  const goBackHome = () => useSessionStore.setState({ connected: true })
  const {
    handleSubmit, control
  } = useForm()
  const onSubmit = async (data) => {
    setRegisterLoading(true)
    // accept terms by default
    client.POST('/v1/register', { params: { query: { token } }, body: { is_terms_accepted: true, ...data } })
      .then(async (response) =>
        await useSessionStore.getState().signin({ username: response.data.email, ...data }, false, false))
      .catch((err) => toastContext.sendMessage(err.message, 'error')).finally(() => { setRegisterLoading(false) })
  }
  useEffect(() => {
    setTokenValidationLoading(true)
    client.GET('/v1/register', { params: { query: { token } } }).then(resp => {
      setTokenValidationLoading(false)
      if (resp.data.lang && i18n.resolvedLanguage !== resp.data.lang) i18n.changeLanguage(resp.data.lang)
    }).catch(err => {
      toastContext.sendMessage(err.message, 'error')
      setTokenValidationLoading(false)
      goBackHome()
    })
  }, [token])

  useEffect(() => {
    // Really tricky, we go to next step only if user and groups associated or not User.
    // But it could be a blocking step if something wierd happen
    // We could change the return of the register Get method to have those informations
    if (me) {
      if (!me.workspace_role?.global) { // indicates is not a global admin
        client.GET('/v1/groups', { params: { query: { page_size: 2 } } }).then(result => {
          setGroups(result.data.results)
          if (result.data.rowCount) setStep(1)
          else goBackHome()
        })
      } else setStep(1)
    }
  }, [me])
  const darkMode = (!useMediaQuery(theme.breakpoints.down('sm')))
  return (
    <Background loading={tokenValidationLoading}>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        pt: 5
      }}
      >
        <StepCircularProgress step={step} nbSteps={2} darkMode={darkMode} />

      </Box>
      {step === 1
        ? (
          <AccessRoleComponent
            resource='Alert'
            operation={['READ']}
            fallbackCallback={() => goBackHome()}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='h1' alignSelf='center'>
                {t('register_create_first_alert')}
              </Typography>
              <Typography variant='body1' color='text.secondary' alignSelf='center' py={3}>
                {t('register_create_first_alert_description')}
              </Typography>
              <AlertForm onSuccess={goBackHome} row={{ device_groups_to_check: groups }} fromRegister />
              <Button variant='text' onClick={goBackHome}> {t('register_create_alert_later')} </Button>
            </Box>
          </AccessRoleComponent>
          )
        : (
          <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant='h1'>
              {t('register_set_password')}
            </Typography>
            <Controller
              name='password'
              control={control}
              defaultValue=''
              rules={{ required: t('form_field_required_error_message') }}
              render={({ field, fieldState: { error } }) => (
                <NanoPassword
                  {...field}
                  label={t('signin_text_field_password_label')}
                  error={!!error}
                  validatePassword
                  helperText={error?.message}
                  isValid={setIsValid}
                />
              )}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <LoadingButton loading={registerLoading} disabled={!isValid} type='submit'> {t('validate')} </LoadingButton>
            </Box>
            <Typography maxWidth={500} align='center' alignSelf='center'>

              <Trans
                i18nKey='register_terms_and_con'
                components={{
                  linkterms: <Link color='secondary' underline='none' href={getUrl(currentClient?.name?.toLocaleLowerCase, t)} target='_blank' title={t('terms_and_con_menu')} rel='noreferrer' />
                }}
              />
            </Typography>
          </Box>
          )}
    </Background>
  )
}

Register.propTypes = propTypes
Register.defaultProps = defaultProps
// #endregion

export default Register
