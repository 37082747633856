import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { ReactComponent as CloseIcon } from '../icons/CloseIcon.svg'

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  actions: PropTypes.node
}

const defaultProps = { actions: null, title: null }

/**
 * Custom Dialog to set up Title and Close icon for all
 * Forward everything to original Dialog Component
  * @param {import('@mui/material').DialogProps & PropTypes.InferProps<propTypes>} props
 */
function NanoDialog ({ title, actions, onClose, onClick, ...props }) {
  // Prevent click on the dialog or backdrop to propagate to parent
  // element responsible from creating the dialog.
  const catchOnClick = useCallback((e) => {
    e.stopPropagation()

    if (onClick) {
      onClick(e)
    }
  }, [onClick])

  return (
    <Dialog
      PaperProps={{
        sx:
      {
        width: '50%',
        maxHeight: '90%'
      }
      }}
      onClick={catchOnClick}
      onClose={onClose}
      {...props}
    >
      {Boolean(title) && (
        <DialogTitle>
          <Stack direction='row' justifyContent='end'>
            <IconButton onClick={(e) => onClose(e, 'backdropClick')}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack>
            <Typography variant='h2'>
              {title}
            </Typography>
          </Stack>

        </DialogTitle>
      )}
      <DialogContent>
        {props.children}
      </DialogContent>
      {!!actions?.length && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  )
}

NanoDialog.propTypes = propTypes
NanoDialog.defaultProps = defaultProps

export default NanoDialog
