import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import SimpleCell from '../../../../shared/components/SimpleCell'
import isNumber from '../../../../shared/utils/isNumber'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  devices_to_check: PropTypes.arrayOf(PropTypes.shape({
    device_name: PropTypes.string
  })),
  device_groups_to_check: PropTypes.arrayOf(PropTypes.shape({
    group_name: PropTypes.string
  })),
  min_value: PropTypes.number,
  max_value: PropTypes.number,
  data_type: PropTypes.string
}

const defaultProps = {
  loading: false,
  devices_to_check: [],
  device_groups_to_check: [],
  min_value: null,
  max_value: null,
  data_type: null
}

/**
 *
 */
function AlertDetailsCell ({
  loading,
  devices_to_check: devicesToCheck,
  device_groups_to_check: deviceGroupsToCheck,
  min_value: minValue,
  max_value: maxValue,
  data_type: dataType
}) {
  const { t } = useTranslation()

  const deviceNamesToCheck = devicesToCheck?.map((item) => item.device_name) ?? []
  const groupNamesToCheck = deviceGroupsToCheck?.map((item) => item.group_name) ?? []

  const min = parseFloat(minValue)
  const max = parseFloat(maxValue)

  let toCheckName = ''
  deviceNamesToCheck.forEach((item) => {
    if (toCheckName.length > 0) {
      toCheckName += ', '
    }
    toCheckName += item
  })
  groupNamesToCheck.forEach((item) => {
    if (toCheckName.length > 0) {
      toCheckName += ', '
    }
    toCheckName += item
  })

  let valueIndicator = ''

  /**
   * The Alerts API can't give us the units linked to dataType. We have to do the mapping ourselves using formatters
   */
  if (isNumber(min) && isNumber(max)) {
    if (dataType === 'level_t') {
      valueIndicator = `[${t('number_workspace_filling_unit', { value: min })}, ${t('number_workspace_filling_unit', { value: max })}]`
    } else if (dataType === 'level_percent') {
      valueIndicator = `[${t('data_type_unit', { value: { value: min, unit: 'percent' } })}, ${t('data_type_unit', { value: { value: max, unit: 'percent' } })}]`
    } else if (dataType === 'remainingDays') {
      valueIndicator = `[${t('data_type_unit', { value: { value: min, unit: 'day' } })}, ${t('data_type_unit', { value: { value: max, unit: 'day' } })}]`
    }
  } else if (isNumber(min) || isNumber(max)) {
    if (dataType === 'level_t') {
      valueIndicator = `${t('number_workspace_filling_unit', { value: min || max })}`
    } else if (dataType === 'level_percent') {
      valueIndicator = `${t('data_type_unit', { value: { value: min || max, unit: 'percent' } })}`
    } else if (dataType === 'remainingDays') {
      valueIndicator = `${t('data_type_unit', { value: { value: min || max, unit: 'day' } })}`
    }

    if (isNumber(max)) {
      valueIndicator = `${t('alerts_details_exceeds')} ${valueIndicator}`
    }
  }

  return (
    <SimpleCell sx={{ pr: 4 }} loading={loading} data={`${valueIndicator}`} />
  )
}

AlertDetailsCell.propTypes = propTypes
AlertDetailsCell.defaultProps = defaultProps
// #endregion

export default AlertDetailsCell
