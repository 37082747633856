import React from 'react'
// import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import RemainingDays from '../../../shared/components/RemainingDays'
// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}
const defaultProps = {}

/**
 *
 */
function RemainingDaysCell ({ ...row }) {
  return (
    <TableCell sx={{ pl: 3, pr: 3 }}>
      <RemainingDays {...row} />
    </TableCell>

  )
}

RemainingDaysCell.propTypes = propTypes
RemainingDaysCell.defaultProps = defaultProps
// #endregion

export default RemainingDaysCell
