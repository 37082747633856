import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { AutoSizer, Column, InfiniteLoader, Table, WindowScroller } from 'react-virtualized'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import NanoPaper from './NanoPaper'
import NotFoundData from './NotFoundData'
import SortIndicator from './SortIndicator'

// #region constants
const HEADER_HEIGHT = 43
const PAGE_SIZE = 15 // This page size must be always greater or equal than the page size from the request.

// #endregion

// #region styled-components

// #endregion

// #region functions
// #endregion

// #region component
const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string,
    format: PropTypes.func,
    width: PropTypes.number.isRequired,
    flexGrow: PropTypes.number,
    flexShrink: PropTypes.number,
    hide: PropTypes.bool || PropTypes.string || PropTypes.func,
    enableSort: PropTypes.bool
  })).isRequired,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ROW_HEIGHT: PropTypes.number,
  remoteRowCount: PropTypes.number,
  fetchPage: PropTypes.func,
  nanoPaperProps: PropTypes.shape({}),
  /** @type {PropTypes.Requireable<Partial<import('react-virtualized').TableProps>>} */
  tableProps: PropTypes.shape({}),
  notFoundDataCustomText: PropTypes.string,
  useStyledTable: PropTypes.bool,
  scrollElement: PropTypes.shape({})
}

const defaultProps = {
  loading: false,
  ROW_HEIGHT: 93,
  tableProps: {},
  remoteRowCount: null,
  notFoundDataCustomText: null,
  fetchPage: null,
  nanoPaperProps: {},
  useStyledTable: true,
  scrollElement: null
}

const headerRenderer = ({ dataKey, sortBy, sortDirection }) => (
  <Box display='flex' alignItems='center'>
    <Typography variant='h6' color='text.secondary'>
      {dataKey === 'actions' ? '' : dataKey}
    </Typography>
    {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
  </Box>
)
const gradient = 'linear-gradient(272deg, #FAFAFB, white, #FAFAFB)' // @FIX ME : create a gradient main and contrast direcctly in the theme
// @ts-ignore styled must be used only with MUI element
const StyledTable = styled(Table)`
    .ReactVirtualized__Table__row {
        :hover {
            background: ${gradient}  
        }
    }
`
/**
 * Table respecting the ux/ui provided.
 * Use react-virtualized to unload line not displayed
 * @param {PropTypes.InferProps<propTypes>} params
 */
function NanoTable ({ columns, data, loading, ROW_HEIGHT, remoteRowCount, fetchPage, useStyledTable, nanoPaperProps, tableProps, notFoundDataCustomText, scrollElement }) {
  const { t } = useTranslation()
  const isRowLoaded = ({ index }) => {
    return (fetchPage && !loading ? !!data[index] : true)
  }// if no fetch page we assume we are not using pagination, if loading we consider all here, maybe source of problem but prevent double call on changing filter
  const computeDisplayedRow = (height) => Math.max(Math.round((height - HEADER_HEIGHT) / ROW_HEIGHT), PAGE_SIZE * 2)

  const TableComponent = useStyledTable ? StyledTable : Table
  const theme = useTheme()
  const cols = columns.filter(c => !(c.hide && (typeof c.hide !== 'string' || useMediaQuery(theme.breakpoints.down(c.hide)))))
  if (!loading && !data.length) return <NotFoundData customText={notFoundDataCustomText} />
  return (
    <NanoPaper {...nanoPaperProps}>
      <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={fetchPage} rowCount={remoteRowCount ?? data.length} threshold={5}>
        {({ onRowsRendered, registerChild }) => (
          // @ts-ignore scrolleElement type is Element but we cannot type it with proptypes
          <WindowScroller scrollElement={scrollElement ?? window}>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  // @ts-ignore styled must be used only with MUI element
                  <TableComponent
                    stickyHeader
                    options={{
                      exportButton: true,
                      exportAllData: true
                    }}
                    autoHeight
                    height={height}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    scrollTop={scrollTop}
                    onRowsRendered={onRowsRendered}
                    ref={registerChild}
                    width={width}
                    rowHeight={ROW_HEIGHT}
                    rowStyle={({ index }) => ({
                      display: 'flex',
                      alignItems: 'center',
                      boxSizing: 'border-box',
                      cursor: tableProps.onRowClick ? 'pointer' : 'default',
                      zIndex: 0,
                      borderBottom: index === -1 ? null : index === data.length - 1 ? null : '1px solid rgba(239, 239, 243, 1)'
                    })}
                    headerHeight={HEADER_HEIGHT}
                    rowCount={loading ? computeDisplayedRow(height) : data.length}
                    rowGetter={({ index }) => ((loading || (remoteRowCount && data.length < remoteRowCount && index === data.length - 1)) ? { loading: true } : data[index])} // if paginated the last row is loading while waiting for the next page.
                    {...tableProps}
                  >
                    {cols.map((col) => (
                      <Column
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          boxSizing: 'border-box',
                          height: '100%',
                          justifyContent: col.header === 'actions' ? 'flex-end' : 'flex-start'
                        }}
                        key={col.header}
                        disableSort={!col.enableSort} // default disable
                        headerRenderer={headerRenderer}
                        cellRenderer={({ rowData }) => col.format(rowData, t)} // send translation if
                        dataKey={t(col.header)}
                        flexGrow={col.header === 'actions' ? 1 : col.flexGrow}
                        width={col.width}
                        {...col}
                      />
                    ))}
                  </TableComponent>
                )}
              </AutoSizer>
            )}

          </WindowScroller>
        )}
      </InfiniteLoader>
    </NanoPaper>
  )
}

NanoTable.propTypes = propTypes
NanoTable.defaultProps = defaultProps
// #endregion

export default NanoTable
