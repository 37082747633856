import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, Stack, Tab, Tabs, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
// import FilterBar from '../../../shared/components/FilterBar'
import NanoTable from '../../../shared/components/NanoTable'
import { client, postRequest } from '../../../shared/apiClient'
import { useSessionStore } from '../../../shared/store'
import NotificationCell from './NotificationCell'
import { sendEvent } from '../../../shared/utils/analyticsUtils'

// #region constants
const columns = (changeStatus) => [
  {
    header: 'notifications',
    width: 400,
    flexGrow: 1,
    format: (row) => (
      <NotificationCell {...row} changeStatus={changeStatus} />
    )
  }
]
// #endregion

// #region styled-components
const InlineBadge = styled(Badge)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  '& .MuiBadge-badge': {
    right: 12
  }
}))
// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  /** indicated that whe are in in a popover mode by sending the scrolling component.
   * represent when the list is displayed in the popover in the toppage. We deactivate some feature such as the search
   * */
  popoverElement: PropTypes.shape({
    current: PropTypes.shape({})
  })
}

const defaultProps = { popoverElement: null }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function NotificationsList ({ popoverElement }) {
  const { t } = useTranslation()
  const [value, setValue] = useState(0)
  const [notificationsLoading, setNotificationsLoading] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [remoteRowCount, setRemoteRowCount] = useState(null)
  const { fetchUnread, unreadNotificationsCount } = useSessionStore((state) => state)
  const [page, setPage] = useState(1)
  const tabs = useMemo(() => [
    { value: 'all', label: t('all_notifications') },
    { value: 'alert', label: t('alerts_title') },
    { value: 'order', label: t('orders_title') },
    { value: 'sensorInfos', label: t('sensorInfos_title') }
  ], [t])
  const loadMoreRows = useCallback(async (props) => {
    const query = { page: props.newTab ? 1 : page + 1, ordering: '-created_at' }
    if (tabs[value].value !== 'all') query.action = tabs[value].value
    if (props.newTab)setNotificationsLoading(true)
    client.GET('/internal/notifications/getuserwebnotifications', { params: { query } }).then((result) => {
      const { results, ...pagination } = result.data
      setRemoteRowCount(result.data.rowCount)
      setNotifications(props.newTab ? results : [...notifications, ...results])
      setPage(pagination.page)
    }).finally(() => setNotificationsLoading(false))
  }, [value, page, notifications])

  useEffect(() => {
    loadMoreRows({ newTab: true })
  }, [value])

  const handleChange = (e, newValue) => {
    setValue(newValue)
  }
  const handleMakAllOpen = async () => {
    const payload = {}
    if (tabs[value].value !== 'all') payload.action = tabs[value].value
    client.POST('/internal/notifications/markopen', { body: { ...payload } })
      .then(() => {
        sendEvent('notifications_marked_as_open')
        fetchUnread()
        setNotifications(notifications.map((n) => ({ ...n, status: 'open' })))
      })
  }

  const changeStatus = useCallback((id, currentStatus) => {
    postRequest('internal/notifications/markopen', { status: currentStatus !== 'open' ? 'open' : 'created', ids: [id] })
    // patchRequest(`internal/notifications/${id}`, { status: currentStatus !== 'open' ? 'open' : 'created' })
      .then(() => setNotifications(notifications.map((n) => (n.id === id ? { ...n, status: currentStatus !== 'open' ? 'open' : 'created' } : n)))).then(() => fetchUnread()).then(() => sendEvent('notification_status_changed', { status: currentStatus }))
  }, [notifications, fetchUnread])
  return (
    <>
      {popoverElement
        ? (
          <Stack justifyContent='space-between' alignItems='center' direction='row' sx={{ py: 3, position: 'sticky', top: 0, zIndex: 99, backgroundColor: 'white' }}>
            <Typography variant='h4'>
              {t('notifications')}
            </Typography>
            <Button variant='text' sx={{ p: 0 }} onClick={handleMakAllOpen}>{t('markAllOpen')}</Button>
          </Stack>
          )
        : (
          <Stack py={2} direction='row' alignItems='center' alignContent='center' justifyContent='space-between'>
            <Tabs variant='scrollable' value={value} onChange={handleChange}>
              {tabs.map((tab, index) =>
                <Tab icon={<InlineBadge badgeContent={unreadNotificationsCount[tab.value] || '0'} color='grey' />} iconPosition='start' key={index} sx={{ padding: 0 }} label={tab.label} />)}
            </Tabs>
            <Button variant='text' sx={{ p: 0 }} onClick={handleMakAllOpen}>{t('markAllOpen')}</Button>
          </Stack>
          )}
      <NanoTable scrollElement={popoverElement?.current} notFoundDataCustomText={t('not_found_notifications')} tableProps={{ disableHeader: true }} nanoPaperProps={{ sx: { backgroundColor: 'none', border: 'none', p: 0, height: '100%' } }} columns={columns(changeStatus)} data={notifications} loading={notificationsLoading} fetchPage={loadMoreRows} remoteRowCount={remoteRowCount} />
    </>
  )
}

// #endregion

NotificationsList.propTypes = propTypes
NotificationsList.defaultProps = defaultProps

export default NotificationsList
