import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs, useTheme, useMediaQuery } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { debounce } from '@mui/material/utils'
import TopPage from '../layout/TopPage'
import NanoTable from '../../shared/components/NanoTable'
import { client } from '../../shared/apiClient'
import { ToastContext } from '../../shared/contexts'
import MoreActionAlerts from './MoreActionAlerts'
import ButtonAdd from '../../shared/components/ButtonAdd'
import AccessRoleComponent from '../../shared/components/AccessRoleComponent'
import AlertTypeCell from './models/cells/AlertTypeCell'
import AlertDetailsCell from './models/cells/AlertDetailsCell'
import AlertRecipientsCell from './models/cells/AlertRecipientsCell'
import GroupsDevicesCell from '../../shared/components/GroupsDevicesCell'
import AlertStateCell from './models/cells/AlertStateCell'
import AlertDateCell from './models/cells/AlertDateCell'
import SimpleCell from '../../shared/components/SimpleCell'
import { useUserStore } from '../../shared/store'
import AlertFilterDataType from './AlertFilterDataType'
import NanoSelectMultiple from '../../shared/components/NanoSelectMultiple'
import AlertModal from './AlertModal'
import FilterBar from '../../shared/components/FilterBar'

// #region constants

const columns = (users, handleEdit, getData) => [
  {
    header: 'name',
    width: 220,
    flexGrow: 2,
    format: (row, key) => (
      <SimpleCell
        key={key} loading={row.loading} data={row.alert_name} variant='h5'
      />

    )
  },
  {
    header: 'type',
    width: 220,
    hide: 'lg',
    flexGrow: 2,
    format: (row, key) => (
      <AlertTypeCell key={key} {...row} />
    )
  },
  {
    header: 'details',
    width: 220,
    hide: 'sm',
    flexGrow: 1,
    format: (row, key) => (
      <AlertDetailsCell
        key={key}
        {...row}
      />
    )
  },

  {
    header: 'alerts_recipients',
    width: 224,
    hide: 'sm',
    flexGrow: 2,
    format: (row, key) => {
      return (
        <AlertRecipientsCell
          key={key}
          users={users}
          {...row}
        />
      )
    }
  },

  {
    header: 'alerts_last_triggered',
    width: 180,
    flexGrow: 1,
    hide: 'lg',
    format: (row, key) => (
      <AlertDateCell
        key={key}
        {...row}
      />
    )
  },

  {
    header: 'menu_dashboard',
    width: 340,
    hide: 'sm',
    flexGrow: 3,
    format: (row, key) => (
      <GroupsDevicesCell key={key} {...row} />
    )
  },
  {
    header: 'state',
    width: 60,
    flexGrow: 0.5,
    format: (row) => (
      <AlertStateCell
        {...row}
        // Refresh data after state change
        onUpdated={getData}
      />
    )
  },
  {
    header: 'actions',
    width: 50,
    format: (row) => (
      <MoreActionAlerts handleUpdateOrDelete={(action) => handleEdit(action, row)} />
    )
  }
]

/** useState but with a current and debounced value  */
function useDebouncedState (initialValue, delay) {
  const [currentValue, setCurrentValue] = useState(initialValue)
  const [debouncedValue, setDebouncedValue] = useState(initialValue)

  const setDebouncedValueDebounced = useCallback(
    debounce(setDebouncedValue, delay),
    [setDebouncedValue]
  )

  const setValue = useCallback((newValue) => {
    setCurrentValue(newValue)
    setDebouncedValueDebounced(newValue)
  }, [])

  return {
    current: currentValue,
    debounced: debouncedValue,
    set: setValue
  }
}

const TABS = {
  MY_ALERTS: 0,
  ALL_ALERTS: 1
}

const propTypes = {}

const defaultProps = {}

function Alerts () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [dataLoading, setDataLoading] = useState(true)
  const [defaultValues, setDefaultValues] = useState({})
  const [formIsOpen, setFormIsOpen] = useState(false)
  const toastContext = useContext(ToastContext)
  const users = useUserStore((state) => state.dataUser)
  const [filterDataType, setFilterDataType] = useState([])
  const [filterGroups, setFilterGroups] = useState([])
  const [groupsOption, setGroupsOption] = useState([])
  const theme = useTheme()
  const search = useDebouncedState('', 500)
  const location = useLocation()
  const [tab, setTab] = useState(Number(new URLSearchParams(location.search).get('tab')) ?? TABS.MY_ALERTS)
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))

  const getData = useCallback(async (searched = '', tab, filterDataType = [], filterGroups = []) => {
    setDataLoading(true)
    try {
      const query = {
        v2: true,
        // Need to pass tab from debounce to catch the new value
        myAlerts: tab === TABS.MY_ALERTS,
        data_type: filterDataType.length ? filterDataType.join() : undefined,
        group_ids: filterGroups.length ? filterGroups.join() : undefined,
        search: searched
      }
      const response = await client.GET('/v1/alerts', { params: { query } })
      setData(response.data.results)
    } finally {
      setDataLoading(false)
    }
  }, [])

  useEffect(() => {
    client.GET('/v1/groups', { params: { query: { is_poi: true } } }).then(res => setGroupsOption(res.data.map(g => ({ name: g.group_name, value: g.group_id }))))
    useUserStore.getState().fetchData()
  }, [])

  useEffect(() => {
    getData(search.debounced, tab, filterDataType, filterGroups)
  }, [search.debounced, tab, filterDataType, filterGroups])

  const handleAlertDidCreate = (isUpdate) => {
    isUpdate ? toastContext.sendMessage(t('alerts_snackbar_alert_updated')) : toastContext.sendMessage(t('alerts_snackbar_alert_created'))
    getData(search.current, tab, filterDataType, filterGroups)
  }

  /**
   * operation called by the MoreActionAlerts
   * @param {'update' | 'delete'} action
   * @param {import('../../../openapiDoc').components['schemas']['alert']} row
   */
  const handleUpdateOrDelete = (action, row) => {
    if (action === 'delete') {
      return client.DELETE('/v1/alerts/{id}', { params: { path: { id: row.alert_id } } }).then(() => getData())
    } else {
      setDefaultValues(row)
      setFormIsOpen(true)
    }
  }
  const handleClick = ({ rowData }) => !dataLoading && navigate(`/alerts/${rowData.alert_id}`)

  const NanoTableProps = {
    columns: columns(users, handleUpdateOrDelete, getData),
    data,
    loading: dataLoading,
    tableProps: {
      onRowClick: handleClick
    }
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%' }}>
      <TopPage
        title={mobileFormat ? null : t('alerts_title')}
        actions={<ButtonAdd text={t('alerts_button_title')} onClick={() => { setFormIsOpen(true) }} />}
      />
      <AccessRoleComponent
        resource='User'
        operation={['CREATE', 'UPDATE']}
      >
        {/* TODO: find a way to integrate this in filterBar */}
        <FilterBar
          withTabs
          isSearch
          searchFieldPlaceholder='search'
          searchFieldAction={search.set}
          searchFieldValue={search.current}
        >
          <Tabs value={tab} onChange={(_, newTab) => { navigate(`/alerts?tab=${newTab}`); setTab(newTab) }}>
            <Tab label={t('alerts_my_alerts')} />
            <Tab label={t('alerts_all_alerts')} />
          </Tabs>
          {!mobileFormat &&
          (
            <>
              <AlertFilterDataType value={filterDataType} handleSelect={setFilterDataType} />
              <NanoSelectMultiple
                placeholder={t('group_other')}
                options={groupsOption}
                loading
                value={filterGroups}
                handleSelect={(value) => setFilterGroups(value)}
              />
            </>
          )}
        </FilterBar>
      </AccessRoleComponent>
      {tab === TABS.ALL_ALERTS
        ? (
          <AccessRoleComponent
            resource='User'
            operation={['CREATE', 'UPDATE']}
            fallbackComponent={(
              <NanoTable
                {...NanoTableProps}
                columns={columns(users, handleUpdateOrDelete).filter((col) => col.header !== 'alerts_recipients')}
              />
        )}
          >
            <NanoTable
              {...NanoTableProps}
            />
          </AccessRoleComponent>

          )
        : (
          <NanoTable
            {...NanoTableProps}
            columns={columns(users, handleUpdateOrDelete).filter((col) => col.header !== 'alerts_recipients')}
          />
          )}

      {formIsOpen &&
        (
          <AlertModal
            isOpen={formIsOpen}
            onClose={() => { setFormIsOpen(false); setDefaultValues({}) }}
            onSuccess={handleAlertDidCreate}
            row={defaultValues}
            users={users}
          />
        )}
    </div>
  )
}

Alerts.propTypes = propTypes
Alerts.defaultProps = defaultProps

export default Alerts
