import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import NanoDialog from '../../../shared/components/NanoDialog'
import SuccessPage from '../../../shared/components/SuccessPage'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonTitle: PropTypes.string
}

const defaultProps = {
  isOpen: false,
  title: 'confirmation_form_title',
  description: 'confirmaton_form_text',
  buttonTitle: 'menu_dashboard'
}

function ConfirmationModal ({ isOpen, onClose, title, description, buttonTitle }) {
  const { t } = useTranslation()

  const handleClose = () => {
    onClose()
  }
  return (
    <NanoDialog
      open={isOpen}
      onClose={handleClose}
    >
      <SuccessPage
        title={t(title)}
        description={t(description)}
        buttonTitle={t('go_back', { screen: t(buttonTitle).toLowerCase() })}
        onClose={handleClose}
      />
    </NanoDialog>
  )
}

ConfirmationModal.propTypes = propTypes
ConfirmationModal.defaultProps = defaultProps
// #endregion

export default ConfirmationModal
