import React from 'react'
import PropTypes from 'prop-types'
import { Marker, Tooltip } from 'react-leaflet'
// import L from 'leaflet'
import { FarmPinIcon } from './FarmPinIcon'
// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// #region constants
// const normalSize = new L.Point(20, 20);
// const deviceSize = new L.Point(40, 40);

// allow to adapt based on conditional situation, prevent duplication of svg  https://leafletjs.com/reference.html#divicon
// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  position: PropTypes.shape([]),
  poiName: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

const defaultProps = { onClick: null }

/**
 *
 */
// @TODO: handle device case not present in demo
// eslint-disable-next-line no-unused-vars
function FarmPin ({ position, poiName, onClick }) {
  const iconColor = FarmPinIcon('#0F0B37')
  return (
    <Marker
      position={position}
      icon={iconColor}
      eventHandlers={{ click: () => { onClick(position) } }}
    >
      <Tooltip>
        {poiName}
      </Tooltip>
    </Marker>
  )
}

FarmPin.propTypes = propTypes
FarmPin.defaultProps = defaultProps
// #endregion

export default FarmPin
