import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import MoreAction from '../../shared/components/MoreAction'
import { client } from '../../shared/apiClient'
import AccessRoleComponent from '../../shared/components/AccessRoleComponent'

// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  idUser: PropTypes.string.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  handleEdit: PropTypes.func
}

const defaultProps = { handleEdit: null }

/**
 *
 */
function MoreActionUsers ({ idUser, fetchUsers, handleEdit }) {
  const { t } = useTranslation()

  const deleteAction = () =>
    client.DELETE('/v1/users/{id}', {
      params: { path: { id: idUser } }
    })
      .then(() => fetchUsers())

  return (
    <AccessRoleComponent
      resource='User'
      operation={['CREATE', 'UPDATE']}
      fallbackComponent={(
        <MoreAction
          deleteItemFunction={deleteAction}
          deleteItemAlertTitle={t('user_confirm_deletion_title')}
        />
          )}
    >
      <MoreAction
        deleteItemFunction={deleteAction}
        deleteItemAlertTitle={t('user_confirm_deletion_title')}
        onClickEdit={() => handleEdit()}
      />
    </AccessRoleComponent>

  )
}

MoreActionUsers.propTypes = propTypes
MoreActionUsers.defaultProps = defaultProps
// #endregion

export default MoreActionUsers
