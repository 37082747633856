import React from 'react'
import PropTypes from 'prop-types'
import { Alert, AlertTitle, Box, Button, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { CallIcon } from '../../../shared/icons'
import { useNavigate } from 'react-router-dom'
import { getOperationSuggestedExplanation, getOperationSuggestedIcon, getOperationSuggestedTitle } from '../../../shared/components/OperationSuggestedInfo'

const getTitle = (t, metadata, status) => {
  if (status === 'pending') {
    if (metadata?.info_to_calibrate === 'missing_delivery') return t('device_status_pending_missing_delivery_title')
    if (metadata?.info_to_calibrate === 'missing_level') return t('device_status_pending_missing_level_title')
    if (metadata?.info_to_calibrate === 'missing_level_delivery') return t('device_status_pending_missing_level_delivery_title')
  }
  if (status === 'problem') {
    return getOperationSuggestedTitle(t, metadata?.operation_suggested)
  }
  return null
}

const getExplanation = (t, metadata, status) => {
  if (status === 'pending') {
    if (metadata?.info_to_calibrate === 'missing_delivery') return <Trans i18nKey='device_status_pending_missing_delivery_explanation' components={{ u: <u /> }} />
    if (metadata?.info_to_calibrate === 'missing_level') return <Trans i18nKey='device_status_pending_missing_level_explanation' components={{ u: <u /> }} />
    if (metadata?.info_to_calibrate === 'missing_level_delivery') return <Trans i18nKey='device_status_pending_missing_level_delivery_explanation' components={{ u: <u /> }} />
  }
  if (status === 'problem') {
    return getOperationSuggestedExplanation(t, metadata?.operation_suggested)
  }
  return null
}

const propTypes = {
  data: PropTypes.shape({}),
  loading: PropTypes.bool
}

const defaultProps = { data: {}, loading: true }

function Explanations ({ data, loading }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const customColor = theme.palette[data.status] ?? theme.palette.info
  const bgcolor = customColor.light
  const titleColor = customColor.main
  const title = getTitle(t, data.metadata, data.status)
  const Icon = getOperationSuggestedIcon(data.metadata?.operation_suggested)

  if (!loading) {
    return title
      ? (
        <Alert
          action={data.status === 'problem' &&
            <Box alignSelf='center'>
              <Button variant='contained' color='error' startIcon={<CallIcon color={theme.palette.problem.contrastText} />} onClick={() => navigate('/user-help')}>
                {t('button_support')}
              </Button>
            </Box>}
          sx={{
            bgcolor,
            // struggled to prevent button alert from shrinking. solution from https://github.com/mui/material-ui/issues/39392#issuecomment-1759285935
            button: { whiteSpace: 'nowrap', textAlign: 'center' }
          }}
          icon={<Icon color={titleColor} height={24} width={24} />}
        >
          <AlertTitle sx={{ color: titleColor, fontWeight: 600 }}>{title}</AlertTitle>
          <Typography variant='body1' color='text.secondary'>
            {getExplanation(t, data.metadata, data.status)}
          </Typography>
        </Alert>
        )
      : null
  }
}

Explanations.propTypes = propTypes
Explanations.defaultProps = defaultProps

export { getExplanation, getTitle }
export default Explanations
