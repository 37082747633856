import React, { useState, useContext } from 'react'
// import styled from 'styled-components';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import { Alert, Stack, TextField, InputAdornment, useTheme, Button, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import { useForm, Controller } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import { ToastContext } from '../../shared/contexts'
import { useSessionStore } from '../../shared/store'
import TopPage from '../layout/TopPage'
import { patchRequest, deleteRequest } from '../../shared/apiClient'
import { ReactComponent as ChangePasswordIcon } from '../../shared/icons/ChangePasswordIcon.svg'
import DialogConfirmAction from '../../shared/components/DialogConfirmAction'
import { sendEvent } from '../../shared/utils/analyticsUtils'
// import getTimeZones from '../../shared/utils/timezonesUtils'
// import NanoSelectSingle from '../../shared/components/NanoSelectSingle'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
function EditProfile () {
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation()
  const user = useSessionStore((state) => state.user)
  const { handleSubmit, control, watch } = useForm()
  // const zonesOptions = getTimeZones()
  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(null)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const toastContext = useContext(ToastContext)
  const isUserUpdated = watch()
  const onSubmit = async (payload) => {
    setDisplayError(null)
    setIsLoading(true)
    patchRequest(`v1/users/${user.idUser}/`, payload).then(() => {
      sendEvent('account_profile_updated')
      toastContext.sendMessage(t('user_snackbar_settings_success'))
      return (useSessionStore.getState().refreshMe())
    })
      .catch((err) => {
        setDisplayError(err.message)
      })
      .finally(() => { setIsLoading(false) })
  }

  const handleDelete = () => {
    deleteRequest(`v1/users/${user.idUser}/`).then(() => {
      sendEvent('account_deleted')
      useSessionStore.getState().logout()
    }).catch((err) => {
      setDisplayError(err.message)
      setIsLoading(false)
    })
  }
  return (
    <>
      <TopPage
        back
        justifyContent='center'
        title={mobileFormat ? null : t('edit_profile_menu')}
      />
      <div style={{ margin: 'auto', width: mobileFormat ? '90%' : '33%', padding: '10px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box mt={4} padding='2px' component='form' onSubmit={handleSubmit(onSubmit)}>

          <Stack spacing={3}>
            <Controller
              defaultValue={user.first_name}
              control={control}
              name='first_name'
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('user_form_firstname')}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />

            <Controller
              defaultValue={user.last_name}
              control={control}
              name='last_name'
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('name')}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              defaultValue={user.email}
              control={control}
              name='email'
              rules={{ required: t('name') }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={t('email')}
                  disabled
                  error={!!error}
                  helperText={error?.message}
                  type='email'
                  InputProps={{
                    endAdornment: <InputAdornment position='end'><ChangePasswordIcon stroke={theme.palette.text.secondary} /></InputAdornment>
                  }}
                />
              )}
            />
            {/* <Controller
              defaultValue={user.preferred_timezone}
              control={control}
              name='preferred_timezone'
              render={({ field, fieldState: { error } }) => (
                <NanoSelectSingle
                  label={t('user_form_timezone')}
                  variant='outlined'
                  options={zonesOptions}
                  error={!!error}
                  {...field}
                />
              )}
            /> */}
            {!!displayError && <Alert severity='error'>{displayError}</Alert>}
            <LoadingButton
              loading={isLoading}
              type='submit'
              loadingPosition='start'
              fullWidth
              disabled={(Object.keys(isUserUpdated).length === 0)}
            >
              {t('save_confirm_button')}
            </LoadingButton>
          </Stack>
        </Box>
        <Button
          variant='text'
          sx={{ color: 'priorityHigh.contrastText' }}
          onClick={() => {
            setConfirmDelete(true)
          }}
        >
          {t('delete_account')}
        </Button>
        <DialogConfirmAction
          title={t('dialog_confirm_button_delete_confirm')}
          open={confirmDelete}
          description={t('confirm_removing_text')}
          onClickCancel={() => { setConfirmDelete(false) }}
          onClickConfirm={handleDelete}
        />
      </div>
    </>
  )
}

EditProfile.propTypes = propTypes
EditProfile.defaultProps = defaultProps
// #endregion

export default EditProfile
