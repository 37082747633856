import i18n from '../../config/i18n'

const urlsForWorkspace =
[
  'https://nanolike-public.s3.eu-west-1.amazonaws.com/terms_and_conditions/lantmannen/en/terms_and_conditions.html'
]

// @TODO : add lang logic
const getUrl = (workspace, t) => {
  if (!workspace) return (t('terms_and_con_link'))
  let url = null
  const urls = urlsForWorkspace.filter((url) => url.includes(workspace))
  if (urls.length > 0) {
    // check with the local

    url = urls.find((url) => url.includes(`${i18n.resolvedLanguage}/terms_and_conditions.html`))
    // fallback english
    if (!url) {
      url = urls.find((url) => url.includes('en/terms_and_conditions.html'))
    }
  } else {
    url = t('terms_and_con_link') // fallback general conditions
  }

  return url
}

export default getUrl
