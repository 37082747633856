import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Badge, Box, IconButton, Link, Popover } from '@mui/material'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { ReactComponent as ArrowLeftIcon } from '../../shared/icons/ArrowLeftIconBig.svg'
import { ReactComponent as NotificationIcon } from '../../shared/icons/NotificationIcon.svg'
import UserMenu from './components/UserMenu'
import NanoAvatar from '../../shared/components/NanoAvatar'
import { useSessionStore } from '../../shared/store'
import NotificationsList from '../notifications/components/NotificationsList'
// #region constants

// #endregion

// #region styled-components
// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  title: PropTypes.string,
  renderTitle: PropTypes.element,
  actions: PropTypes.element,
  justifyContent: PropTypes.string,
  back: PropTypes.bool
}

const defaultProps = { back: false, renderTitle: null, actions: null, justifyContent: 'space-between', title: null }

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */

function TopPage ({ title, renderTitle, actions, justifyContent, back }) {
  const { t } = useTranslation()
  const ref = useRef(null)
  const navigate = useNavigate()
  const [open, setOpen] = useState(true)
  const { fetchUnread, unreadNotificationsCount } = useSessionStore((state) => state)
  const handleDrawerOpenClose = () => (open ? setOpen(false) : setOpen(true))
  const [notificationsAnchor, setNotificationsAnchor] = useState(null)

  useEffect(() => {
    fetchUnread()
  }, [])
  return (
    <Stack>
      <Stack pt={2} pb={2} direction='row' alignItems='center' alignContent='center' justifyContent={back ? 'space-between' : 'flex-end'}>

        {back && (
          <Stack direction='row' justifyContent='flex-start'>
            <IconButton
              sx={{ paddingLeft: 0 }} onClick={() => {
                if (window.history.length) navigate(-1)
                else { navigate('/devices') }
              }}
            >
              <NanoAvatar sx={{ width: 32, height: 32, backgroundColor: 'grey.main' }}>
                <ArrowLeftIcon />
              </NanoAvatar>
            </IconButton>
          </Stack>
        )}

        <Stack spacing={1} direction='row' alignItems='center' alignContent='flex-start' justifyContent='flex-end'>
          <IconButton
            onClick={(event) => {
              setNotificationsAnchor(event.currentTarget)
            }}
          >
            <Badge
              badgeContent={unreadNotificationsCount.all}
            // @ts-ignore
              color='priorityHighRevert'
            >
              <NotificationIcon color='black' />
            </Badge>
          </IconButton>
          <Popover
            sx={{ height: 500 }}
            open={!!notificationsAnchor}
            anchorEl={notificationsAnchor}
            onClose={() => setNotificationsAnchor(null)}
            slotProps={{ paper: { ref } }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Box sx={{ px: 3, width: 500 }}>
              <NotificationsList popoverElement={ref} />
            </Box>
            <Box sx={{ p: 2, position: 'sticky', bottom: 0, zIndex: 99, backgroundColor: 'white' }}>
              <Link component={RouterLink} underline='hover' color='inherit' to={{ pathname: '/notifications' }}>
                <Typography textAlign='center' variant='h5' color='secondary'>{t('seeAllNotifications')}</Typography>
              </Link>
            </Box>
          </Popover>
          <IconButton
            sx={{ p: '0px' }}
            onClick={handleDrawerOpenClose}
          >
            <UserMenu
              drawerOpen={open}
            />
          </IconButton>
        </Stack>
      </Stack>
      <Stack sx={{ pb: 2 }} direction='row' alignItems='center' alignContent='flex-start' justifyContent={justifyContent}>
        {renderTitle || <Typography variant='h1' color='black'>{t(title)}</Typography>}
        {actions}
      </Stack>

    </Stack>
  )
}

TopPage.propTypes = propTypes
TopPage.defaultProps = defaultProps
// #endregion

export default TopPage
