import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Typography, Popover, useTheme, useMediaQuery, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BatteryProblemIcon, IceTrappedIcon, PlugSocketIcon, WifiOffIcon, WrenchIcon, InfoIcon } from '../icons/index'
import { OPERATION_SUGGESTED } from '../models/operationSuggested'

const OPERATION_SUGGESTED_ICON = Object.freeze({
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: PlugSocketIcon,
  [OPERATION_SUGGESTED.MOVE]: WrenchIcon,
  [OPERATION_SUGGESTED.NEW_BATTERY]: BatteryProblemIcon,
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: WrenchIcon,
  [OPERATION_SUGGESTED.NEW_ELEC]: WrenchIcon,
  [OPERATION_SUGGESTED.NEW_SENSOR]: WrenchIcon,
  [OPERATION_SUGGESTED.OUT_OF_DATA]: WifiOffIcon,
  [OPERATION_SUGGESTED.SHIM]: WrenchIcon,
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: IceTrappedIcon
})

const OPERATION_SUGGESTED_SHORT = Object.freeze({
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: 'device_status_problem_connect_sensor_short',
  [OPERATION_SUGGESTED.MOVE]: 'device_status_problem_move_short',
  [OPERATION_SUGGESTED.NEW_BATTERY]: 'device_status_problem_new_battery_short',
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: 'device_status_problem_new_device_full_short',
  [OPERATION_SUGGESTED.NEW_ELEC]: 'device_status_problem_new_elec_short',
  [OPERATION_SUGGESTED.NEW_SENSOR]: 'device_status_problem_new_sensor_short',
  [OPERATION_SUGGESTED.OUT_OF_DATA]: 'device_status_problem_out_of_data_short',
  [OPERATION_SUGGESTED.SHIM]: 'device_status_problem_shim_short',
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: 'device_status_problem_trapped_in_ice_short'
})

const OPERATION_SUGGESTED_TITLE = Object.freeze({
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: 'device_status_problem_connect_sensor_title',
  [OPERATION_SUGGESTED.MOVE]: 'device_status_problem_move_title',
  [OPERATION_SUGGESTED.NEW_BATTERY]: 'device_status_problem_new_battery_title',
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: 'device_status_problem_new_device_full_title',
  [OPERATION_SUGGESTED.NEW_ELEC]: 'device_status_problem_new_elec_title',
  [OPERATION_SUGGESTED.NEW_SENSOR]: 'device_status_problem_new_sensor_title',
  [OPERATION_SUGGESTED.OUT_OF_DATA]: 'device_status_problem_out_of_data_title',
  [OPERATION_SUGGESTED.SHIM]: 'device_status_problem_shim_title',
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: 'device_status_problem_trapped_in_ice_title'
})

const OPERATION_SUGGESTED_EXPLANATION = Object.freeze({
  [OPERATION_SUGGESTED.CONNECT_SENSOR]: 'device_status_problem_connect_sensor_explanation',
  [OPERATION_SUGGESTED.MOVE]: 'device_status_problem_move_explanation',
  [OPERATION_SUGGESTED.NEW_BATTERY]: 'device_status_problem_new_battery_explanation',
  [OPERATION_SUGGESTED.NEW_DEVICE_FULL]: 'device_status_problem_new_device_full_explanation',
  [OPERATION_SUGGESTED.NEW_ELEC]: 'device_status_problem_new_elec_explanation',
  [OPERATION_SUGGESTED.NEW_SENSOR]: 'device_status_problem_new_sensor_explanation',
  [OPERATION_SUGGESTED.OUT_OF_DATA]: 'device_status_problem_out_of_data_explanation',
  [OPERATION_SUGGESTED.SHIM]: 'device_status_problem_shim_explanation',
  [OPERATION_SUGGESTED.TRAPPED_IN_ICE]: 'device_status_problem_trapped_in_ice_explanation'
})

const getOperationSuggestedTitle = (t, operationSuggested) => {
  // The day we add links to docs, we will use something like this
  // return (
  //   <Trans
  //     i18nKey='test_tags'
  //     values={{ href: 'value' }}
  //     components={{ link: <a href='https://doclink...' rel='noreferrer' /> }}
  //   />
  // )
  return t(OPERATION_SUGGESTED_TITLE[operationSuggested] ?? 'device_status_problem_unknown_title')
}

const getOperationSuggestedExplanation = (t, operationSuggested) => {
  return t(OPERATION_SUGGESTED_EXPLANATION[operationSuggested] ?? 'device_status_problem_unknown_explanation', { product: 'BinConnect' })
}

const getOperationSuggestedIcon = (operationSuggested) => {
  return OPERATION_SUGGESTED_ICON[operationSuggested] ?? InfoIcon
}

const propTypes = {
  operationSuggested: PropTypes.string
}

function OperationSuggestedInfo ({ operationSuggested }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  if (!operationSuggested) return null

  const iconStyles = {
    color: theme.palette.error.main, height: 24, width: 24
  }
  const Icon = getOperationSuggestedIcon(operationSuggested)
  const info = OPERATION_SUGGESTED_SHORT[operationSuggested]
  const explanation = getOperationSuggestedExplanation(t, operationSuggested)
  const title = getOperationSuggestedTitle(t, operationSuggested)

  // The case may not be handled yet, like NEW_TAPPING
  if (!info || !Icon) return null

  return (
    <Stack
      spacing={0.5} direction='row' justifyContent='flex-end' alignItems='center'
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup='true'
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Icon {...iconStyles} />
      {!mobileFormat && (
        <Typography variant='h5' color='error.main' style={{ wordBreak: 'break-word', marginLeft: 8 }}>{t(info)}</Typography>
      )}
      {!mobileFormat && (
        <Popover
          id='mouse-over-popover'
          sx={{
            pointerEvents: 'none'
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box p={3} maxWidth={400}>
            <Typography variant='h4' color='text.primary' pb={2}>
              {title}
            </Typography>
            <Typography variant='body1' color='text.secondary'>
              {explanation}
            </Typography>
          </Box>
        </Popover>
      )}
    </Stack>
  )
}

OperationSuggestedInfo.propTypes = propTypes

export { getOperationSuggestedExplanation, getOperationSuggestedTitle, getOperationSuggestedIcon }
export default OperationSuggestedInfo
