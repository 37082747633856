import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { Box } from '@mui/material'
import SelectDevicesAndGroups from '../../../shared/components/selectDevicesOrGroups/SelectDevicesAndGroups'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  order: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired
}

const defaultProps = { }

/**
 *
 */
function OrderFormStepWhere ({ order, control }) {
  const { t } = useTranslation()

  return (
    <Box sx={{ width: 500 }}>

      <Controller
        control={control}
        defaultValue={order.devicesSelected?.length
          ? order.devicesSelected?.map((d) => (
            {
              label: d.device_name,
              id: d.device_reference,
              section: 'bin',
              ...d
            })
          )
          : []}
        name='where_devices_groups'
        rules={{ required: t('form_field_required_error_message') }}
        render={({ field, fieldState: { error } }) => (
          <SelectDevicesAndGroups
            label={t('order_form_step_where_select_label')}
            onChange={(_, devicesAndGroups) => {
              field.onChange(devicesAndGroups)
            }}
            value={field.value}
            error={error}
            required
          />
        )}
      />

    </Box>
  )
}

OrderFormStepWhere.propTypes = propTypes
OrderFormStepWhere.defaultProps = defaultProps
// #endregion

export default OrderFormStepWhere
