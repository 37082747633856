import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import { Box, Button, Stack } from '@mui/material'
import { ReactComponent as CloseIcon } from '../icons/CloseIcon.svg'
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDownIcon.svg'
import NanoSlider from './NanoSlider'
import remainingDaysFilter from '../../shared/models/reaminingDaysFilter'

// #region constants

// #endregion

// #region styled-components
const InlineBadge = styled(Badge)(({ theme }) => ({
  paddingRight: theme.spacing(4),
  '& .MuiBadge-badge': {
    right: 12,
    top: 10
  }
}))
// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectProps: PropTypes.shape({}),
  withSelect: PropTypes.bool
}

const defaultProps = { selectProps: {}, withSelect: true }

/**
 *
 */

function SelectRemainingDays ({ value, handleSelect, placeholder, selectProps, withSelect }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(undefined)
  const options = remainingDaysFilter(t)
  useEffect(() => {
    setSelectedValue(value[value.length - 1])
  }, [value])

  const handleValidate = () => {
    setOpen(false)
    handleSelect(Array.from({ length: selectedValue + 1 }, (_, i) => i))
  }

  const clearAll = () => { setSelectedValue(undefined); handleSelect([]) }

  const handleSliderChange = (event, v) => {
    setSelectedValue(v)
  }

  const handleSliderChangeNoSelect = (event, v) => {
    setSelectedValue(v)
    handleSelect(Array.from({ length: v + 1 }, (_, i) => i))
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (withSelect) {
    return (
      <Select
        sx={{ bgcolor: 'white', height: 40 }}
        open={open}
        displayEmpty
        IconComponent={ArrowDownIcon}
        MenuProps={{
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom'
          },
          transformOrigin: {
            horizontal: 'left',
            vertical: -24
          }
        }}
        onOpen={handleOpen}
        onClose={handleClose}
        renderValue={() => (
          selectedValue != null
            ? (
              <InlineBadge color='secondary' badgeContent={1}>
                <Typography variant='h5'>{t(placeholder)}</Typography>
              </InlineBadge>
              )
            : <Typography variant='h5'>{t(placeholder)}</Typography>
        )}
        {...selectProps}
      >
        <Box sx={{ pl: 1, pr: 1, minWidth: 300 }}>
          <NanoSlider
            marks={options}
            step={1}
            min={0}
            max={5}
            defaultValue={2.5}
            value={selectedValue}
            onChange={handleSliderChange}
            onClick={(event) => event.stopPropagation()}
          />
          <Stack direction='row' pt={2} justifyContent='center' sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
            <Typography variant='body1'>
              {selectedValue === null || selectedValue === undefined
                ? t('remaining_day_info_empty')
                : (
                  <>
                    {t('remaining_day_info')}{'   '}
                    <b>{t('data_type_unit', { value: { value: selectedValue, unit: 'day' } })}</b>
                  </>

                  )}
            </Typography>
          </Stack>

          <Stack direction='row' pt={2} justifyContent='space-between'>
            <Button color='grey' onClick={clearAll} endIcon={<CloseIcon />}>
              {t('clear')}
            </Button>
            <Button onClick={handleValidate}>
              {t('validate')}
            </Button>
          </Stack>
        </Box>
      </Select>

    )
  } else {
    return (
      <Box sx={{ p: 1, minWidth: 300 }}>
        {(selectedValue !== null && selectedValue !== undefined) &&
          <Stack justifyContent='flex-end' alignItems='center' direction='row' sx={{ position: 'sticky', top: 0, zIndex: 99, backgroundColor: 'transparent' }}>
            <Button variant='text' sx={{ p: 0 }} onClick={clearAll}>{t('clear')}</Button>
          </Stack>}
        <NanoSlider
          marks={options}
          step={1}
          min={0}
          max={5}
          value={selectedValue ?? 2.5}
          onChange={handleSliderChangeNoSelect}
          onClick={handleValidate}
        />
      </Box>
    )
  }
}

SelectRemainingDays.propTypes = propTypes
SelectRemainingDays.defaultProps = defaultProps
// #endregion

export default SelectRemainingDays
