import { dateMediumWithoutTimeUTC } from '../../../shared/utils/dateUtils'

const orderDatesToString = ({ order }) => {
  if (order?.date) {
    const dateString = dateMediumWithoutTimeUTC(order?.date)
    const endDate = new Date(order?.date_range_end)
    const startDate = new Date(order.date)

    if (order?.date_range_end && (endDate.getTime() !== startDate.getTime())) {
      const dateEndString = ` - ${dateMediumWithoutTimeUTC(order?.date_range_end)}`
      return `${dateString}${dateEndString}`
    }

    return dateString
  }

  return ''
}

function removeDuplicates (inputArray) {
  const uniqueKeys = new Set()
  return inputArray.filter((item) => {
    const key = JSON.stringify(item)
    if (!uniqueKeys.has(key)) {
      uniqueKeys.add(key)
      return true
    }
    return false
  })
}

export {
  orderDatesToString,
  removeDuplicates
}
