import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { langs } from '../shared/utils/langUtils'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    detection: {
      convertDetectedLanguage: (lng) => {
        if (!langs.includes(lng)) {
          if (lng.includes('-')) { // if regional try pure ex fr-GF for french guiana
            const pureLng = lng.split('-')[0]
            lng = langs.includes(pureLng) ? pureLng : 'en'
          } else lng = 'en' // fallback
        }
        return lng
      }
    },
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
