import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Autocomplete, Box, CircularProgress, Divider, Grid, IconButton, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material'
import { debounce } from '@mui/material/utils'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PoiIcon } from '../icons/PoiIconBold.svg'
import { ReactComponent as HashtagIcon } from '../icons/HashtagIcon.svg'
import { ReactComponent as Arrow } from '../icons/ArrowRightIconBold.svg'
import { ReactComponent as SearchIcon } from '../icons/SearchIcon.svg'
import NanoMenuItem from './NanoMenuItem'
import { ContentIcon } from '../icons/index'
import { sendEvent } from '../utils/analyticsUtils'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  fetchOptions: PropTypes.func, // intial state, fetch all options
  value: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
    section: PropTypes.string
  }))
}

const defaultProps = {
  value: null,
  fetchOptions: null
}

/**
 * @TODO:Rework
 * For now this component is only used in Dashbaord.
 * If it is its destiny it will need a bit of rework for simplifciation.
 */
function SearchDevice ({ fetchOptions, value }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('')
  const fetchData = (v) => {
    setLoading(true)
    fetchOptions(v).then((response) => {
      if (v) {
        sendEvent('search_device', { query: v })
      }
      setOptions(response)
    }).finally(() => setLoading(false))
  }
  useEffect(() => {
    if (typeof fetchOptions === 'function') getOptionsDelayed(searchQuery)
  }, [searchQuery])

  const getOptionsDelayed = useCallback(
    debounce(fetchData, 500),
    []
  )

  const customSurface = (props) => (
    <Box
      {...props}
    />
  )

  return (

    <Autocomplete
      sx={{
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        }
      }}
      PopperComponent={customSurface}
      PaperComponent={customSurface}
      open
      popupIcon={null}
      isOptionEqualToValue={(option, v) => option.id === v.id}
      options={options}
      loading={loading}
      inputValue={searchQuery}
      value={value}
      onInputChange={(_, v) => setSearchQuery(v)}
      renderInput={(params) => (
        <>
          <TextField
            autoFocus
            fullWidth
            {...params}
            placeholder={t('search_bin_ph')}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),

              endAdornment: (
                <>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              style: {
                backgroundColor: 'white'
              }
            }}
          />
          <Divider />
        </>
      )}
        // Render options,render group and props linked
      getOptionLabel={(option) => `${option.label} ${option.others.device_reference}`}
      renderOption={(props, option) => (
        <NanoMenuItem
          {...props}
          onClick={() => navigate(`/devices/${option.others.device_reference}`)}
        >
          <Grid ml={2} container spacing={0} direction='column'>
            <Grid item container spacing={0} alignItems='center'>
              <Grid item>
                <Typography noWrap width={250} variant='h5'>
                  {option.label}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <PoiIcon />
              </Grid>
              <Grid item xs={8}>
                <Typography noWrap width={250} variant='body2'>
                  {option.others?.farm_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <ContentIcon />
              </Grid>
              <Grid item xs={8}>
                <Typography noWrap width={250} variant='body2'>
                  {option?.others?.deviceContent?.device_content ?? t('not_applicable')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item>
                <HashtagIcon />
              </Grid>
              <Grid item xs={8}>
                <Typography noWrap variant='body2'>
                  {option?.others?.device_reference}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Stack mr={2} direction='row' alignItems='center' alignContent='flex-start'>
            {/* @FIXME: Add Icon should really add the value */}
            <IconButton>
              <Arrow fill={theme.palette.secondary.main} stroke={theme.palette.secondary.main} />
            </IconButton>
          </Stack>
        </NanoMenuItem>
      )}
    />
  )
}

SearchDevice.propTypes = propTypes
SearchDevice.defaultProps = defaultProps
// #endregion

export default SearchDevice
