import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import NanoDialog from '../../shared/components/NanoDialog'
import AlertForm from './AlertForm'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  row: PropTypes.shape({
    alert_id: PropTypes.string,
    alert_name: PropTypes.string,
    devices_to_check: PropTypes.shape([]),
    device_groups_to_check: PropTypes.shape([]),
    data_type: PropTypes.string,
    min_value: PropTypes.number,
    max_value: PropTypes.number,
    recipient_groups_for_notifications_ids: PropTypes.shape([]),
    recipients_for_notifications_ids: PropTypes.shape([])
  }),
  users: PropTypes.arrayOf(PropTypes.shape({}))
}

const defaultProps = {
  isOpen: false,
  row: {},
  users: []
}

/**
 *
 */
function AlertModal ({ isOpen, onClose, onSuccess, row, users }) {
  const { t } = useTranslation()
  const handleSuccess = () => {
    onClose()
    onSuccess(row?.alert_id)
  }
  return !isOpen
    ? null
    : (
      <NanoDialog
        open={isOpen}
        onClose={onClose}
        title={row?.alert_id ? t('alert_form_title_update') : t('alert_form_title')}
      >
        <AlertForm row={row} users={users} onSuccess={handleSuccess} />
      </NanoDialog>
      )
}

AlertModal.propTypes = propTypes
AlertModal.defaultProps = defaultProps
// #endregion

export default AlertModal
