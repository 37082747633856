import React, { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { OkIcon } from '../../../shared/icons/index'
import { client } from '../../../shared/apiClient'
import { useSessionStore } from '../../../shared/store'
import { sendEvent } from '../../../shared/utils/analyticsUtils'
import { LoadingButton } from '@mui/lab'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = { isValidated: PropTypes.bool.isRequired, order: PropTypes.shape({}), onUpdateValidated: PropTypes.func }

const defaultProps = { order: null, onUpdateValidated: null }

/**
 *
 */
const ValidateCommandButton = ({ isValidated, order, onUpdateValidated }) => {
  const { t } = useTranslation()
  const me = useSessionStore((state) => state.user)
  const [validated, setValidated] = useState(isValidated)
  const [loading, setLoading] = useState(false)
  const handleUpdate = () => {
    setLoading(true)
    client.PATCH('/v1/orders/{id}', {
      params: { path: { id: order.id } },
      body: { ...order, status: 'validated' }
    }).then(() => {
      setValidated(true)
      sendEvent('order_validated')
      setLoading(false)
      if (onUpdateValidated) onUpdateValidated(true)
    })
  }

  return (!validated && !isValidated)
    ? (
        me.workspace_role.hierarchy < 2
          ? (
            <LoadingButton sx={{ height: '40px' }} loading={loading} onClick={handleUpdate}>{t('validate')}</LoadingButton>
            )
          : (
            <Stack spacing={0.5} direction='row' alignItems='center'>
              <Typography variant='h5' display={{ lg: 'block', md: 'none' }} color='problem.main'>
                {t('order_not_validated_order')}
              </Typography>
            </Stack>
            )
      )

    : (
      <Stack spacing={0.5} direction='row' alignItems='center'>
        <OkIcon />
        <Typography variant='h5' display={{ lg: 'block', md: 'none' }} color='ok.contrastText'>
          {t('order_validated_order')}
        </Typography>
      </Stack>)
}

ValidateCommandButton.propTypes = propTypes
ValidateCommandButton.defaultProps = defaultProps
// #endregion

export default ValidateCommandButton
