import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import NanoDialog from '../../shared/components/NanoDialog'
import SelectEmailUser from '../../shared/components/SelectEmailUser'
import { useSessionStore } from '../../shared/store'
import { ToastContext } from '../../shared/contexts'
import { postRequest } from '../../shared/apiClient'
import UserAvatarAndName from '../../shared/components/UserAvatarAndName'

// import { useSessionStore } from '../../shared/store';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  userGroup: PropTypes.arrayOf(PropTypes.shape({})),
  filters: PropTypes.shape({})
}

const defaultProps = {
  isOpen: false,
  userGroup: [],
  onSuccess: null,
  filters: {}
}

/**
 *
 */
function InviteUserModal ({ isOpen, onClose, onSuccess, userGroup, filters }) {
  const { t } = useTranslation()
  const toastContext = useContext(ToastContext)
  const { user } = useSessionStore((state) => state)
  const [isDisabled, setIsDisabled] = useState(true)
  const [email, setEmail] = useState([])
  const handleClose = () => {
    setIsDisabled(true)
    onClose()
  }
  const onSubmit = () => {
    Promise.all(email.map((g) => postRequest(`v1/users/${g.id}/group-memberships`, {
      group_id: filters.viewSelected.id,
      group_role_id: 'Admin' // default user
    })))
      .then(() => {
        // responses.map((r) => groupCellContext.groups.push(r.data[0]));
        toastContext.sendMessage(t('access_added'))
      })
      .finally(() => {
        handleClose()
        setEmail([])
        onSuccess()
      })

    // toastContext.sendMessage(t('access_added'));
  }
  return (
    <NanoDialog
      open={isOpen}
      onClose={handleClose}
      title={t('custom_invit_user_title')}
    >
      {userGroup.length > 0 && (
        <>
          <Stack sx={{ pt: 3 }} flexDirection='row'>
            <UserAvatarAndName withEmail {...user} />
            {t(`role_${user.workspace_role.workspace_role_name}`)}
          </Stack>
          {userGroup
            .slice(user.userId)
            .map((user) => (
              <Stack sx={{ pt: 3 }} flexDirection='row' key={user.id}>
                <UserAvatarAndName withEmail {...user} />
                {t(`role_${user.workspace_role.workspace_role_name}`)}
              </Stack>
            ))}
        </>
      )}

      <Stack sx={{ pt: 7 }} flexDirection='row' alignItems='flex-end' justifyContent='space-between'>

        <Stack flexGrow='1'>
          <SelectEmailUser
            onChange={(_, data) => {
              setEmail(data)
              setIsDisabled(data.length === 0)
            }}
            value={email}
            required
            filterUsers={user => {
              const isAlreadyInGroup = userGroup.some((u) => u.id === user.id)
              return !isAlreadyInGroup && !user.workspace_role.global
            }}
          />
        </Stack>
        <LoadingButton
          sx={{ ml: 10 }}
          type='submit'
          loadingPosition='start'
          disabled={isDisabled}
          onClick={onSubmit}
        >
          {t('custom_button_add')}
        </LoadingButton>
        {/* </Stack> */}
      </Stack>
    </NanoDialog>
  )
}

InviteUserModal.propTypes = propTypes
InviteUserModal.defaultProps = defaultProps
// #endregion

export default InviteUserModal
