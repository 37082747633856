import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Snackbar, Typography } from '@mui/material'

// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  data: PropTypes.shape({
    message: PropTypes.string,
    severity: PropTypes.string
  }),
  onClose: PropTypes.func
}

const defaultProps = {
  data: null,
  onClose: null
}

/**
 *
 */
function Toast ({ data, onClose }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    onClose()
  }

  return (
    <Snackbar open={!!data} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={data?.severity ?? 'success'} sx={{ width: '100%', height: 60, alignItems: 'center' }}>
        <Typography fontWeight={600}>
          {data?.message ?? ''}
        </Typography>
      </Alert>
    </Snackbar>
  )
}

Toast.propTypes = propTypes
Toast.defaultProps = defaultProps
// #endregion

export default Toast
