import React from 'react'
import PropTypes from 'prop-types'
import { Button, Stack, Typography } from '@mui/material'
import { ReactComponent as DiscuterImage } from '../icons/DiscuterImage.svg'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  onClose: PropTypes.func.isRequired
}

const defaultProps = {}

function SuccessPage ({ title, description, buttonTitle, onClose }) {
  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      display='flex'
      spacing={4}
    >
      <DiscuterImage />
      <Stack spacing={4}>
        <Typography variant='h1' color='black.main'>
          {title}
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {description}
        </Typography>
      </Stack>

      <Button fullWidth sx={{ height: 56 }} onClick={() => { onClose() }}>
        {buttonTitle}
      </Button>
    </Stack>
  )
}

SuccessPage.propTypes = propTypes
SuccessPage.defaultProps = defaultProps
// #endregion

export default SuccessPage
