import React from 'react'
// import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'
import LevelWithTonsAndMissingWeight from '../../../shared/components/LevelWithTonsAndMissingWeight'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {}

const defaultProps = {}

/**
 *
 */
function LevelTodayCell ({ ...row }) {
  return (
    <TableCell sx={{ pr: 3 }}>
      <LevelWithTonsAndMissingWeight {...row} />
    </TableCell>
  )
}

LevelTodayCell.propTypes = propTypes
LevelTodayCell.defaultProps = defaultProps
// #endregion

export default LevelTodayCell
