import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, Typography, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  last_triggered_timestamp: PropTypes.string
}

const defaultProps = { loading: false, last_triggered_timestamp: '' }

/**
 *
 */
function AlertDateCell ({ loading, last_triggered_timestamp: lastTriggeredTimestamp }) {
  const { t } = useTranslation()

  const specificDate = new Date(lastTriggeredTimestamp)
  const currentDate = new Date()

  // Calcul du nombre de jours
  const timeDifference = specificDate && (currentDate.getTime() - specificDate.getTime())
  const dayDifference = (Math.floor(timeDifference / (1000 * 60 * 60 * 24)))

  return (
    <TableCell align='left'>
      <Typography variant='body1'>
        {loading
          ? (
            <Skeleton width='80%' />
            )
          : (
              lastTriggeredTimestamp ? t('data_type_unit', { value: { value: dayDifference, unit: 'day' } }) : '-'
            )}
      </Typography>
    </TableCell>
  )
}

AlertDateCell.propTypes = propTypes
AlertDateCell.defaultProps = defaultProps
// #endregion

export default AlertDateCell
