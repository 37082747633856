import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import { ReactComponent as CloseIcon } from '../icons/CloseIcon.svg'
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDownIcon.svg'
import NanoDateRange from './NanoDateRange'
import { Stack } from '@mui/material'
import dayjs from 'dayjs'

const propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSelect: PropTypes.func.isRequired,
  dateRangePickerProps: PropTypes.shape({})
}

const defaultProps = {
  dateRangePickerProps: {}
}

/**
 *
 */
function NanoSelectDateRange ({ value, handleSelect, placeholder, dateRangePickerProps }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const isRangeSet = selected.length === 2 && selected.every(Boolean)

  useEffect(() => {
    setSelected(value)
  }, [value])

  const handleValidate = () => {
    setOpen(false)
    if (dayjs(selected[0]).isValid() && dayjs(selected[1]).isValid()) { handleSelect(selected) } else { setSelected([null, dayjs()]) }
  }

  const onReset = () => {
    setSelected([dayjs().subtract(1, 'month'), dayjs()])
    setOpen(false)
    handleSelect([dayjs().subtract(1, 'month'), dayjs()])
  }

  return (
    <Select
      sx={{ bgcolor: 'white', height: 40 }}
      open={open}
      displayEmpty
      IconComponent={ArrowDownIcon}
      MenuProps={{
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom'
        },
        transformOrigin: {
          horizontal: 'left',
          vertical: -24
        }
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderValue={() => (
        <Badge color='secondary' variant='dot' invisible={!isRangeSet}>
          <Typography variant='h5'>{t(placeholder)}</Typography>
        </Badge>
      )}
    >
      <NanoDateRange calendars={2} valueSelected={selected} disableFuture onChange={(newValue) => setSelected(newValue)} {...dateRangePickerProps} />

      <Stack direction='row' pt={2} spacing={3} justifyContent='space-between'>
        <Button color='grey' onClick={onReset} endIcon={<CloseIcon />}>
          {t('reset')}
        </Button>
        <Button onClick={handleValidate}>
          {t('validate')}
        </Button>
      </Stack>
    </Select>

  )
}

NanoSelectDateRange.propTypes = propTypes
NanoSelectDateRange.defaultProps = defaultProps

export default NanoSelectDateRange
