import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Skeleton, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { TruckIcon } from '../../shared/icons'
import { useTranslation } from 'react-i18next'
import { dateMediumWithoutYearAndWithoutTime } from '../../shared/utils/dateUtils'
import i18n from '../../config/i18n'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  loading: PropTypes.bool,
  fillingDate: PropTypes.string
}
const defaultProps = { loading: false, fillingDate: null }

/**
 *
 */
function Delivery ({ loading, fillingDate }) {
  const theme = useTheme()
  const { t } = useTranslation()
  const days = Math.round((Date.now() - new Date(fillingDate).getTime()) / (1000 * 3600 * 24))
  return (
    <Stack display='flex' direction='column' justifyContent='center' alignItems='center' spacing={1}>
      {loading
        ? <Skeleton variant='rounded' width={24} height={24} />
        : <TruckIcon color={theme.palette.text.secondary} width={24} height={24} />}
      <Typography align='center' variant='h6'>
        {loading
          ? <Skeleton />
          : fillingDate ? t('delivery_dashboard', { date: dateMediumWithoutYearAndWithoutTime(fillingDate) }) : '-'}
      </Typography>
      <Typography align='center' noWrap variant='body2' color='text.secondary'>
        {loading ? <Skeleton /> : fillingDate ? new Intl.RelativeTimeFormat(i18n.resolvedLanguage, { numeric: 'auto' }).format(-days, 'day') : '-'}
      </Typography>
    </Stack>

  )
}

Delivery.propTypes = propTypes
Delivery.defaultProps = defaultProps
// #endregion

export default Delivery
