import React from 'react'
import PropTypes from 'prop-types'
import { TimePicker } from '@mui/x-date-pickers-pro'
import { ClockIconBold } from '../icons/index'
import { useTheme } from '@mui/material'

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired
}

const defaultProps = { value: null }

/**
 *
 */
function NanoSelectHour ({ value, onChange, ...props }) {
  const theme = useTheme()

  return (
    <TimePicker
      {...props}
      slots={{
        openPickerIcon: () => <ClockIconBold height={24} width={24} fill={theme.palette.text.secondary} />
      }}
      value={value ?? {}}
      onChange={onChange}
    />
  )
}

NanoSelectHour.propTypes = propTypes
NanoSelectHour.defaultProps = defaultProps
// #endregion

export default NanoSelectHour
