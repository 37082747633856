import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import vi from 'dayjs/locale/vi'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, ReferenceLine, Legend } from 'recharts'
import NanoPaper from '../../../shared/components/NanoPaper'
import { useWorkspaceSettingsStore } from '../../../shared/store'
import { dateMediumWithoutYearTimeAndDay } from '../../../shared/utils/dateUtils'
import { formatTimeTickLabel } from './timeTicks'

const Loader = () => (
  <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }}
  >
    <CircularProgress
      size={50}
      thickness={2}
      sx={{
        margin: 'auto'
      }}
    />
  </Box>
)

/** Use text-shadow to create an outline around some text */
function shadowOutline (width, color) {
  return [
    [-1, -1],
    [-1, 0],
    [-1, 1],
    [0, -1],
    [0, 0],
    [0, 1],
    [1, -1],
    [1, 0],
    [1, 1]
  ].map(([x, y]) => `${x * width}px ${y * width}px 0.1px ${color}`).join(', ')
}

function average (array) {
  return array.reduce((a, b) => a + b, 0) / array.length
}

function roundDecimals (value, rounding) {
  return Math.round(value * 10 ** rounding) / 10 ** rounding
}

const APPROXIMATE_CHAR_WIDTH = 10 // px

export default function DeviceDailyConsumptionChart ({ graphData, isLoading, capaMax }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const workspaceSettings = useWorkspaceSettingsStore()
  const rounding = parseInt(workspaceSettings.getSetting('rounding_precision')?.value) ?? 2

  const dataAvg = average(graphData.map(point => point.value))
  const dataMax = Math.max(...graphData.map(point => point.value))

  const yLabelLength = t('number_workspace_filling_unit', { value: dataMax }).length
  const yAxisWidth = yLabelLength * APPROXIMATE_CHAR_WIDTH

  return (
    <NanoPaper sx={{ height: '300px' }}>
      <ResponsiveContainer width='100%' height='100%'>
        {isLoading
          ? <Loader />
          : (
            <BarChart
              data={graphData}
              margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
            >

              <XAxis
                axisLine={false}
                dataKey='timestamp'
                domain={['dataMin', 'dataMax']}
                name='Time'
                tickLine={false}
                type='number'
                padding='no-gap'
                minTickGap={100}
                scale='time'
                dy={12} // vertical padding between axis and labels
                tickFormatter={formatTimeTickLabel}
              />
              <YAxis
                dataKey='value'
                tickFormatter={(value) => t('number_workspace_filling_unit', { value })}
                axisLine={false}
                width={yAxisWidth}
                tickLine={false}
                type='number'
                domain={[
                  0,
                  dataMax => roundDecimals(
                    dataMax * 1.4, // make the variations look less dramatic
                    rounding
                  )
                ]}
              />

              <Tooltip
                cursor={{ fill: theme.palette.grey.main }}
                formatter={(value) => [t('number_workspace_filling_unit', { value })]}
                labelFormatter={(value) => `${dateMediumWithoutYearTimeAndDay(value)}`}
              />

              <CartesianGrid vertical={false} strokeDasharray='4,6' stroke='rgba(0,0,0,0.2)' />

              <Bar
                fill={theme.palette.graph.main}
                dataKey={(data) => roundDecimals(data.value, rounding)}
                background={{ fill: theme.palette.grey.main, opacity: 0.5 }}
              />

              <ReferenceLine
                y={dataAvg}
                stroke={theme.palette.graph.text}
                isFront
                strokeDasharray='8,4'
                label={({
                  offset,
                  viewBox // viewBox of the line
                }) => (
                  <>
                    <text
                      x={viewBox.x + viewBox.width / 2}
                      y={viewBox.y - 10} // position the text above the line
                      fill={theme.palette.graph.text}
                      textAnchor='middle'
                      style={{ fontWeight: 700, textShadow: shadowOutline(1, '#fff') }}
                    >
                      {t('average', {
                        value: t('number_workspace_filling_unit', {
                          value: roundDecimals(dataAvg, rounding)
                        })
                      })}
                    </text>
                  </>
                )}
                labelPosition='top'
              />

            </BarChart>
            )}
      </ResponsiveContainer>
    </NanoPaper>
  )
}
